import { Component, OnInit } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; // If using Firebase auth
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { AuthService } from './services/auth/auth.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { HelperService } from './services/helper/helper.service';
import { MaintenanceService } from './services/auth/maintenance.service';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Crisp } from 'crisp-sdk-web';
import { ChatbotService } from './services/chatbot/chatbot.service';
import { take } from 'rxjs/operators';
import { Language } from './constants/supportedLanguages';
import { NgSelectConfig } from '@ng-select/ng-select';
import dayjs from 'dayjs';
import { RouterOutlet } from '@angular/router';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { locales } from './constants/supportedLanguages';
import * as en from 'dayjs/locale/en';
import * as en_gb from 'dayjs/locale/en-gb';
import updateLocale from 'dayjs/plugin/updateLocale';
import { LoadingComponent } from '@components/loading/loading.component';
dayjs.extend(updateLocale);
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [NgIf, NavbarComponent, RouterOutlet, AsyncPipe, LoadingComponent]
})
export class AppComponent implements OnInit {
    maintenanceSub: Subscription;
    isSignedIn$: Observable<boolean> = this.authService.isSignedIn;
    isRolesSet$: Observable<boolean> = this.authService.isRolesSet;

    constructor(
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private helperService: HelperService,
        private maintenanceService: MaintenanceService,
        private chatbotService: ChatbotService,
        private config: NgSelectConfig,
        private translate: TranslateService
    ) {
        // below is translations set in the config for ng-select to use in the entire app
        this.config.notFoundText = this.translate.instant('ng_select.not_found');
        this.config.typeToSearchText = this.translate.instant('ng_select.type_to_search');
        this.config.addTagText = this.translate.instant('ng_select.add_item');
        this.config.loadingText = this.translate.instant('ng_select.loading');
        this.config.clearAllText = this.translate.instant('ng_select.clear_all');

        this.maintenanceSub = this.helperService
            .getMaintenanceState()
            .snapshotChanges()
            .subscribe(state => {
                const maintenanceTimestamp = state.payload.val();
                const isUnderMaintenance: boolean = (maintenanceTimestamp ?? 0) > moment().unix();
                const url = window.location.href;

                if (isUnderMaintenance) {
                    this.maintenanceService.setBackAt(maintenanceTimestamp);
                    if (!url.includes('sign-in')) {
                        this.authService.signOut();
                    }
                } else {
                    this.maintenanceService.setIsUnderMaintenance(false);
                }
            });
    }

    async ngOnInit() {
        Crisp.configure('6a5a5077-f61b-4a5f-9fd9-4f514ab13ecf', {
            cookieExpire: 3600 * 24,
            locale: this.localStorageService.getItem('language') ? this.localStorageService.getItem('language').language_code : 'en'
        });
        Crisp.setZIndex(999999999999999); // to make sure it's on top of everything
        Crisp.session.setSegments(['dashboard'], true);
        Crisp.session.onLoaded(async () => {
            if (await lastValueFrom(this.isSignedIn$.pipe(take(1)))) {
                this.chatbotService.setupChatBot();
            }
        });
        $crisp.push(['safe', true]);
        if (!window.location.pathname.includes('/sign-in')) {
            this.localStorageService.setItem('callbackUrl', window.location.pathname); // trying to access a guarded page, will remember for redirect after sign in.
        }
        this.isSignedIn$.subscribe(async (val: boolean) => {
            if (val === true) {
                this.chatbotService.setupChatBot();
            }
        });
        this.setDayjsLocale();
        if (window.location.pathname.includes('/reset-password') || window.location.pathname.includes('/set-password') || window.location.pathname.includes('/team-member')) {
            // user is signed in and trying to access an unguarded page, our ungarded pages are used for non-authenticated users, so we sign them out;
            // this is used for team-member/subcustomer's invitation links, and sign-up
            await this.authService.signOut(true);
        }
    }

    // Dayjs is getting deprecated, we should switch to something like Date-fns, which works great with typescript
    async setDayjsLocale() {
        let browserLang = navigator.language;

        const lang: Language = this.localStorageService.getItem('language');
        if (lang) {
            browserLang = lang.language_code;
        }

        let LOCALE = browserLang.split('-')[0];
        let end = browserLang.split('-')[1];
        if (navigator.language.substring(0, 2) === 'nb' || navigator.language.substring(0, 2) === 'nn') {
            LOCALE = 'no';
            end = undefined;
        }

        const fullString = (end ? LOCALE + '_' + end : LOCALE).toLocaleLowerCase();
        if (locales[fullString] === undefined) {
            if (browserLang === 'en-US') {
                const localeObject = { ...en };

                dayjs.locale('en-us', localeObject);
            }
        } else {
            // Update Dayjs
            dayjs.updateLocale('en', { ...en_gb });
            dayjs.locale(fullString);
        }
    }
}


