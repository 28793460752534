import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ControlLowerTypes, ControlTypes } from '@shared_models/form-creator';
import { z } from 'zod';

export type Controls<T> = {
    [K in keyof T]: FormControl<T[K]>;
};

export function createFormGroup<T extends z.ZodRawShape, K, F extends K>(schema: z.ZodObject<T> | z.ZodEffects<z.ZodObject<T>>, initialValues?: Partial<K>): FormGroup<Controls<F>> {
    const innerSchema = schema instanceof z.ZodEffects ? schema.innerType() : schema;
    const shape = innerSchema.shape;
    const controls = Object.keys(shape).reduce((acc, key) => {
        const control = shape[key];
        const value = initialValues ? initialValues[key] : undefined;
        if (control instanceof z.ZodString) {
            acc[key] = new FormControl(value || null);
        } else if (control instanceof z.ZodNumber) {
            acc[key] = new FormControl(value || null);
        } else if (control instanceof z.ZodEnum) {
            acc[key] = new FormControl(value || null);
        }
        return acc;
    }, {} as any);

    return new FormGroup(controls);
}

@Injectable({
    providedIn: 'root'
})
export class FormControlService<T, K extends keyof T> {
    toFormGroup(form: AwForm<T, K>): FormGroup {
        const group: any = {};

        for (const key in form) {
            const field = form[key];
            group[key] = new FormControl(field.value);
            if (field.disabled) {
                group[key].disable();
            }
        }

        return new FormGroup(group);
    }
}

export type AwForm<T, K extends keyof T> = { [key in K]: FormBase<T, K> };

export abstract class FormBaseService<T, K extends keyof T> {
    abstract setValue(value: T[K]): void;
    abstract setErrors(errors: Record<string, any>): void;
    abstract disable(): void;
    abstract enable(): void;
}

export class FormBase<T, K extends keyof T> implements FormBaseService<T, K> {
    value: T[K] | null;
    key?: Extract<keyof T, string>;
    label: string;
    required: boolean;
    order: number;
    controlType?: ControlTypes;
    lowerType?: ControlLowerTypes;
    type?: string;
    options?: { key: string; value: string }[];
    tooltip?: { enabled: boolean; description: string };
    disabled?: boolean;
    events?: Partial<Record<keyof HTMLElementEventMap, (e: Event) => void>>;
    errors?: Record<string, any>;
    showControl?: boolean;

    constructor(
        options: {
            value?: T[K];
            key?: Extract<keyof T, string>;
            label?: string;
            required?: boolean;
            order?: number;
            controlType?: ControlTypes;
            lowerType?: ControlLowerTypes;
            type?: string;
            options?: { key: string; value: string }[];
            tooltip?: { enabled: boolean; description: string };
            disabled?: boolean;
            events?: Partial<Record<keyof HTMLElementEventMap, (e: Event) => void>>;
            errors?: Record<string, any>;
            showControl?: boolean;
        } = {}
    ) {
        this.value = options.value;
        this.key = options.key;
        this.label = options.label || '';
        this.required = !!options.required;
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType;
        this.lowerType = options.lowerType ?? undefined;
        this.type = options.type || '';
        this.options = options.options || [];
        this.tooltip = options.tooltip || { enabled: false, description: '' };
        this.events = options.events || {};
        this.disabled = options.disabled || false;
        this.showControl = options.showControl ?? true;
        this.errors = options.errors || {};
    }

    setValue(value: T[K]): void {
        this.value = value;
    }

    disable(): void {
        this.disabled = true;
    }

    enable(): void {
        this.disabled = false;
    }

    setErrors(errors: Record<string, any>): void {
        this.errors = errors;
    }
}

export class TextControl<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'text' as const;

    constructor(additionalProps: Omit<FormBase<T, K>, 'controlType' | keyof FormBaseService<T, K>> & { type: string }) {
        super({ ...additionalProps, controlType: 'text' });
    }
}

export class NumberControl<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'number' as const;

    constructor(additionalProps: Omit<FormBase<T, K>, 'controlType' | keyof FormBaseService<T, K>> & { controlType: ControlTypes }) {
        super(additionalProps);
        if (additionalProps.controlType !== 'number') {
            throw new Error('NumberControl must have a controlType.');
        }
    }
}

export class SelectControl<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'select' as const;
    override options: { key: string; value: string }[];
    placeholder?: string;

    constructor(
        additionalProps: Omit<FormBase<T, K>, 'options' | keyof FormBaseService<T, K>> & {
            options: { key: string; value: string }[]; // Mark as required here
            placeholder?: string;
        }
    ) {
        super(additionalProps);
        this.options = additionalProps.options;
        this.placeholder = additionalProps.placeholder;
    }
}

export class CheckboxControl<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'aw-checkbox' as const;
}

export class RadioControl<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'aw-radio' as const;
    override options: { key: string; value: string }[] = [] as Required<{ key: string; value: string }[]>;
}

export class RadioGroupControl<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'aw-radio-group' as const;
    override options: { key: string; value: string }[] = [];
    override value: T[K];

    constructor(
        additionalProps: Omit<FormBase<T, K>, keyof FormBaseService<T, K>> & {
            options: { key: string; value: string }[];
        }
    ) {
        super(additionalProps);
        this.options = additionalProps.options;
    }
}

export class MultiSelectControl<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'multi-select' as const;
    override options: { key: string; value: string }[] = [];
}

export class MultiSelectWg<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'multi-select-wg' as const;
    override options: { key: string; value: string }[] = [];
}

export class HappyHourControl<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'happy-hour' as const;
}

export class SwitchControl<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'aw-switch' as const;
}

export class DropdownControl<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'dropdown' as const;
    override options: { key: string; value: string }[] = [];
}

/**
 * @description
 * A control for selecting a country phone call code and entering a phone number.
 *
 * @param lowerType - The type of phone number control, either 'call-code' or 'phone-number'.
 *  if 'call-code' is selected, the control will only display a dropdown for selecting a country code.
 *  If 'phone-number' is selected, the control will display a dropdown for selecting a country code and an input for entering a phone number.
 */
export class PhoneNumberControl<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'phone-number' as const;
    override lowerType: ControlLowerTypes = 'phone-number' as const;
    override options: { key: string; value: string }[] = [];

    constructor(
        additionalProps: Omit<FormBase<T, K>, 'options' | 'controlType' | 'lowerType' | keyof FormBaseService<T, K>> & {
            options: { key: string; value: string }[];
            type: string;
        }
    ) {
        super({ ...additionalProps, controlType: 'phone-number' });

        this.options = additionalProps.options;
    }
}

export class PhoneCallCodeControl<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'phone-number' as const;
    override lowerType = 'call-code' as const;
    override options: { key: string; value: string }[] = [];

    constructor(
        additionalProps: Omit<FormBase<T, K>, 'options' | 'controlType' | 'lowerType' | 'label' | keyof FormBaseService<T, K>> & {
            options: { key: string; value: string }[];
            label?: string;
        }
    ) {
        super({ ...additionalProps, controlType: 'phone-number' });

        this.options = additionalProps.options;
    }
}

export class CustomControl<T, K extends keyof T> extends FormBase<T, K> {
    override controlType = 'custom' as const;
}
