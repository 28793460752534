<app-page-layout [pageTitle]="'navbar.billing' | translate" [tabOptions]="['billing.inactive', 'billing.active']" (tabChanged)="view = $event">
    <ng-container buttons>
        @if (view === "billing.active") {
            <app-aw-filter-button
                [style]="isMobile ? '' : 'margin-right: 15px'"
                [popupAnchor]="isMobile ? 'left' : 'right'"
                [filterOptions]="filterOptionsActive"
                [disabled]="initLoading"
                (filterChanged)="catchFilterChanged($event)"
                (emitDependencyChange)="handleFilterValueChange($event)"
            />
            <app-aw-export-button
                [disabled]="loadingActiveBilling || !paramsForActive.total"
                exportMailPath="api_export/billing"
                exportPath="api_export/billing"
                filename="Airwallet_billing_export_active"
                [limitBeforeMail]="500"
                [filterSortParams]="paramsForActive"
                [itemCount]="paramsForActive.total"
            />
        } @else if (view === "billing.inactive") {
            <app-aw-filter-button
                [style]="isMobile ? '' : 'margin-right: 15px'"
                [popupAnchor]="isMobile ? 'left' : 'right'"
                [filterOptions]="filterOptionsInactive"
                [disabled]="initLoading"
                (filterChanged)="catchFilterChanged($event)"
                (emitDependencyChange)="handleFilterValueChange($event)"
            />
            <app-aw-export-button
                [disabled]="loadingInactiveBilling || !paramsForInactive.total"
                exportPath="api_export/billing"
                exportMailPath="api_export/billing"
                filename="Airwallet_billing_export_inactive"
                [limitBeforeMail]="500"
                [filterSortParams]="paramsForInactive"
                [itemCount]="paramsForInactive.total"
            />
            <button
                [class.btn-disabled]="selectedUnits.length < 1 && !selectAll"
                [disabled]="selectedUnits.length < 1 && !selectAll"
                class="btn btn-primary btn-tiny"
                [style]="isMobile ? '' : 'margin-left: 15px'"
                (click)="openBillingSubscriptionModal(billingSubscriptionModal)"
            >
                <img class="plus-icon-on-btn" src="../../../../assets/icons/plus-for-create.svg" alt="{{ '' | translate }}" />
                {{ "billing.subscribe" | translate }}
            </button>
        }
    </ng-container>

    <ng-container body>
        @if (!initLoading) {
            @if (!billingDetails.onboardingStateActive) {
                <div class="billing-card-wrapper">
                    <h5>
                        {{ "billing.before_setup" | translate }} <a role="button" class="primary-color" [routerLink]="['/overview']">{{ "billing.overview" | translate }}</a
                        >.
                    </h5>
                </div>
            } @else {
                <div>
                    <!--Table displaying devices with subscription (active)-->
                    <app-aw-table
                        [tableHeaders]="tableHeaderOptions"
                        [emptytext]="'billing.no_active_devices' | translate"
                        [pageNumber]="paramsForActive.pageNumber"
                        [pageSize]="paramsForActive.pageSize"
                        [totalItems]="paramsForActive.total"
                        (handlePageChange)="handlePageActive($event)"
                        (sortBy)="catchSortChanged($event)"
                        [loadingData]="loadingActiveBilling"
                        [sidescrollable]="isMobile"
                        [initalSort]="this.paramsForActive.sortBy"
                        *ngIf="view === 'billing.active'"
                    >
                        <ng-container table-body>
                            <row *ngFor="let unit of activeBillingUnits">
                                <cell [style.width]="defaultColumnWidthActive[0] + '%'">
                                    <span>{{ unit.serial }}</span>
                                </cell>
                                <cell [style.width]="defaultColumnWidthActive[1] + '%'">
                                    <span *ngIf="isOperator">{{ getCustomerName(unit) }}</span>
                                </cell>
                                <cell [style.width]="defaultColumnWidthActive[2] + '%'">
                                    @if (unit.in_use) {
                                        <span>{{ getLocationName(unit) }}</span>
                                    } @else {
                                        <div style="display: flex; align-items: center">
                                            <app-custom-tool-tip [spaceBetween]="true" [label]="'billing.not_in_use' | translate" [gap]="0.25" [fullWidth]="false" [description]="'billing.device_on_active_subscription' | translate" />
                                        </div>
                                    }
                                </cell>
                                <cell [style.width]="defaultColumnWidthActive[3] + '%'">
                                    @if (unit.in_use) {
                                        <span>{{ unit.unit_type === "terminal" ? "Anton terminal" : unit.name }}</span>
                                    } @else {
                                        <div style="display: flex; align-items: center">
                                            <app-custom-tool-tip [spaceBetween]="true" [label]="'billing.not_in_use' | translate" [gap]="0.25" [fullWidth]="false" [description]="'billing.device_on_active_subscription' | translate" />
                                        </div>
                                    }
                                </cell>
                                <cell [style.width]="defaultColumnWidthActive[4] + '%'">
                                    <app-aw-hover-icon
                                        [hoverText]="
                                            unit.canceled
                                                ? ('billing.cancels' | translate) + ': ' + helperService.createdLabelForLists(unit.period_end)
                                                : ('billing.renew_date' | translate) + ': ' + helperService.createdLabelForLists(unit.period_end)
                                        "
                                        [label]="(unit.canceled ? 'billing.cancels' : 'location.active') | translate"
                                        [hoverIcon]="'../../../assets/icons/clock-white.svg'"
                                        [icon]="'../../../assets/icons/' + (unit.canceled ? 'clock-orange.svg' : 'clock-green.svg')"
                                        [startColor]="unit.canceled ? '#FC9124' : '#67C2AC'"
                                        [endColor]="unit.canceled ? '#FFB66B' : '#29E1B4'"
                                        [iconWidth]="13"
                                    />
                                </cell>
                                <cell *ngIf="billingDetails.hasDiscount" style="width: 15%">
                                    <app-aw-hover-icon
                                        [hoverText]="unit.hoverText"
                                        [label]="unit.label"
                                        [hoverIcon]="'../../../assets/icons/' + (unit.renewable ? 'clock-white.svg' : 'cross-white.svg')"
                                        [icon]="'../../../assets/icons/' + unit.icon"
                                        [startColor]="unit.startColor"
                                        [endColor]="unit.endColor"
                                        [iconWidth]="13"
                                        *ngIf="(globalDiscount && globalDiscount != 0) || unit.discount"
                                    />
                                    <span *ngIf="!((globalDiscount && globalDiscount != 0) || unit.discount)">-</span>
                                </cell>
                                <cell [style.width]="defaultColumnWidthActive[5] + '%'" style="text-align: right">
                                    <div style="width: 100%">
                                        <span>{{ helperService.createdLabelForLists(unit.activated) }}</span>
                                    </div>
                                </cell>
                                <cell [style.width]="defaultColumnWidthActive[6] + '%'">
                                    <div style="display: flex; justify-content: end; width: 100%">
                                        <app-aw-dot-popup-button
                                            [isDisabled]="unit.block_undo_cancel"
                                            [menuOptions]="getMenuOptions(unit)"
                                            [isLoading]="dotButtonLoadingIndex === unit.serial"
                                            (clickedOption)="catchDotMenuClicked($event, unit)"
                                            (dropdownOpened)="onDropdownOpened($event)"
                                            (componentInitialized)="dropdownComponents.push($event)"
                                        />
                                    </div>
                                </cell>
                            </row>
                        </ng-container>
                    </app-aw-table>

                    <!--Table displaying devices without subscription (inactive)-->
                    <app-aw-table
                        [tableHeaders]="tableHeaderOptionsInactive"
                        [emptytext]="'billing.no_inactive_devices' | translate"
                        (sortBy)="catchSortChanged($event)"
                        (handlePageChange)="handlePageInactive($event)"
                        [pageNumber]="paramsForInactive.pageNumber"
                        [pageSize]="paramsForInactive.pageSize"
                        [totalItems]="paramsForInactive.total"
                        [loadingData]="loadingInactiveBilling"
                        [sidescrollable]="isMobile"
                        [initalSort]="paramsForInactive.sortBy"
                        [showCheckBoxPosition]="isMobile ? 'start' : 'end'"
                        *ngIf="view === 'billing.inactive'"
                    >
                        <ng-container header-checkbox>
                            <div [ngStyle]="{ display: 'flex', 'justify-content': isMobile ? 'start' : 'end', 'align-items': 'center', height: '8px', position: 'relative' }">
                                <app-aw-checkbox
                                    style="position: absolute"
                                    [checkedBackgroundColor1]="'var(--primary-color-dark)'"
                                    [checkedBackgroundColor2]="'var(--primary-color-light)'"
                                    [partial]="selectedUnits.length > 0 || unselectedUnits.length > 0"
                                    [checkbox]="selectAll || selectedUnits.length > 0"
                                    (checkStateEmitter)="selectAllUnits($event)"
                                />
                            </div>
                        </ng-container>

                        <ng-container table-body>
                            <row *ngFor="let unit of inactiveBillingUnits">
                                <cell [style.width]="defaultColumnWidthInactive[6] + '%'" *ngIf="isMobile">
                                    <div style="display: flex; justify-content: start; width: 100%">
                                        <app-aw-checkbox
                                            [checkbox]="unit.is_checked"
                                            [checkedBackgroundColor1]="'var(--primary-color-dark)'"
                                            [checkedBackgroundColor2]="'var(--primary-color-light)'"
                                            (checkStateEmitter)="toggleDeviceSelection(unit, $event)"
                                        />
                                    </div>
                                </cell>
                                <cell [style.width]="defaultColumnWidthInactive[0] + '%'">
                                    <span>{{ unit.serial }}</span>
                                </cell>
                                <cell [style.width]="defaultColumnWidthInactive[1] + '%'">
                                    <span *ngIf="isOperator">{{ getCustomerName(unit) }}</span>
                                </cell>
                                <cell [style.width]="defaultColumnWidthInactive[2] + '%'">
                                    <span>{{ getLocationName(unit) }}</span>
                                </cell>
                                <cell [style.width]="defaultColumnWidthInactive[3] + '%'">
                                    <span>{{ unit.unit_type === "terminal" ? "Anton terminal" : unit.name }}</span>
                                </cell>
                                <cell *ngIf="billingDetails.hasDiscount" style="width: 15%">
                                    @if (unit.icon) {
                                        <app-aw-hover-icon
                                            [hoverText]="unit.hoverText"
                                            [label]="unit.label"
                                            [hoverIcon]="'../../../assets/icons/clock-white.svg'"
                                            [icon]="'../../../assets/icons/' + unit.icon"
                                            [startColor]="unit.startColor"
                                            [endColor]="unit.endColor"
                                            [iconWidth]="13"
                                        />
                                    } @else {
                                        <span>-</span>
                                    }
                                </cell>
                                <cell [style.width]="defaultColumnWidthInactive[4] + '%'">
                                    <div *ngIf="unit.pending_card_payment" class="no-overflow">
                                        <img style="aspect-ratio: 1" src="../../../assets/icons/clock-orange.svg" />
                                        {{ "billing.pending_payment" | translate }}
                                    </div>
                                </cell>
                                <cell [style.width]="defaultColumnWidthInactive[5] + '%'" style="text-align: right">
                                    <div style="width: 100%">
                                        <span>{{ helperService.createdLabelForLists(unit.created) }}</span>
                                    </div>
                                </cell>
                                <cell [style.width]="defaultColumnWidthInactive[6] + '%'" *ngIf="!isMobile">
                                    <div style="display: flex; justify-content: end; width: 100%">
                                        <app-aw-checkbox
                                            [disabled]="unit.pending_card_payment ?? false"
                                            [initialCheckedValue]="unit.isChecked"
                                            [checkbox]="unit.isChecked"
                                            [checkedBackgroundColor1]="'var(--primary-color-dark)'"
                                            [checkedBackgroundColor2]="'var(--primary-color-light)'"
                                            (checkStateEmitter)="toggleUnitSelection(unit, $event)"
                                        />
                                    </div>
                                </cell>
                            </row>
                        </ng-container>
                    </app-aw-table>
                </div>
            }
        } @else {
            <div class="billing-card-wrapper">
                <div>
                    <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
                </div>
            </div>
        }
    </ng-container>
</app-page-layout>

<ng-template #billingSubscriptionModal let-modal>
    <app-billing-subscription-modal
        [selectedUnits]="selectedUnits"
        [unselectedUnits]="unselectedUnits"
        [selectAll]="selectAll"
        [billingDetails]="billingDetails"
        [paramsForInactive]="paramsForInactive"
        (subscriptionCreated)="ngOnInit()"
    ></app-billing-subscription-modal>
</ng-template>
