import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { AuthService } from '../auth/auth.service';
import { AccountResponse } from 'shared_models/account';
import { InvitedUserDetails } from '../../../../shared_models/invited-user';
import { lastValueFrom } from 'rxjs';
import { HttpService, RequestTypes } from '@services/helper/http.service';
import { verifyBeforeUpdateEmail } from '@angular/fire/auth';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    baseUrl: string = environment.baseUrl;

    constructor(
        private authService: AuthService,
        private db: AngularFireDatabase,
        private firebaseAuth: AngularFireAuth,
        private http: HttpClient,
        private httpService: HttpService
    ) {}

    async getAccount(): Promise<AccountResponse> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(`${this.baseUrl}/api_account/get_account/`, await this.authService.getHeadersWithoutOwner()))
                .then((resp: any) => {
                    return resolve(resp).data;
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async updateTeamMemberEmailSettings(uid: string, details: InvitedUserDetails): Promise<any> {
        try {
            await this.db.list(`/customers/${uid}`).set('details', details);
        } catch (e) {
            console.error('Error updating team member email settings', e.message);
        }
    }

    async updateAccount(data: any): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(`${this.baseUrl}/api_account`, data))
                .then((resp: any) => {
                    return resolve(resp);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async updateEmail(oldEmail: string, newEmail: string): Promise<any> {
        return this.httpService.dynamicHttp(`api_account/update_user`, RequestTypes.PUT, { body: { oldEmail, newEmail } });
    }

    async deleteAccount(signOut: boolean, onBehalf: string): Promise<void> {
        await this.httpService.dynamicHttp(`api_account/delete_account`, RequestTypes.DELETE, { onBehalf }).then(() => {
            if (signOut) this.authService.signOut();
        });
    }
}
