import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ExternalAccount, ExternalAccountCreateParams, ExternalAccountUpdateParams } from '../../../../../../../shared_models/external_accounts';
import { countries } from 'shared_data/countries';
import { HelperService } from '../../../../../services/helper/helper.service';
import { ExternalAccountService } from '../../../../../services/external-account/external-account.service';
import { DashboardUser } from '../../../../../../dashboard-models/dashboard-user';
import { bankAccountCountries } from './bankAccountCountries';
import { banks } from '../../../../../../../shared_data/banklist';
import { LoadingComponent } from '../../../../loading/loading.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf, NgFor } from '@angular/common';
import { CustomToolTipComponent } from '../../../../misc/custom-tool-tip/custom-tool-tip.component';
import { CustomModalComponent } from '../../../../misc/custom-modal/custom-modal.component';
import * as branches from 'shared_data/bankBranches'; // used in html

@Component({
    selector: 'app-external-account-form',
    templateUrl: './external-account-form.component.html',
    styleUrls: ['./external-account-form.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, FormsModule, ReactiveFormsModule, CustomToolTipComponent, NgIf, NgFor, NgSelectModule, LoadingComponent, TranslateModule]
})
export class ExternalAccountFormComponent implements OnInit {
    @Input() modalService: NgbModal;
    @Input() externalAccount: ExternalAccount;
    @Input() isInitialAccount: boolean;
    @Input() onlyDefault: boolean;
    @Input() countrySpecs: { default_currency: string; currencies: Record<string, string[]> };
    @Output() performListChangeEmitter = new EventEmitter<ExternalAccount>();
    @Output() archiveEmitter = new EventEmitter<string>();
    externalAccountForm: UntypedFormGroup;
    externalAccountFormSubmitted: boolean;
    countries: Record<string, string>[] = []; // [{code,name}]
    currencies: string[] = [];
    isEdit: boolean;
    formDisabled: boolean;
    formProcessing: boolean;
    formSubmitted: boolean;
    confirmedConvert = false;
    user: DashboardUser;
    isIBAN = true;
    bankAccountRequirements: {
        routing_type: 'swift' | 'none' | 'routing' | 'sort_code' | 'bank_code';
        account_type: 'iban' | 'account';
    };
    countriesAndCurrencies: Record<string, string[]>; // currency, array of countrycodes
    bankData: { name: string; code: string }[] = [];
    contriesWithBankSelection = ['TH', 'CO', 'ID', 'TH', 'SG', 'MY'];
    contriesWithBranchSelection = ['UZ', 'SG', 'TH'];
    branches = {
        TH: branches.TH,
        UZ: branches.UZ,
        SG: branches.SG
    };

    constructor(
        private formBuilder: UntypedFormBuilder,
        private toast: ToastrService,
        private translate: TranslateService,
        private helperService: HelperService,
        private externalAccountService: ExternalAccountService
    ) {}

    async ngOnInit(): Promise<void> {
        this.isEdit = !!this.externalAccount;
        this.user = this.helperService.getUser();
        this.setupExternalAccountForm(this.countrySpecs);
        this.bankAccountRequirements = bankAccountCountries[this.country.value];
        this.bankData = banks[this.country.value];

        if (this.isEdit) {
            this.currencies.push(this.externalAccount.currency);
            this.countries.push({ code: this.externalAccount.country, name: this.helperService.getCountryTranslated(this.externalAccount.country, true) });
            this.currency.disable();
            this.country.disable();
        }
    }

    async onCountrySelect() {
        this.bankAccountRequirements = bankAccountCountries[this.country.value];
        if (this.country.value === 'UZ') {
            this.bank.setValidators([Validators.required]);
            this.branch_code.setValidators([Validators.required]);
        } else {
            this.bank.removeValidators([Validators.required]);
            this.branch_code.removeValidators([Validators.required]);
        }
        this.externalAccountForm.updateValueAndValidity();
    }

    onCurrencySelect() {
        this.currency?.updateValueAndValidity();
        this.setTranslatedCountries();
        this.country.patchValue(null);
    }

    setupExternalAccountForm(countrySpecs: { default_currency: string; currencies: Record<string, string[]> }) {
        const countryPicked = this.externalAccount ? this.externalAccount.country : this.user.settings.country;
        this.externalAccountForm = this.formBuilder.group({
            name: new UntypedFormControl(this.isEdit ? this.externalAccount.name : null, [Validators.required]),
            country: new UntypedFormControl(this.isEdit ? this.externalAccount.country : this.user.settings.country, [Validators.required]),
            currency: new UntypedFormControl(this.isEdit ? this.externalAccount.currency : this.user.settings.currency, [Validators.required]),
            account_number: new UntypedFormControl(this.isEdit ? `••••${this.externalAccount.last4}` : null, [Validators.required]),
            routing_number: new UntypedFormControl(this.isEdit ? (this.externalAccount.routing_number ? this.externalAccount.routing_number : null) : null),
            account_type: new UntypedFormControl(null),
            is_default: new UntypedFormControl(
                {
                    value: this.isInitialAccount ? true : this.isEdit ? this.externalAccount.is_default : false,
                    disabled: this.isEdit && this.externalAccount.is_default ? true : this.isInitialAccount ? true : false
                },
                [Validators.required]
            ),
            bank: new UntypedFormControl(this.isEdit ? this.externalAccount.selected_bank_name : null, countryPicked === 'UZ' ? [Validators.required] : null),
            branch_code: new UntypedFormControl(this.isEdit ? this.externalAccount.branch_code : null, countryPicked === 'UZ' ? [Validators.required] : null)
        });

        if (!this.isEdit) {
            this.countriesAndCurrencies = countrySpecs.currencies;
            this.currencies = Object.keys(this.countriesAndCurrencies);
            this.currency.patchValue(countrySpecs.default_currency);
            this.setTranslatedCountries();
            this.country.patchValue(this.user.settings.country);

            if (this.externalAccountForm.get('bank') && this.bank.value === null) {
                this.externalAccountForm.get('branch_code').disable();
            }
        }
    }

    getTranslateBankAccount(id: string, isDesc?: boolean): string {
        if (id !== 'none' && id !== '') {
            if (isDesc) return this.translate.instant(`account.${id}_desc`);
            if (id === 'account') return this.translate.instant(`account.account_number`);
            return this.translate.instant(`account.${id}`);
        }
        return ' ';
    }

    async submitForm(): Promise<void> {
        this.formProcessing = true;
        this.formDisabled = true;

        if (this.externalAccountForm.valid) {
            if (this.isEdit) {
                const updateParams = { ...(this.externalAccountForm.value as ExternalAccountUpdateParams) };

                if (`••••${this.externalAccount.last4}` === updateParams.account_number) {
                    // it found to be the same as fetched from the backend, i.e. not a new account number
                    delete updateParams.account_number;
                    delete updateParams.routing_number;
                }

                for (const key in updateParams) {
                    if (updateParams[key] === this.externalAccount[key]) {
                        if (key === 'routing_number' && updateParams.account_number && updateParams.routing_number) {
                            // leave the routing number if the account number is changed
                            continue;
                        }

                        delete updateParams[key]; // stripping the values if they match the passed values
                    }
                }

                this.externalAccount.is_default ? (updateParams.is_default = true) : null;
                updateParams.key = this.externalAccount.key;

                if (this.bank.value) updateParams.selected_bank_name = this.bank.value.name;
                updateParams.branch_code = this.branch_code.value;

                await this.externalAccountService
                    .updateExternalAccount(updateParams)
                    .then((updatedAccount: ExternalAccount) => {
                        this.modalService.dismissAll();
                        this.toast.success(this.translate.instant('account.successfully_updated_account'), this.translate.instant('misc.success'));
                        this.performListChangeEmitter.next(updatedAccount);

                        if (updateParams.key !== updatedAccount.key) {
                            // it is a new account number which means it will archive the old account and make a new in the update call
                            this.archiveEmitter.next(updateParams.key);
                        }
                        this.formProcessing = false;
                    })
                    .catch(err => {
                        this.formProcessing = false;
                        // error is from backend, this should be updated when we implement a better way to send error to the frontend
                        if (err.error.error.includes('{ERROR: ACCOUNT_NUMBER_MISSING}')) {
                            this.toast.warning(this.translate.instant('account.account_number_needs_update'), this.translate.instant('misc.warning'));
                        } else {
                            this.toast.warning(this.translate.instant('account.failed_to_update_account'), this.translate.instant('misc.warning'));
                        }
                    });
            } else {
                const createParams = this.externalAccountForm.value as ExternalAccountCreateParams;
                if (this.bank.value) createParams.selected_bank_name = this.bank.value.name;
                if (this.contriesWithBranchSelection.includes(this.country.value)) createParams.routing_number = `${this.bank.value.code}-${this.branch_code.value}`;
                await this.externalAccountService
                    .createExternalAccount(createParams)
                    .then((createdAccount: ExternalAccount) => {
                        this.modalService.dismissAll();
                        this.toast.success(this.translate.instant('account.successfully_created_account'), this.translate.instant('misc.success'));
                        this.performListChangeEmitter.next(createdAccount);
                        this.formProcessing = false;
                    })
                    .catch(err => {
                        this.formProcessing = false;
                        switch (err.error.error) {
                            case 'account_number_invalid':
                                this.toast.warning(this.translate.instant(`err_msg.${err.error.error}`), this.translate.instant('misc.warning'));
                                break;

                            case 'routing_number_invalid':
                                this.toast.warning(this.translate.instant(`err_msg.${err.error.error}`), this.translate.instant('misc.warning'));
                                break;

                            default:
                                this.toast.warning(this.translate.instant(`err_msg.contact_airwallet`), this.translate.instant('misc.warning'));
                                break;
                        }
                    });
            }
        } else {
            this.externalAccountFormSubmitted = true;
        }

        this.formDisabled = false;
        this.formProcessing = false;
    }

    async delete(): Promise<void> {
        if (!this.externalAccount || this.is_default.value) {
            this.toast.info(this.translate.instant('account.unset_default'), this.translate.instant('misc.info'));
            return;
        }

        this.formDisabled = true;
        this.formProcessing = true;

        await this.externalAccountService
            .deleteExternalAccount(this.externalAccount.key)
            .then((deletedAccount: ExternalAccount) => {
                this.modalService.dismissAll();
                this.toast.success(this.translate.instant('account.successfully_deleted_account'), this.translate.instant('misc.success'));
                this.performListChangeEmitter.next(deletedAccount);
                this.formProcessing = false;
            })
            .catch(err => {
                this.formProcessing = false;
                this.toast.warning(this.translate.instant('account.failed_to_delete_account'), this.translate.instant('misc.warning'));
            });

        this.formDisabled = false;
    }

    setTranslatedCountries() {
        this.countries = []; // Clear the existing entries
        for (const code in countries) {
            if (this.countriesAndCurrencies[this.currency.value].includes(code)) {
                this.countries.push({ code: code, name: this.helperService.getCountryTranslated(code, true) });
            }
        }
        this.countries = this.countries.sort((a: Record<string, string>, b: Record<string, string>) => {
            if (a.name && b.name) return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
        });
    }

    disabledStateInfo() {
        this.toast.info(this.translate.instant('account.unset_default'), this.translate.instant('misc.info'));
    }

    onBankSelect() {
        if (this.bank.value === null) {
            this.branch_code.patchValue(null);
            this.branch_code.disable();
        } else {
            this.branch_code.patchValue(null);
            this.branch_code.enable();
        }
    }

    bankCleared() {
        this.branch_code.patchValue(null);
        this.branch_code.disable();
    }

    get name() {
        return this.externalAccountForm.get('name');
    }
    get country() {
        return this.externalAccountForm.get('country');
    }
    get account_number() {
        return this.externalAccountForm.get('account_number');
    }
    get routing_number() {
        return this.externalAccountForm.get('routing_number');
    }
    get currency() {
        return this.externalAccountForm.get('currency');
    }
    get is_default() {
        return this.externalAccountForm.get('is_default');
    }
    get account_type() {
        return this.externalAccountForm.get('account_type');
    }

    get bank() {
        return this.externalAccountForm.get('bank');
    }

    get branch_code() {
        return this.externalAccountForm.get('branch_code');
    }
}
