<div class="contract-box box">
    <div class="box-header">
        <app-custom-tool-tip header="{{ 'overview.contract_performance' | translate }}" description="{{ 'overview.performance_tooltip' | translate }}" placementUp="true" titleClass="line-title"></app-custom-tool-tip>
        <p class="pointer" (click)="onLinkClick()" [routerLink]="['/operator/contracts']" *ngIf="!isMobile">
            <u>{{ "overview.open_list" | translate }}</u>
            <img src="../../../../assets/icons/arrow-up-blue.svg" style="transform: rotate(90deg)" alt="{{ 'customers.company_name' | translate }}" />
        </p>
    </div>
    <div *ngIf="!loading" style="position: relative">
        <div class="empty-performance-overlay" *ngIf="contracts.fronts[0] === ''">
            <p>{{ "overview.no_good_locations" | translate }}</p>
        </div>
        <table style="width: 100%">
            <tr>
                <th class="smaller-column">{{ "operator.contract_modal.contract_id" | translate }}</th>
                <th class="locations-column">{{ "misc.locations" | translate }}</th>
                <th class="smaller-column overflow-column">{{ "overview.front_runners" | translate }}</th>
            </tr>
            <tbody>
                <tr *ngFor="let contract of contracts.fronts">
                    <td [routerLink]="contract.contract_id ? ['/operator/contracts'] : null" [queryParams]="{ id: contract.contract_key }" class="{{ contract.contract_id ? 'pointer' : null }} smaller-column overflow-column">
                        <u>{{ contract.contract_id }}</u>
                    </td>
                    <td class="locations-column">{{ contract.location_names ? contract.location_names.join(", ") : "" }}</td>
                    <td class="smaller-column" [style]="contract.performance < 0 ? 'color: #D81E5B' : 'color: #29E1B4'">
                        <strong>{{ round(contract.performance) }}&ensp;</strong>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!loading" style="position: relative">
        <div class="empty-performance-overlay" *ngIf="contracts.slackers[0] === ''">
            <p>{{ "overview.no_bad_locations" | translate }}</p>
        </div>
        <table style="width: 100%">
            <tr>
                <th class="smaller-column"></th>
                <th class="locations-column"></th>
                <th class="smaller-column overflow-column">{{ "overview.slackers" | translate }}</th>
            </tr>
            <tbody>
                <tr *ngFor="let contract of contracts.slackers">
                    <td [routerLink]="contract.contract_id ? ['/operator/contracts'] : null" [queryParams]="{ id: contract.contract_key }" class="{{ contract.contract_id ? 'pointer' : null }} smaller-column overflow-column">
                        <u>{{ contract.contract_id }}</u>
                    </td>
                    <td class="locations-column">{{ contract.location_names ? contract.location_names.join(", ") : "" }}</td>
                    <td class="smaller-column" [style]="contract.performance < 0 ? 'color: #D81E5B' : 'color: #29E1B4'">
                        <strong>{{ round(contract.performance) }}&ensp;</strong>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <p class="pointer bottom-link" [routerLink]="['/operator/contracts']" *ngIf="isMobile">
        <u>{{ "overview.open_list" | translate }}</u>
        <img src="../../../../assets/icons/arrow-up-blue.svg" style="transform: rotate(90deg)" alt="{{ 'customers.company_name' | translate }}" />
    </p>

    <div *ngIf="loading" class="loading-box">
        <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
    </div>
</div>
