import { Component, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { SettlementsService } from '../../../services/settlements/settlements.service';
import { HelperService } from '../../../services/helper/helper.service';
import { DashboardUser } from '../../../../dashboard-models/dashboard-user';
import { Subscription } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { LoadingComponent } from '../../loading/loading.component';
import { NgIf, NgFor } from '@angular/common';
import { DisplaySettlement } from '@shared_models/payouts';
import { TableHeaderOptions } from '@shared_models/aw-components/tableHeaderOptions';
import { PaginatePipe } from '../../../pipe/paginate.pipe';
import { AwTableComponent } from '../../misc/aw-table/aw-table.component';
import { AwHoverIconComponent } from '../../misc/aw-hover-icon/aw-hover-icon.component';
import { Sort } from '@shared_models/search-params/FilterSortParams';
import { DateTimeService } from '@services/date-time/date-time.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'app-settlements',
    templateUrl: './settlements.component.html',
    styleUrls: ['./settlements.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingComponent, RouterLink, NgFor, TranslateModule, PaginatePipe, AwTableComponent, AwHoverIconComponent]
})
export class SettlementsComponent implements OnInit, OnDestroy {
    protected isMobile = false;
    showLoadingIndicator = true;
    settlementsSub: Subscription;
    settlements: DisplaySettlement[];
    noSettlementsYet = false;
    currentUserUid = '';
    controlledUid: string;
    user: DashboardUser;
    pageSize = 15;
    pageNumber = 0;
    sortBy = 'created';

    tableHeaderOptions: TableHeaderOptions[] = [
        {
            sortKey: 'status',
            title: this.translate.instant('transactions.status'),
            width: '15%'
        },
        {
            sortKey: 'created',
            title: this.translate.instant('settlements.date'),
            width: '15%'
        },
        {
            sortKey: '',
            title: this.translate.instant('settlements.bank_name'),
            width: '15%'
        },
        {
            sortKey: 'amount',
            title: this.translate.instant('settlements.payout_amount'),
            width: '15%',
            alignment: 'right'
        },
        {
            sortKey: '',
            title: this.translate.instant('settlements.export'),
            width: '28%',
            alignment: 'right'
        },
        {
            sortKey: '',
            title: this.translate.instant('settlements.pdf'),
            width: '10%',
            alignment: 'right'
        }
    ];

    constructor(
        private settlementsService: SettlementsService,
        private translate: TranslateService,
        private helperService: HelperService,
        private dateTimeService: DateTimeService,
        private breakpointObserver: BreakpointObserver
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    ngOnDestroy() {
        this.settlementsSub ? this.settlementsSub.unsubscribe() : null;
    }

    ngOnInit() {
        this.user = this.helperService.getUser();
        this.setSubscriptions();
    }

    setSubscriptions() {
        this.settlementsService.getSettlements(this.user.uid).then(settlements => {
            this.settlements = settlements;
            this.showLoadingIndicator = false;
            this.noSettlementsYet = this.settlements.length === 0;
        });
    }

    // Reset pagenumber if sort is changed to another key, if same sort but different order, keep the pagenumber
    async catchSortChanged(event: Sort) {
        this.settlements = Object.values(this.settlements) // ["Music", "The Arts", "Social"]
            .sort(function (a, b) {
                return event.order === 'desc' ? (a[event.key] > b[event.key] ? 1 : -1) : a[event.key] < b[event.key] ? 1 : -1; // Organize the category array
            });
    }

    formatAmount(payoutAmount: number, currency: string): string {
        return `${this.helperService.localizeNumberWithCurrency(payoutAmount / 100, 2, 2, currency)}`;
    }

    export(downloadLink: string) {
        if (downloadLink) {
            const link = document.createElement('a');
            link.href = downloadLink;
            link.download = 'file.csv';
            link.click();
            this.helperService.defaultHtmlToast('', this.translate.instant('settlements.successfully_exported'), 'Success');
        } else {
            this.helperService.defaultHtmlToast(this.translate.instant('settlements.failed_export'), this.translate.instant('settlements.no_data_to_export'), 'Error');
        }
    }

    getDate(timestamp: number) {
        return this.dateTimeService.getDateAndTime(timestamp, true, true);
    }

    handlePage(e: PageEvent) {
        this.pageSize = e.pageSize;
        this.pageNumber = e.pageIndex;
    }
}
