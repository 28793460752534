import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DashboardUser } from '../../../../../dashboard-models/dashboard-user';
import { Action, ActionLog, actionState } from '../../../../../../shared_models/operator/actions';
import { AuthService } from '../../../../services/auth/auth.service';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActionsService } from '../../../../services/actions/actions.service';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { HelperService } from 'src/app/services/helper/helper.service';
import { ActionCardService } from '../../../../services/actions/actionCard.service';
import { DateTimeService } from '../../../../services/date-time/date-time.service';
import { RouterLink } from '@angular/router';
import { NgIf, AsyncPipe } from '@angular/common';
import { MixpanelService } from '@services/mixpanel/mixpanel.service';

@Component({
    selector: 'app-small-actions-card',
    templateUrl: './small-overview-actions-card.component.html',
    styleUrls: ['./small-overview-actions-card.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, AsyncPipe]
})
export class SmallOverviewActionsCardComponent implements OnInit, OnDestroy {
    user: DashboardUser;

    @Input() action: Action;
    @Input() isSmallerCard = false;
    @Input() isSkeleton: boolean;
    @Output() emitRemoveAction = new EventEmitter<any>();

    latestLogValue;
    timestamp;
    reversedLogKeys;
    isOperator$: Observable<boolean> = this.authService.isOperator;

    showAdviceAction = true;
    showAlertAction = true;

    private ngUnsubscribe = new Subject<void>();

    constructor(
        private authService: AuthService,
        private translate: TranslateService,
        private actionService: ActionsService,
        private mixpanelService: MixpanelService,
        private helperService: HelperService,
        private actionCardService: ActionCardService,
        private dateTimeService: DateTimeService
    ) {
        actionService.selectedAdviceToggled$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
            this.showAdviceAction = value;
        });
        actionService.selectedAlertToggled$$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
            this.showAlertAction = value;
        });
    }

    ngOnInit(): void {
        moment.locale(this.translate.currentLang);
        this.user = this.helperService.getUser();

        this.reversedLogKeys = this.getReversedLogKeys();

        this.latestLogValue = this.getLastestLogEntry();
        this.timestamp = this.timeSince(this.latestLogValue.timestamp);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    showAction(): boolean {
        let hasAlert = false;
        let hasAdvice = false;
        if (this.action.active_actions) {
            for (const activeAction in this.action.active_actions) {
                if (this.action.log[activeAction].state === actionState.advice) {
                    hasAdvice = true;
                }
                if (this.action.log[activeAction].state !== actionState.advice) {
                    hasAlert = true;
                }
            }
        } else {
            if (this.latestLogValue.state === actionState.advice) {
                hasAdvice = true;
            }
            if (this.latestLogValue.state !== actionState.advice) {
                hasAlert = true;
            }
        }
        if (hasAdvice && this.showAdviceAction) {
            return true;
        } else return hasAlert && this.showAlertAction;
    }

    timeSince(timestamp: number): string {
        return this.dateTimeService.getDateAndTime(timestamp, false, false);
    }

    getLastestLogEntry(): ActionLog {
        const firstElementKey = Object.keys(this.action.log).reverse()[0];
        return this.action.log[firstElementKey];
    }

    getReversedLogKeys(): string[] {
        return Object.keys(this.action.log).reverse();
    }

    getState(actionLog: ActionLog): string {
        return this.translate.instant(`operator.actions.states.${actionLog.state}`);
    }

    getTitle(actionLog: ActionLog, isForLog: boolean): string {
        return this.actionCardService.getTitle(actionLog, isForLog, this.isSkeleton, this.action);
    }

    getDescription(actionLog: ActionLog, action: Action): string {
        return this.actionCardService.getDescription(actionLog, action);
    }

    getPath(customerId: string, locationId?: string, deviceId?: string): string {
        return `${customerId === this.user.uid ? '' : `/customers/${customerId.split('_operated_by_')[0]}`}/locations${locationId ? `/${locationId}` : ''}/${deviceId ? `/devices/${deviceId}` : ''}`;
    }

    onLinkClick(item_number: number) {
        console.log({ action: `action card ${item_number} from overview` });
        this.mixpanelService.track(`Action: Actions Card ${item_number} Clicked`, { action: `action card ${item_number} from overview` });
        this.mixpanelService.identify(); // Associate actions with the user
    }
}
