import { Injectable } from '@angular/core';
import { Coupon, CouponsQueryResult, CreateCouponParams, CustomerDisplay, CustomerLocations } from '../../../../../shared_models/coupon';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService, RequestTypes } from '../../helper/http.service';
import { FilterSortParams } from '@shared_models/search-params/FilterSortParams';

@Injectable({
    providedIn: 'root'
})
export class CouponService {
    private couponGlobalToggled$ = new BehaviorSubject<any>({});
    private notifyUsersToggled$ = new BehaviorSubject<any>({});
    private givenUpfrontToggled$ = new BehaviorSubject<any>({});
    private selectedCouponSubject: BehaviorSubject<Coupon | null> = new BehaviorSubject<Coupon | null>(null);
    processedEvents: { day: string; events: any[] }[];

    constructor(private httpService: HttpService) {}

    selectedCoupon$: Observable<Coupon | null> = this.selectedCouponSubject.asObservable();

    setSelectedCoupon(coupon: Coupon | null): void {
        this.selectedCouponSubject.next(coupon);
    }

    setCouponGlobalToggled(toggled: boolean) {
        this.couponGlobalToggled$.next(toggled);
    }

    setNotifyUsersToggled(toggled: boolean) {
        this.notifyUsersToggled$.next(toggled);
    }

    setGivenUpfrontToggled(toggled: boolean) {
        this.givenUpfrontToggled$.next(toggled);
    }

    async getCustomersForDisplay() {
        return this.httpService.dynamicHttp<{ allCustomers: CustomerDisplay[]; locationAmount: number }>('api_coupon/get_coupon_locations', RequestTypes.GET);
    }

    async getSpecificCouponData(couponKey: string): Promise<{ coupon: Coupon; customersForCoupon: CustomerDisplay[]; locationAmount: number; allCustomers: CustomerDisplay[]; allLocationsAmount: number }> {
        return this.httpService.dynamicHttp(`api_coupon/get_coupon_locations/specific?couponKey=${couponKey}`, RequestTypes.GET);
    }

    async getCouponFilters(): Promise<{ value: string; label: string }[]> {
        return this.httpService.dynamicHttp('api_coupon/coupon/filters', RequestTypes.GET);
    }

    async getCoupons(data: FilterSortParams): Promise<CouponsQueryResult> {
        return this.httpService.dynamicHttp(`api_coupon/coupon?params=${JSON.stringify(data)}`, RequestTypes.GET);
    }

    async createCoupons(couponParams: CreateCouponParams): Promise<Coupon> {
        return this.httpService.dynamicHttp('api_coupon/coupon', RequestTypes.POST, { body: couponParams });
    }

    async archiveCoupons(couponId: string, global: boolean) {
        return this.httpService.dynamicHttp('api_coupon/coupon/archive', RequestTypes.PUT, { body: { couponId, global } });
    }

    editCoupons(couponEditParams: Coupon, selectedLocations: CustomerLocations[], shouldCheckForName: boolean) {
        return this.httpService.dynamicHttp('api_coupon/coupon/edit', RequestTypes.PUT, { body: { couponEditParams, selectedLocations, shouldCheckForName } });
    }

    async getUsedCouponCodes(): Promise<string[]> {
        return this.httpService.dynamicHttp<string[]>('api_coupon/coupon/codes', RequestTypes.GET);
    }
}
