import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SnapshotAction } from '@angular/fire/compat/database/interfaces';
import { HelperService } from '../../services/helper/helper.service';
import { CustomerService } from '../../services/customer/customer.service';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { lastValueFrom, Observable, Subscription } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SubCustomer, SubCustomerPermission } from 'shared_models/sub-customer';
import { AuthService } from '../../services/auth/auth.service';
import { take } from 'rxjs/operators';
import { Roles } from '../../../../shared_models/claims';
import { FilterSortParams, ListIdentifiersEnum, LocationItem, Sort } from '../../../../shared_models/search-params/FilterSortParams';
import { TableHeaderOptions } from '../../../../shared_models/aw-components/tableHeaderOptions';
import { PageEvent } from '@angular/material/paginator';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { PaginatePipe } from '../../pipe/paginate.pipe';
import { CreateLocationModalComponent } from './create-location-modal/create-location-modal.component';
import { AwTableComponent } from '../misc/aw-table/aw-table.component';
import { LoadingComponent } from '../loading/loading.component';
import { LocationControlPanelComponent } from './location-control-panel/location-control-panel.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { PageLayoutComponent } from '../misc/aw-page-layout/page-layout.component';
import { LocationService } from '@services/location/location.service';
import { Details } from '@shared_models/details';
import { AwFilterButtonComponent } from '@components/misc/aw-filter-button/aw-filter-button.component';
import { FilterOption, FilterType } from '@shared_models/aw-components/filterOption';
import { AwExportButtonComponent } from '@components/misc/aw-export-button/aw-export-button.component';

@Component({
    selector: 'app-locations',
    templateUrl: './locations.component.html',
    styleUrls: ['./locations.component.scss'],
    standalone: true,
    imports: [
        PageLayoutComponent,
        NgIf,
        LocationControlPanelComponent,
        LoadingComponent,
        AwTableComponent,
        NgFor,
        RouterLink,
        CreateLocationModalComponent,
        AsyncPipe,
        TranslateModule,
        PaginatePipe,
        AwFilterButtonComponent,
        AwExportButtonComponent
    ]
})
export class LocationsComponent implements OnInit, OnDestroy {
    protected isMobile = false;
    subCustomerSub: Subscription;
    isOperator$: Observable<boolean> = this.authService.isOperator;
    isOperator: boolean;
    showLoadingIndicator = false;
    exporting = false;
    loadingLocations = false;
    user: DashboardUser;
    customerDetailsSub: Subscription;
    customerDetails: Details;
    subCustomer: SubCustomer;
    role$: Observable<Roles> = this.authService.getRole;
    locations: LocationItem[];
    isCustomerOperated: boolean;
    subCustomerPermissions: SubCustomerPermission;
    uid: string;
    subCustomerUid: string;
    isSubCustomer: boolean;

    filterOptions: FilterOption[] = [
        {
            key: 'created',
            type: FilterType.DATE_RANGE,
            value: null,
            isDateRange: true,
            label: 'customers.created'
        },
        {
            key: 'revenue',
            type: FilterType.NUMBER_RANGE,
            numberRangeKey: 'BETWEEN',
            value: null,
            value2: null,
            label: 'search.locations.last_30_days'
        },
        {
            key: 'daily_starts',
            type: FilterType.NUMBER_RANGE,
            numberRangeKey: 'BETWEEN',
            value: null,
            value2: null,
            label: 'search.locations.daily_starts'
        }
    ];

    tableHeaderOptions: TableHeaderOptions[] = [
        {
            sortKey: 'name',
            title: this.translate.instant('search.locations.location_name'),
            width: '40%',
            sortDirection: 'asc'
        },
        {
            sortKey: 'daily_starts',
            title: this.translate.instant('search.locations.starts'),
            width: '20%',
            alignment: 'right',
            sortDirection: 'desc'
        },
        {
            sortKey: 'revenue',
            title: this.translate.instant('search.locations.last_30_days'),
            width: '20%',
            alignment: 'right',
            sortDirection: 'desc'
        },
        {
            sortKey: 'created',
            title: this.translate.instant('customers.created'),
            width: '20%',
            alignment: 'right',
            sortDirection: 'desc'
        }
    ];

    // Necessary inputs for FE pagination
    pageSize = 15;
    totalItems = 0;
    pageNumber = 0;
    params: FilterSortParams = {
        sortBy: { key: ListIdentifiersEnum.name, order: 'asc' },
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        filter: {}
    };

    constructor(
        public authService: AuthService, // used in html
        private translate: TranslateService,
        private modalService: NgbModal,
        private customerService: CustomerService,
        private route: ActivatedRoute,
        private locationService: LocationService,
        protected helperService: HelperService,
        private breakpointObserver: BreakpointObserver
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    ngOnDestroy() {
        this.subCustomerSub ? this.subCustomerSub.unsubscribe() : null;
        this.customerDetailsSub ? this.customerDetailsSub.unsubscribe() : null;
    }

    async ngOnInit() {
        this.showLoadingIndicator = true;
        this.isOperator = await this.authService.getOperatorStatus();
        this.user = this.helperService.getUser();
        this.isCustomerOperated = !!window.location.pathname.split('/').includes('customers');
        this.subCustomerUid = this.route.snapshot.paramMap.get('sub_customer_id');
        this.uid = this.subCustomerUid ? `${this.subCustomerUid}_operated_by_${this.user.uid}` : this.user.uid;

        if (this.isOperator && this.isCustomerOperated) {
            await this.getSubcustomer();
            const subcustomerPermAction: SnapshotAction<SubCustomerPermission> = await lastValueFrom(this.customerService.readSubCustomerPermission(this.uid).snapshotChanges().pipe(take(1)));
            this.subCustomerPermissions = subcustomerPermAction.payload.val();
            if (this.subCustomerPermissions.allow_change_billing === undefined) {
                await this.customerService.checkAllowChangeBilling(this.uid);
            }
            this.isSubCustomer = false;
        }

        await this.loadLocations();

        this.customerDetailsSub = this.customerService
            .readCustomerDetails(this.uid)
            .snapshotChanges()
            .subscribe(async (action: any) => {
                this.customerDetails = action.payload.val();
                this.customerDetailsSub.unsubscribe();
            });
    }

    async loadLocations(): Promise<void> {
        this.loadingLocations = true;
        this.params.pageNumber = this.pageNumber;
        const paramsAsString: string = JSON.stringify(this.params);
        this.locationService.getLocations(this.uid, paramsAsString).then(response => {
            this.locations = response.pages[this.pageNumber];
            this.totalItems = response.totalItems;
            this.showLoadingIndicator = false;
            this.loadingLocations = false;
        });
    }

    async catchFilterChanged(event: Record<string, number[]>) {
        this.pageNumber = 0;
        this.params.filter = event;
        this.params.filter = {};
        console.log(event);

        for (const key in event) {
            if (key === 'revenue') {
                this.params.filter[key] = {
                    from: event[key][0] * 100,
                    to: event[key][1] * 100
                };
            } else if (key === 'daily_starts') {
                this.params.filter[key] = {
                    from: event[key][0] * 1000000,
                    to: event[key][1] * 1000000
                };
            } else {
                this.params.filter[key] = {
                    from: event[key][0],
                    to: event[key][1]
                };
            }
        }
        console.log(this.params.filter);
        await this.loadLocations();
    }

    private async getSubcustomer() {
        this.subCustomerSub = this.customerService
            .readSubCustomer(this.user.uid, this.subCustomerUid)
            .valueChanges()
            .subscribe((subCustomer: SubCustomer) => {
                this.subCustomerSub.unsubscribe();
                this.subCustomer = subCustomer;
            });
    }

    async handlePageActive(e: PageEvent) {
        this.pageSize = e.pageSize;
        this.pageNumber = e.pageIndex;
        this.params.pageSize = this.pageSize;
        this.params.pageNumber = this.pageNumber;
        await this.loadLocations();
    }

    async catchSortChanged(event: Sort) {
        if (this.params.sortBy.key !== event.key) {
            this.pageNumber = 0;
        }
        this.params.sortBy.key = event.key as ListIdentifiersEnum;
        this.params.sortBy.order = event.order;
        this.params.pageNumber = this.pageNumber;
        await this.loadLocations();
    }

    openModal(modal: any) {
        this.modalService
            .open(modal, {
                ariaLabelledBy: 'modal-basic-title'
            })
            .result.then((event: any) => {})
            .catch((event: any) => {});
    }
}