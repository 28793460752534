import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditUser, CreditUserCreateParams, CreditUserUpdateParams, UserCreateParams } from '../../../../../../shared_models/credit-payment';
import { DashboardUser } from '../../../../../dashboard-models/dashboard-user';
import { CreditPaymentService } from '../../../../services/credit-payment/credit-payment.service';
import { HelperService } from '../../../../services/helper/helper.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { phoneNumberCountryData } from '../../../../../../shared_data/phone_number_data';
import { CreditPaymentCodeModalComponent } from './credit-payment-code-modal/credit-payment-code-modal.component';
import { AnimatedButtonComponent } from '../../../misc/animated-button/animated-button.component';
import { LoadingComponent } from '../../../loading/loading.component';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-credit-user-form-modal',
    templateUrl: './credit-user-form-modal.component.html',
    styleUrls: ['./credit-user-form-modal.component.scss'],
    standalone: true,
    imports: [NgIf, CustomModalComponent, FormsModule, ReactiveFormsModule, NgFor, LoadingComponent, AnimatedButtonComponent, CreditPaymentCodeModalComponent, TranslateModule]
})
export class CreditUserFormModalComponent implements OnInit {
    @Input() creditUser: CreditUser;
    @Input() modalService: NgbModal;
    @Input() locationId: string;
    @Output() deleteCreditUserEmitter = new EventEmitter<string>();
    @Output() createCreditUserEmitter = new EventEmitter<CreditUser>();
    @Output() updateCreditUserEmitter = new EventEmitter<CreditUser>();

    creditUserForm: UntypedFormGroup;
    formSubmitted: boolean;
    formProcessing: boolean;
    formProcessingDisable: boolean;
    formProcessingNewCode: boolean;
    formProcessingDeleteUser: boolean;
    smsNotification = true;
    toggleSmsNotification: Function = () => {
        this.smsNotification = !this.smsNotification;
    };
    renewCode: boolean;
    supportPhone: {
        phoneNumber: string;
        telLink: string;
    } = environment.supportPhone;
    showPaymentCodeModal: boolean;
    isEdit: boolean;
    newCode: string;
    user: DashboardUser;
    uid: string;
    isCustomerOperated: boolean;
    phoneNumberPicker: { all: Record<string, string>[]; fav: Record<string, string>[] };

    constructor(
        private fb: UntypedFormBuilder,
        private creditPaymentService: CreditPaymentService,
        private route: ActivatedRoute,
        private helperService: HelperService,
        private toast: ToastrService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.user = this.helperService.getUser();
        this.isCustomerOperated = window.location.pathname.split('/').includes('customers') ? true : false;
        this.uid = this.isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid;
        this.phoneNumberPicker = this.getCallCodesAndCountry();
        this.creditUserForm = this.fb.group({
            id_from_administration: [this.creditUser ? this.creditUser.id_from_administration : null, [Validators.required, Validators.maxLength(50)]],
            name: [this.creditUser ? this.creditUser.name : null],
            phone_number_call_code: [this.creditUser ? this.creditUser.phone_number_call_code && this.getCallCode(null, this.creditUser.phone_number_call_code).label : null],
            phone_number: [this.creditUser ? this.creditUser.phone_number : null]
        });

        if (this.creditUser) {
            // NOTE: for input disabled
            // This is because the id_from_administration is used for reports and should therefore not be changed.
            // If needed to be changed in the future we need to implement logs on what the id have been through time, so we can find the orders related to the ids over a given period
            this.id_from_administration.disable();
        }
    }

    paymentCodeAction(codeRequest?: { smsNotification: boolean; newCode: string }) {
        if (codeRequest) {
            this.creditPaymentService
                .newCode(this.locationId, this.creditUser.id, this.uid, codeRequest)
                .then(() => {
                    this.toast.success(this.translate.instant('location.successfully_updated_code'), this.translate.instant('misc.success'));
                    this.modalService.dismissAll();
                })
                .catch(err => {
                    console.error(err);
                    this.toast.warning(this.translate.instant('location.failed_to_update_code'), this.translate.instant('misc.warning'));
                });
        } else {
            this.modalService.dismissAll();
            this.createCreditUserEmitter.next(this.creditUser);
        }
    }

    intializeNewPaymentCodeFlow() {
        this.isEdit = true;
        this.renewCode = true;
        this.showPaymentCodeModal = true;
        this.smsNotification = true;
    }

    getCallCode(label: string | null, code?: string): { label: string; code: string } {
        for (const countryDetails of this.getCallCodesAndCountry().all) {
            if (label === countryDetails.label) {
                return { code: countryDetails.callCode, label: countryDetails.label };
            } else if (!label && code === countryDetails.callCode) {
                return { code: countryDetails.callCode, label: countryDetails.label };
            }
        }
    }

    async submitUserForm(codeRequest?: { smsNotification: boolean; newCode: string }): Promise<void> {
        this.formProcessingDisable = true;
        this.formSubmitted = true;
        const smsNotification = codeRequest ? (codeRequest.smsNotification ? true : false) : this.smsNotification;
        const newCode = codeRequest && codeRequest.newCode ? codeRequest.newCode : null;

        // clear validators on every submit
        this.phone_number.setValidators(null);
        this.phone_number_call_code.setValidators(null);

        if (this.creditUser) {
            // an edit operation
            // by default set the validators to null
            this.phone_number.setValidators(null);
            this.phone_number_call_code.setValidators(null);

            // call code only required if phone number value is present
            if (this.phone_number.value) {
                this.phone_number.setValidators([Validators.pattern(/^[1-9]\d*$/), Validators.maxLength(15)]);
                this.phone_number_call_code.setValidators([Validators.required]);
            } else {
                this.phone_number_call_code.setValue(null);
            }
        } else {
            // a create operation
            if (this.smsNotification) {
                this.phone_number.setValidators([Validators.required, Validators.pattern(/^[1-9]\d*$/), Validators.maxLength(15)]);
                this.phone_number_call_code.setValidators([Validators.required]);
            } else {
                // call code only required if phone number value is present
                if (this.phone_number.value) {
                    this.phone_number.setValidators([Validators.pattern(/^[1-9]\d*$/), Validators.maxLength(15)]);
                    this.phone_number_call_code.setValidators([Validators.required]);
                } else {
                    this.phone_number_call_code.setValue(null);
                }
            }
        }

        this.phone_number.updateValueAndValidity();
        this.phone_number_call_code.updateValueAndValidity();

        if (this.creditUserForm.valid) {
            if (this.creditUser) {
                this.formProcessing = true;
                const updateParams: CreditUserUpdateParams = {
                    name: this.name.value,
                    phone_number: this.phone_number.value,
                    phone_number_call_code: this.phone_number_call_code.value ? this.getCallCode(this.phone_number_call_code.value).code : null
                };
                await this.creditPaymentService
                    .updateUser(updateParams, this.creditUser.id, this.locationId, this.uid)
                    .then(res => {
                        this.creditUser = { ...this.creditUser, ...res }; // updates object to include i.e. new payment code.

                        this.modalService.dismissAll();
                        this.toast.success(this.translate.instant('location.successfully_updated_user'), this.translate.instant('misc.success'));
                        this.updateCreditUserEmitter.next(this.creditUser);
                        this.formProcessing = false;
                    })
                    .catch(err => {
                        this.formProcessing = false;
                        console.error(err);
                        this.toast.warning(this.translate.instant('location.failed_to_update_user'), this.translate.instant('misc.warning'));
                    });
            } else {
                this.formProcessing = true;

                const user: UserCreateParams = { ...this.creditUser, ...this.creditUserForm.value };

                if (user.phone_number) {
                    user.phone_number_call_code = this.getCallCode(this.phone_number_call_code.value).code;
                }
                const createParams: CreditUserCreateParams = {
                    sms_notification: this.smsNotification,
                    credit_user_create_params: [user]
                };

                await this.creditPaymentService
                    .createUser(createParams, this.locationId, this.uid)
                    .then(res => {
                        const creditUser = res[0]; // only one user is created at a time
                        this.creditUser = { ...this.creditUser, ...creditUser }; // updates object to include the pushkey made by backend.

                        if (creditUser.status === 'delivered_by_admin') {
                            // modal for administrator with info on they are responsible for sharing the payment code, because it was not send on a SMS.
                            this.showPaymentCodeModal = true;
                        } else {
                            this.modalService.dismissAll();
                            this.toast.success(this.translate.instant('location.successfully_created_user'), this.translate.instant('misc.success'));
                            this.createCreditUserEmitter.next(this.creditUser);
                        }
                        this.formProcessing = false;
                    })
                    .catch(err => {
                        this.formProcessing = false;
                        console.error(err);
                        this.toast.warning(this.translate.instant('location.failed_to_create_user'), this.translate.instant('misc.warning'));
                    });
            }
        }

        this.formProcessingDisable = false;
    }

    async deleteUser(creditUserId: string): Promise<void> {
        this.formProcessingDisable = true;
        this.formProcessingDeleteUser = true;

        await this.creditPaymentService
            .deleteUser(this.locationId, creditUserId, this.uid)
            .then(() => {
                this.modalService.dismissAll();
                this.toast.success(this.translate.instant('location.successfully_deleted_user'), this.translate.instant('misc.success'));
                this.deleteCreditUserEmitter.next(creditUserId);
            })
            .catch(err => {
                console.error(err);
                this.toast.warning(this.translate.instant('location.failed_to_delete_user'), this.translate.instant('misc.warning'));
            });
        this.formProcessingDeleteUser = false;
        this.formProcessingDisable = false;
    }

    getCallCodesAndCountry(): {
        all: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[];
        fav: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[];
    } {
        const allList: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[] = [];
        const favList: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[] = [];
        const favCountries: string[] = ['UK', 'BE', 'DK', 'NL', 'NO', 'FI', 'FR', 'ES'];
        for (const item of phoneNumberCountryData) {
            const { callCode, country } = item;
            allList.push({ callCode, country, label: `${callCode} - ${country}`, list: 'all' });

            if (favCountries.includes(item.code)) favList.push({ callCode, country, label: `${callCode} - ${country}`, list: 'fav' });
        }

        // sorting in alphabetical order to easier scan the list in the dropdown
        const res = {
            all: allList.sort((a, b) => (a && b && a.country && b.country ? a.country.localeCompare(b.country, 'en', { sensitivity: 'base' }) : null)),
            fav: favList.sort((a, b) => (a && b && a.country && b.country ? a.country.localeCompare(b.country, 'en', { sensitivity: 'base' }) : null))
        };

        return res;
    }

    // removeOptions function is a fix for safari and firefox that does not accept hiding options in a optgroup tag. Instead we just remove the tags from the DOM.
    removeOptions() {
        const options = Array.from(document.getElementsByClassName('removeOption'));
        if (options.length)
            options.forEach(option => {
                option.remove();
            });
    }

    get id_from_administration() {
        return this.creditUserForm.get('id_from_administration');
    }
    get name() {
        return this.creditUserForm.get('name');
    }
    get phone_number_call_code() {
        return this.creditUserForm.get('phone_number_call_code');
    }
    get phone_number() {
        return this.creditUserForm.get('phone_number');
    }
}
