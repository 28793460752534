import { ProductType } from './product-type';
import { Config } from './config';
import { MachineSpecifications } from './machine-specifications';

export enum IncrementUnit {
    Time = 'time',
    Money = 'money'
}
export enum PriceUnit {
    Hour = '/hour',
    Minute = '/minute',
    Increment = '/increment',
    Fixed = 'fixed',
    None = 'none' // for central pay stations OR access
}
export enum ACDetectMode {
    None = 0,
    HighInUse = 1,
    EndPulse = 2,
    LowInUse = 3
}

export interface MachineProgram {
    pushkey?: string;
    name: string;
    price: number;
    pulse_number?: number;
    sort_weight: number;
    fixed_duration?: number;
    pulse_duration?: number;
}

export type DeviceTypes = 'fixedPulse' | 'incrementPickerTime' | 'incrementPickerCost' | 'fullDuration' | 'programPicker' | 'solarium' | 'fixedUserStartTime';

export interface Basic {
    contract_id?: string;
    ac_detect_mode?: ACDetectMode;
    config: Config; // this corresponds to the name of the three different device models.
    created_on: number;
    customer_uid: string; // because it is optional on terminals it is optional here, so the two models can be extends on lists where they are merged
    currency: string;
    guid: string;
    id: string;
    last_used: number; // future, now plus minutes
    last_payment_timestamp?: number; // order creation timestamp
    location_id: string;
    location_name: string;
    mac_address?: string;
    machine_specifications: MachineSpecifications;
    maintenance: boolean;
    maximum_value?: number;
    minimum_value: number; // this is a value that is designed to limit the lowest possible selection of time the app user can choose. This help the owner to control what the minimum transaction price can be.
    name: string;
    coin_drop_installed: boolean;
    coin_feedback_enabled?: boolean;
    open_door?: boolean;
    password: string;
    price?: number; // reason why price is set to optional is that increment_picker with increment_unit === 'money' does not have price.
    price_unit: PriceUnit;
    product_type: ProductType; // e.i. 'WASHER' or 'DRYER' etc...
    protocol_version: string;
    relay_number?: number; // R1's does not have relay_number
    startup_time?: number;
    subscribed?: boolean; // this is used to determine if the device should be a demo device or it should function as usual. 1. if undefined === regular, 2. if false === demo, 3. if true === active/is paid for
    insured?: boolean; // true if Airwallet+ is on the subscription of the device
    sort_weight?: number; // Used for sorting UI
    full_duration_program?: boolean; // if true, the device is a full duration program
}
export interface IncrementPicker extends Basic {
    increment_unit: IncrementUnit.Money | IncrementUnit.Time;
    pulse_duration?: number;
    pulse_pause?: number;
    pulse_increment: number; // if increment_unit === 'money' then this is in 100's (cents) else if increment_unit === 'time' then no multiplication is done. Also if the device is "full duration" then this naming is not great, because it is not pulses, but simply increments in the UI
    pulse_number?: number; // called "Repetition" in the ui
}

export interface FixedPrice extends Basic {
    fixed_duration: number;
    pulse_duration: number;
    pulse_pause?: number;
    pulse_number?: number; // called "Repetition" in the ui
}

export interface ProgramPicker extends Basic {
    machine_programs: Record<string, MachineProgram>;
    fixed_duration: number;
    pulse_duration: number;
    pulse_pause?: number;
    pulse_number?: number;
}

export interface remoteStartParams {
    device_serial: any;
    terminal_user_uid: string;
    minutes: number;
    data_value: number;
    program?: MachineProgram;
}

export interface startDevice {
    serial: string;
    message?: string;
    minutes?: number;
    data_value?: number;
    program?: MachineProgram;
}

export interface replaceForm {
    guidOld: string;
    guidNew: string;
    relayNumber?: string;
}

export interface FirmwareInfo {
    protocol: any;
    firmware: any;
    serial: string;
    password: string;
    flashedTimestamp: string;
    type: string;
    used: any;
}

export interface Unit extends Basic {
    stats: {
        revenue: number;
        terminalStarts: number;
        starts: number;
    };
}
