import { Route } from '@angular/router';
import { CreateTeamMemberComponent } from '../components/settings/team-members/create-team-member/create-team-member.component';
import { CustomersSetPasswordComponent } from '../components/customers-set-password/customers-set-password.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { OverviewComponent } from '../components/overview/overview.component';
import { AuthGuardService } from '../services/auth-guard/auth-guard.service';
import { TransactionsComponent } from '../components/transactions/transactions.component';
import { StripelinkComponent } from '../components/stripelink/stripelink.component';
import { LocationsComponent } from '../components/locations/locations.component';
import { LocationComponent } from '../components/location/location.component';
import { DeviceComponent } from '../components/device/device.component';
import { DeviceSetupComponent } from '../components/device-setup/device-setup.component';
import { AccountComponent } from '../components/settings/account/account.component';
import { TeamMembersComponent } from '../components/settings/team-members/team-members.component';
import { CouponFormComponent } from '@components/settings/coupon/coupon-form/coupon-form.component';
import { CouponComponent } from '../components/settings/coupon/coupon.component';
import { SettlementsComponent } from '../components/settings/settlements/settlements.component';
import { CustomersCreateComponent } from '../components/customers-create/customers-create.component';
import { DeveloperComponent } from '../components/developer/developer.component';
import { ActionsOverviewComponent } from '../components/operator/actions/actions-overview/actions-overview.component';
import { AntonHealthComponent } from '../components/operator/anton-health/anton-health.component';
import { OperatorComponent } from '../components/operator/operator.component';
import { SearchComponent } from '../components/search/search.component';
import { BillingComponent } from '../components/settings/billing/billing.component';
import { SupportComponent } from '../components/support/support.component';
import { UserComponent } from '../components/users/user/user.component';
import { UsersComponent } from '../components/users/users.component';
import operatorRoutes from './operator.routes';
import searchRoutes from './search.routes';
import { DiscountToolComponent } from '../components/discount-tool/discount-tool.component';
import { AuthPageComponent } from '@components/auth-page/auth-page.component';

export default [
    {
        path: 'sign-up',
        component: AuthPageComponent
    },
    {
        path: 'sign-in',
        component: AuthPageComponent
    },
    {
        path: 'team-member/:email/:region/:key',
        component: CreateTeamMemberComponent
    },
    {
        path: 'team-member/:email/:region', // deprecated, can be removed after 1st february 2025
        component: CreateTeamMemberComponent
    },
    {
        path: 'set-password/:email/:operatorUid/:region',
        component: CustomersSetPasswordComponent
    },
    {
        path: 'reset-password/:key',
        component: ResetPasswordComponent
    },
    {
        path: 'transactions',
        component: TransactionsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'stripelink',
        component: StripelinkComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'locations',
        component: LocationsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'locations/:location_id',
        component: LocationComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'locations/:location_id/devices/:device_id',
        component: DeviceComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'locations/:location_id/device_setup',
        component: DeviceSetupComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'locations/:location_id/device_setup/:reconfig_values',
        component: DeviceSetupComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'account/:param',
        component: AccountComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'team-members',
        component: TeamMembersComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'coupon/coupon-create',
        component: CouponFormComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'coupon/:key',
        component: CouponFormComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'coupon',
        component: CouponComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'settlements',
        component: SettlementsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'support',
        component: SupportComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'support/anton-health',
        component: AntonHealthComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'support/discount-tool',
        component: DiscountToolComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'billing',
        component: BillingComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'billing/:processing_subscription',
        component: BillingComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'operator',
        component: OperatorComponent,
        canActivate: [AuthGuardService],
        children: operatorRoutes
    },
    {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'users/:user_id',
        component: UserComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'actions',
        component: ActionsOverviewComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'developer',
        component: DeveloperComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'customers/create',
        component: CustomersCreateComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'customers/:sub_customer_id/locations',
        component: LocationsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'customers/:sub_customer_id/locations/:location_id',
        component: LocationComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'customers/:sub_customer_id/locations/:location_id/devices/:device_id',
        component: DeviceComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'customers/:sub_customer_id/locations/:location_id/device_setup',
        component: DeviceSetupComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'customers/:sub_customer_id/locations/:location_id/device_setup/:reconfig_values',
        component: DeviceSetupComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'overview',
        component: OverviewComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'search',
        component: SearchComponent,
        canActivate: [AuthGuardService],
        children: searchRoutes
    },
    {
        path: '**',
        redirectTo: '/overview'
    }
] satisfies Route[];
