import { Component, OnDestroy, OnInit, WritableSignal } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { countries } from '../../../../../shared_data/countries';
import { environment } from '../../../../environments/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../../services/helper/helper.service';
import { CreateNewCustomer } from '../../../../../shared_models/details';
import { BusinessType } from '../../../../../shared_models/stripe';
import { CountryCode } from '../../../../../shared_models/external_accounts';
import { Router } from '@angular/router';
import { DashboardUser } from '../../../../dashboard-models/dashboard-user';
import { Title } from '@angular/platform-browser';
import { NgFor, NgIf } from '@angular/common';
import { AwCheckboxComponent } from '@components/misc/aw-checkbox/aw-checkbox.component';
import { AuthPageService } from '@services/auth/auth-page.service';
import { euCountries } from '@shared_data/euCountries';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, TranslateModule, AwCheckboxComponent]
})
export class SignUpComponent implements OnInit, OnDestroy {
    signUpForm: UntypedFormGroup;
    formSubmitted = false;
    countries: string[] = [];
    createAccountBtnLabel = 'Create an account';
    supportPhone: {
        phoneNumber: string;
        telLink: string;
    } = environment.supportPhone;
    pickedCountryCode: CountryCode;
    pickedRegion: 'us' | 'eu' = 'eu';
    sign_up: string;
    loading: WritableSignal<boolean>;
    companyIndividualSignUp: WritableSignal<boolean>; // all eu countries have the option to choose between company and individual
    gdprPolicy: string = environment.gdprPolicy;
    euTermsOfUse: string = environment.euTermsOfUse;
    usTermsOfUse: string = environment.usTermsOfUse;
    gdprChecked: boolean;
    termsChecked: boolean;
    termsOfUseAndGDPRDisabled: boolean = true;

    constructor(
        private authService: AuthService,
        protected helperService: HelperService, // used in HTML
        private toast: ToastrService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private translate: TranslateService,
        private title: Title,
        private authPageService: AuthPageService
    ) {
        this.loading = this.authPageService.loading;
        this.companyIndividualSignUp = this.authPageService.companyIndividualSignUp;
    }

    ngOnInit() {
        this.title.setTitle('Sign up');

        const user: DashboardUser = this.helperService.getUser(true);
        if (user) this.router.navigate([`/overview`]);

        this.sign_up = this.translate.instant('sign_up.sign_up');

        Object.values(countries).forEach(countryData => {
            this.countries.push(countryData.country);
        });

        this.countries.sort((a: string, b: string) => {
            return a.localeCompare(b, 'en', { sensitivity: 'base' });
        });

        this.setupSignUpForm();
        this.onChanges();
    }

    ngOnDestroy() {
        this.loading.set(false);
        this.companyIndividualSignUp.set(false);
    }

    onChanges(): void {
        this.signUpForm.valueChanges.subscribe(val => {
            const repeatPasswordValue: string = val.repeatPassword;
            const passValue: string = val.password;

            if (val.country) {
                for (const code in countries) {
                    if (countries[code].country === val.country) {
                        this.termsOfUseAndGDPRDisabled = false; // enable terms and gdpr, cannot unselect country pick again so will be enabled until page reload

                        if (this.pickedRegion !== countries[code].platform) {
                            // region changed, uncheck terms and gdpr
                            this.termsChecked = false;
                            this.gdprChecked = false;
                        }

                        this.pickedCountryCode = code as CountryCode;
                        this.pickedRegion = countries[code].platform;
                        this.companyIndividualSignUp.set(euCountries.includes(this.pickedCountryCode)); // all eu countries have the option to choose between company and individual
                        break;
                    }
                }
            }

            if (passValue === repeatPasswordValue && !this.signUpForm.get('repeatPassword').pristine) {
                // valid state
                this.signUpForm.get('repeatPassword').setErrors(null);
                this.signUpForm.get('repeatPassword').setValidators([Validators.required]);
            }

            if (this.formSubmitted && !this.signUpForm.get('password').pristine && this.helperService.isStrongPassword(passValue).is_strong) {
                // valid state
                this.signUpForm.get('password').setErrors(null);
                this.signUpForm.get('password').setValidators([Validators.required, Validators.minLength(12), Validators.maxLength(255)]);
            }
        });
    }

    setupSignUpForm() {
        this.signUpForm = this.formBuilder.group({
            password: [null, [Validators.required, Validators.minLength(12), Validators.maxLength(255)]],
            repeatPassword: [null, Validators.required],
            email: [null, [Validators.required, Validators.maxLength(255)]],
            country: [null, Validators.required],
            businessType: [null, Validators.required]
        });
    }

    async signUp(): Promise<boolean> {
        let created: boolean = false;
        this.formSubmitted = true;
        this.loading.set(true);
        this.createAccountBtnLabel = this.translate.instant('sign_up.wait');

        if (!euCountries.includes(this.pickedCountryCode)) {
            // non-eu countries can only be company
            this.businessType.patchValue(BusinessType.COMPANY);
        }

        // Validating password
        if (!this.helperService.isStrongPassword(this.password.value).is_strong) {
            this.signUpForm.get('password').setErrors({ weakPassword: true });
        } else if (this.password.value !== this.repeatPassword.value) {
            this.signUpForm.get('repeatPassword').setErrors({ pwNoMatch: true });
        }

        if (this.signUpForm.valid && this.gdprChecked && this.termsChecked) {
            const createCustomerParams: CreateNewCustomer = {
                email: this.signUpForm.value['email'].replace(/\s/g, '').toLowerCase(),
                password: this.signUpForm.value['password'],
                businessType: this.businessType.value,
                country: this.pickedCountryCode,
                tos: {
                    accepted_terms_and_conditions: true,
                    accepted_gdpr: true
                }
            };
            await this.authService.createUser(createCustomerParams);
            this.toast.success(this.translate.instant('account.check_inbox'), this.translate.instant('account.account_successfully_created'));
            created = true;
            this.loading.set(false);
            return created;
        } else {
            this.createAccountBtnLabel = this.translate.instant('sign_up.create_account');
            this.loading.set(false);
            return created;
        }
    }

    toggleConsent(typeOfConsent: string) {
        if (typeOfConsent == 'terms' && !this.termsChecked) {
            this.termsChecked = true;
        } else if (typeOfConsent == 'terms' && this.termsChecked) {
            this.termsChecked = false;
        }

        if (typeOfConsent == 'gdpr' && !this.gdprChecked) {
            this.gdprChecked = true;
        } else if (typeOfConsent == 'gdpr' && this.gdprChecked) {
            this.gdprChecked = false;
        }
    }

    get password() {
        return this.signUpForm.get('password');
    }
    get repeatPassword() {
        return this.signUpForm.get('repeatPassword');
    }
    get email() {
        return this.signUpForm.get('email');
    }
    get country() {
        return this.signUpForm.get('country');
    }
    get businessType() {
        return this.signUpForm.get('businessType');
    }
}
