<div class="filter-wrapper" *ngIf="isCustomerOperated && authService.hasLimitedAccess('control_panel', role$ | async)">
    <!--The filter button-->
    <div class="filter-button" (click)="toggleFilter()">
        <div class="filter-button-content">
            <p class="filter-button-text">{{ "locations.control_panel" | translate }}</p>
        </div>
        <img src="../../../../assets/icons/arrow-down-blue.svg" [className]="isExpanded ? 'arrow arrow-clicked' : 'arrow'" />
    </div>

    <!--The Popup-->
    <div class="filter-popup" [hidden]="!isExpanded">
        <!-- Monthly Balance -->
        <div class="filter-popup-text">
            <h6 class="title">{{ "locations.monthly_balance" | translate }}</h6>

            <div class="content">
                <div class="one-line-container">
                    <div>
                        <strong>{{ "customers.total" | translate }}</strong>
                    </div>
                    <p class="label">{{ formatAmmount(subCustomerBalanceStats.total) }}</p>
                </div>
                <div class="one-line-container">
                    <div>
                        <strong>{{ "customers.operator_share" | translate }}</strong>
                    </div>
                    <p class="label">{{ formatAmmount(subCustomerBalanceStats.operatorShare) }}</p>
                </div>
                <div class="one-line-container">
                    <div>
                        <strong>{{ "customers.customer_share" | translate }}</strong>
                    </div>
                    <p class="label">{{ formatAmmount(subCustomerBalanceStats.customerShare) }}</p>
                </div>
            </div>
        </div>

        <!-- Permissions -->
        <div class="filter-popup-accordion">
            <div class="filter-popup-accordion-button" (click)="openAccordion(0)" style="width: 100%">
                <img src="../../../../assets/icons/arrow-down-blue.svg" [className]="openAccordions.includes(0) ? 'arrow arrow-clicked' : 'arrow'" />
                <app-custom-tool-tip [label]="'locations.permissions' | translate" [spaceBetween]="true" [fullWidth]="true" style="width: 100%">
                    <div description>
                        <strong>{{ "locations.refunds" | translate }}:</strong><br />
                        <span>{{ "locations.permission_tooltip.refund" | translate }}</span
                        ><br /><br />
                        <strong>{{ "locations.locations_edit" | translate }}:</strong><br />
                        <span>{{ "locations.permission_tooltip.location" | translate }}</span
                        ><br /><br />
                        <strong>{{ "location.coupon" | translate }}:</strong><br />
                        <span>{{ "locations.permission_tooltip.coupon" | translate }}</span
                        ><br /><br />
                        <strong>{{ "misc.billing" | translate }}:</strong><br />
                        <span>{{ "locations.permission_tooltip.billing" | translate }}</span>
                    </div>
                </app-custom-tool-tip>
            </div>
            <div class="filter-popup-accordion-unfolded" *ngIf="openAccordions.includes(0)">
                <div class="w-padding" (click)="$event.stopPropagation()">
                    <!-- Refunds -->
                    <div class="one-line-container">
                        <div>
                            <label class="label">{{ "locations.refunds" | translate }}</label>
                        </div>

                        <div style="display: flex">
                            <img src="../../../assets/icons/awesome-lock.svg" style="margin-right: 5px" *ngIf="!authService.hasLimitedAccess('control_panel_permissions', role$ | async)" />

                            <app-aw-switch
                                [shouldBeChecked]="subCustomerPermissions.allow_refund"
                                [disableSwitch]="permissionsUpdating || !authService.hasLimitedAccess('control_panel_permissions', role$ | async)"
                                (activateClick)="switchPermission('refund')"
                            ></app-aw-switch>
                        </div>
                    </div>

                    <!-- Edit locations -->
                    <div class="one-line-container">
                        <div>
                            <label class="label">{{ "locations.location_edit" | translate }}</label>
                        </div>
                        <div style="display: flex">
                            <img src="../../../assets/icons/awesome-lock.svg" style="margin-right: 5px" *ngIf="!authService.hasLimitedAccess('control_panel_permissions', role$ | async)" />

                            <app-aw-switch
                                [shouldBeChecked]="subCustomerPermissions.allow_location"
                                [disableSwitch]="permissionsUpdating || !authService.hasLimitedAccess('control_panel_permissions', role$ | async)"
                                (activateClick)="switchPermission('location')"
                            ></app-aw-switch>
                        </div>
                    </div>

                    <!-- Coupon -->
                    <div class="one-line-container">
                        <div>
                            <label class="label">{{ "navbar.coupon" | translate }}</label>
                        </div>
                        <div style="display: flex">
                            <img src="../../../assets/icons/awesome-lock.svg" style="margin-right: 5px" *ngIf="!authService.hasLimitedAccess('control_panel_permissions', role$ | async)" />

                            <app-aw-switch
                                [shouldBeChecked]="subCustomerPermissions.allow_coupon"
                                [disableSwitch]="permissionsUpdating || !authService.hasLimitedAccess('control_panel_permissions', role$ | async)"
                                (activateClick)="switchPermission('coupon')"
                            ></app-aw-switch>
                        </div>
                    </div>

                    <!-- Billing -->
                    <div class="one-line-container">
                        <div>
                            <label [style.color]="subCustomerPermissions.allow_change_billing ? null : '#BEC9D1'" class="label"> {{ "misc.billing" | translate }}</label>
                        </div>

                        <div style="display: flex">
                            <img src="../../../assets/icons/awesome-lock.svg" style="margin-right: 5px" *ngIf="!subCustomerPermissions.allow_change_billing || !authService.hasLimitedAccess('control_panel_permissions', role$ | async)" />

                            <app-aw-switch
                                [shouldBeChecked]="subCustomerPermissions.allow_billing"
                                [disableSwitch]="permissionsUpdating || !subCustomerPermissions.allow_change_billing || !authService.hasLimitedAccess('control_panel_permissions', role$ | async)"
                                (activateClick)="switchPermission('billing')"
                            ></app-aw-switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Revenue share -->
        <div class="filter-popup-accordion">
            <div class="filter-popup-accordion-button" (click)="openAccordion(1)">
                <img src="../../../../assets/icons/arrow-down-blue.svg" [className]="openAccordions.includes(1) ? 'arrow arrow-clicked' : 'arrow'" />
                <p>{{ "locations.revenue_share" | translate }}</p>
            </div>
            <div class="filter-popup-accordion-unfolded" [hidden]="!openAccordions.includes(1)">
                <div class="w-padding" (click)="$event.stopPropagation()">
                    <form [formGroup]="revenueShareForm">
                        <div>
                            <app-custom-tool-tip [tooltipTitle]="'locations.minimum_revenue' | translate" [description]="'locations.insert_monthly_min' | translate" [spaceBetween]="true">
                                <p title class="label">
                                    {{ "locations.minimum_revenue" | translate }}
                                </p>
                            </app-custom-tool-tip>

                            <div class="common-input mt-1" #min_rev_input>
                                <input
                                    *ngIf="!allowRevenueSplit()"
                                    autocomplete="new-minimum_revenue"
                                    name="minimum_revenue"
                                    class="label-bold input"
                                    type="text"
                                    (focus)="onFocus(min_rev_input)"
                                    (blur)="onBlur(min_rev_input)"
                                    formControlName="minimum_revenue"
                                    (change)="storeValue($event)"
                                    disabled
                                />
                                <input
                                    *ngIf="allowRevenueSplit()"
                                    autocomplete="new-minimum_revenue"
                                    name="minimum_revenue"
                                    class="label-bold input"
                                    type="text"
                                    (focus)="onFocus(min_rev_input)"
                                    (blur)="onBlur(min_rev_input)"
                                    formControlName="minimum_revenue"
                                    (change)="storeValue($event)"
                                />
                                <div class="input-append">
                                    <span class="input-group-text">{{ subCustomer.currency.toUpperCase() }}</span>
                                </div>
                            </div>

                            <!-- Advanced share slider -->
                            <div class="one-line-container" *ngIf="!subCustomerPermissions.lock_advanced_share">
                                <div>
                                    <label class="label">{{ "misc.adv_share_settings" | translate }}</label>
                                </div>

                                <div style="display: flex">
                                    <img src="../../../assets/icons/awesome-lock.svg" style="margin-right: 5px" *ngIf="!authService.hasLimitedAccess('control_panel_permissions', role$ | async)" />

                                    <div>
                                        <app-aw-switch
                                            [shouldBeChecked]="subCustomerPermissions.allow_advanced_share"
                                            [disableSwitch]="permissionsUpdating || !authService.hasLimitedAccess('control_panel_permissions', role$ | async)"
                                            (activateClick)="toggleAdvancedShare()"
                                        >
                                        </app-aw-switch>
                                    </div>
                                </div>
                            </div>

                            <!-- Customer share -->
                            <div>
                                <div class="mt-2 mb-1">
                                    <app-custom-tool-tip
                                        spaceBetween="true"
                                        placementUp="true"
                                        [description]="subCustomerPermissions.allow_advanced_share ? ('locations.advanced_customer_share' | translate) : ('locations.insert_how_much' | translate)"
                                        [tooltipTitle]="'locations.customer_share' | translate"
                                    >
                                        <h4 title style="margin-bottom: 0" class="label">
                                            {{ "locations.customer_share" | translate }}
                                        </h4>
                                    </app-custom-tool-tip>
                                </div>
                                <div #share_input [className]="this.subCustomerPermissions.allow_advanced_share ? 'disabled common-input' : 'common-input'">
                                    <input
                                        *ngIf="allowRevenueSplit()"
                                        autocomplete="new-share"
                                        name="share"
                                        [value]="revenueShareForm.get('share').disabled ? '-' : revenueShareForm.get('share').value"
                                        (focus)="onFocus(share_input)"
                                        (blur)="onBlur(share_input)"
                                        class="label-bold input"
                                        type="text"
                                        formControlName="share"
                                    />
                                    <select
                                        *ngIf="!allowRevenueSplit()"
                                        class="label-bold input"
                                        (focus)="onFocus(share_input)"
                                        (blur)="onBlur(share_input)"
                                        [value]="revenueShareForm.get('share').disabled ? '-' : revenueShareForm.get('share').value"
                                        formControlName="share"
                                    >
                                        <option>0</option>
                                        <option>100</option>
                                    </select>
                                    <div class="input-append">
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                                <div class="error-msg">
                                    {{ revenueShareForm.invalid && share.invalid && share.errors.invalidNumber ? ("locations.share_between" | translate) : "" }}
                                </div>
                            </div>

                            <!-- From washers -->
                            <div [hidden]="subCustomerPermissions.lock_advanced_share">
                                <label class="label">{{ "locations.from_washers" | translate }}</label>
                                <div #from_washer [className]="this.subCustomerPermissions.allow_advanced_share ? 'common-input' : 'disabled common-input'">
                                    <input
                                        *ngIf="allowRevenueSplit()"
                                        autocomplete="new-washer_share"
                                        (focus)="onFocus(from_washer)"
                                        (blur)="onBlur(from_washer)"
                                        name="washer_share"
                                        class="label-bold input"
                                        type="text"
                                        [value]="revenueShareForm.get('washer_share').disabled ? '-' : revenueShareForm.get('washer_share').value"
                                        formControlName="washer_share"
                                    />
                                    <select
                                        *ngIf="!allowRevenueSplit()"
                                        class="label-bold input"
                                        (focus)="onFocus(from_washer)"
                                        (blur)="onBlur(from_washer)"
                                        [value]="revenueShareForm.get('washer_share').disabled ? '-' : revenueShareForm.get('washer_share').value"
                                        formControlName="washer_share"
                                    >
                                        <option>0</option>
                                        <option>100</option>
                                    </select>
                                    <div class="input-append">
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                                <div class="error-msg">
                                    {{ revenueShareForm.invalid && washer_share.invalid && washer_share.errors.invalidNumber ? ("locations.share_between" | translate) : "" }}
                                </div>
                            </div>

                            <!-- From dryers -->
                            <div [hidden]="subCustomerPermissions.lock_advanced_share">
                                <label class="label">{{ "locations.from_dryers" | translate }}</label>
                                <div #from_dryer [className]="this.subCustomerPermissions.allow_advanced_share ? 'common-input' : 'disabled common-input'">
                                    <input
                                        *ngIf="allowRevenueSplit()"
                                        autocomplete="new-dryer_share"
                                        name="dryer_share"
                                        (focus)="onFocus(from_dryer)"
                                        (blur)="onBlur(from_dryer)"
                                        [value]="revenueShareForm.get('dryer_share').disabled ? '-' : revenueShareForm.get('dryer_share').value"
                                        class="label-bold input"
                                        type="text"
                                        formControlName="dryer_share"
                                    />
                                    <select
                                        *ngIf="!allowRevenueSplit()"
                                        class="label-bold input"
                                        (focus)="onFocus(from_dryer)"
                                        (blur)="onBlur(from_dryer)"
                                        [value]="revenueShareForm.get('dryer_share').disabled ? '-' : revenueShareForm.get('dryer_share').value"
                                        formControlName="dryer_share"
                                    >
                                        <option>0</option>
                                        <option>100</option>
                                    </select>
                                    <div class="input-append">
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                                <div class="error-msg">
                                    {{ revenueShareForm.invalid && dryer_share.invalid && dryer_share.errors.invalidNumber ? ("locations.share_between" | translate) : "" }}
                                </div>
                            </div>

                            <!-- From others -->
                            <div [hidden]="subCustomerPermissions.lock_advanced_share" class="mb-2">
                                <label class="label">{{ "locations.from_others" | translate }}</label>
                                <div #from_others [className]="this.subCustomerPermissions.allow_advanced_share ? 'common-input' : 'disabled common-input'">
                                    <input
                                        *ngIf="allowRevenueSplit()"
                                        autocomplete="new-other_share"
                                        name="other_share"
                                        (focus)="onFocus(from_others)"
                                        (blur)="onBlur(from_others)"
                                        [value]="revenueShareForm.get('other_share').disabled ? '-' : revenueShareForm.get('other_share').value"
                                        class="label-bold input"
                                        type="text"
                                        formControlName="other_share"
                                    />
                                    <select
                                        *ngIf="!allowRevenueSplit()"
                                        class="label-bold input"
                                        (focus)="onFocus(from_others)"
                                        (blur)="onBlur(from_others)"
                                        [value]="revenueShareForm.get('other_share').disabled ? '-' : revenueShareForm.get('other_share').value"
                                        formControlName="other_share"
                                    >
                                        <option>0</option>
                                        <option>100</option>
                                    </select>
                                    <div class="input-append">
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                                <div class="error-msg">
                                    {{ revenueShareForm.invalid && other_share.invalid && other_share.errors.invalidNumber ? ("locations.share_between" | translate) : "" }}
                                </div>
                            </div>

                            <div class="mt-1">
                                <button class="submit-button" type="button" (click)="saveChanges()" [disabled]="disableSaveButton || deletingAccount || !authService.hasLimitedAccess('control_panel_settings', role$ | async)">
                                    {{ !disableSaveButton ? ("device.save_changes" | translate) : "" }}
                                    <loading-indicator *ngIf="disableSaveButton" [size]="'btn-tiny'" [color]="'white'"></loading-indicator>
                                </button>
                                <div class="unsaved">
                                    <i
                                        ><label class="label">{{ revenueShareForm.dirty ? "Unsaved settings" : "" }}</label></i
                                    >
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Delete account -->
        <div class="filter-popup-accordion">
            <div class="filter-popup-accordion-button" (click)="openAccordion(2)">
                <img src="../../../../assets/icons/arrow-down-blue.svg" [className]="openAccordions.includes(2) ? 'arrow arrow-clicked' : 'arrow'" />
                <p>Danger zone</p>
            </div>
            <div class="filter-popup-accordion-unfolded" *ngIf="openAccordions.includes(2)">
                <div class="w-padding" (click)="$event.stopPropagation()">
                    <div style="text-align: center" *ngIf="authService.hasLimitedAccess('control_panel_settings', role$ | async)">
                        <button type="button" class="submit-button danger-button" (click)="deleteAccount()" style="font-size: 13px" [disabled]="deletingAccount">
                            {{ !deletingAccount ? ("account.delete_account" | translate) : "" }}
                            <loading-indicator *ngIf="deletingAccount" [size]="'btn-tiny'" [color]="'secondary'"></loading-indicator>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
