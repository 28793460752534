import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RedirectCommand, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { SubCustomerPermission } from 'shared_models/sub-customer';
import { HelperService } from '../helper/helper.service';
import * as accessMap from './roleAccessMap.json';
import { Roles } from 'shared_models/claims';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { MixpanelService } from '@services/mixpanel/mixpanel.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService {
    user: DashboardUser;
    subCustomerPermissionSub: Subscription;
    subCustomerPermission: SubCustomerPermission;
    private pageRefreshed = false;

    constructor(
        private authService: AuthService,
        private router: Router,
        private helperService: HelperService,
        private titleService: Title,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private localStorageService: LocalStorageService
    ) {
        this.user = this.helperService.getUser();
    }

    /**
     * Determines if a route can be activated based on user authentication and role.
     *
     * This method checks if the user is signed in and has the appropriate role and permissions
     * to access the requested route. If the user is not signed in, they are redirected to the
     * sign-in page. If the user does not have the necessary permissions, they are redirected
     * to the overview page.
     *
     * The method also handles page refreshes by calling the `onPageRefresh` method of the
     * `AuthService`. The `onPageRefresh` method is only called once per page load to avoid
     * unnecessary calls during subsequent route changes.
     *
     * @param next - The next route snapshot that is being navigated to.
     * @param state - The current router state snapshot.
     * @returns An Observable that emits a boolean indicating if the route can be activated or a RedirectCommand.
     */
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | RedirectCommand> {
        return new Observable<boolean | RedirectCommand>(observer => {
            const proceedWithRouting = async () => {
                // The order of observables is important, as it must match the order in the combineLatest .map

                const observables = {
                    isSignedIn: this.authService.isSignedIn,
                    role: this.authService.getRole,
                    isAdmin: this.authService.isAdmin,
                    isOperator: this.authService.isOperator,
                    isDeveloper: this.authService.isDeveloper,
                    isBillingAllowed: this.authService.isBillingAllowed,
                    isCouponAllowed: this.authService.isCouponAllowed
                };
                this.titleService.setTitle(this.getTitle(next));
                // Checks if all observables have emitted a value before continuing,
                // Documentation: https://rxjs-dev.firebaseapp.com/api/index/function/combineLatest

                combineLatest(Object.values(observables))
                    .pipe(
                        skipWhile(values => values.some(value => value === undefined)),
                        map(values => {
                            const result = Object.keys(observables).reduce(
                                (acc, key, index) => {
                                    acc[key] = values[index];
                                    return acc;
                                },
                                {} as Record<string, any>
                            );
                            return result;
                        })
                    )
                    .subscribe(result => {
                        const { isSignedIn, role, isAdmin, isOperator, isDeveloper, isBillingAllowed, isCouponAllowed } = result;
                        return new Promise<boolean | RedirectCommand>(resolve => {
                            this.authService.rolesSetStatus(true);
                            if (!isSignedIn) {
                                if (state.url.split('?')[1]?.includes('mail')) {
                                    const params: string = state.url.split('?')[1].split('mail=')[1];
                                    const mail: string = params.includes('&') ? params.substring(0, params.indexOf('&')) : params;
                                    this.localStorageService.setItem('prefillMail', decodeURIComponent(mail));
                                }
                                this.localStorageService.setItem('returnUrl', state.url);
                                this.router.navigateByUrl('sign-in');
                                return;
                            }
                            this.user = this.helperService.getUser();
                            const path = next.routeConfig.path;

                            const formatString = (str: string): string => {
                                // Remove the leading slash and capitalize the first letter
                                const formatted = str
                                    .replace('/', '')
                                    .replace(/^\w/, c => c.toUpperCase())
                                    .split('?')[0]; // Remove query parameters
                                return formatted;
                            };
                            this.mixpanelService.track(`${formatString(path)} View`, { view: formatString(path) });
                            this.mixpanelService.identify(); // Associate actions with the user

                            const redirectToOverview = () => new RedirectCommand(this.router.createUrlTree(['/overview']));

                            if (['support', 'support/anton-health', 'support/discount-tool'].includes(path) && !isAdmin) {
                                return resolve(redirectToOverview());
                            }
                            if (['customers', 'operator'].includes(path.split('/')[0]) && !isOperator) {
                                return resolve(redirectToOverview());
                            }
                            if (path === 'developer' && !isDeveloper) {
                                return resolve(redirectToOverview());
                            }
                            if (path === 'billing' && (!isBillingAllowed || this.user.settings.is_regular)) {
                                return resolve(redirectToOverview());
                            }

                            if (path === 'coupon' && !isCouponAllowed) {
                                return resolve(redirectToOverview());
                            }

                            if (state.url === '/actions' && isOperator) {
                                return resolve(new RedirectCommand(this.router.createUrlTree(['/operator/actions'])));
                            }

                            if (role !== Roles.owner && !accessMap[role as Roles].includes(path)) {
                                console.log('Not allowed to go to this url', path);
                                return resolve(new RedirectCommand(this.router.createUrlTree([accessMap[role as Roles][0]])));
                            }

                            return resolve(true);
                        }).then(result => {
                            observer.next(result);
                            observer.complete();
                        });
                    });
            };

            proceedWithRouting();
        });
    }

    /**
     * Sets the title of the page based on the route.
     * If there is a path, it will return the last part of the path.
     *  E.g. 'support/discount-tool' -> 'discount-tool'
     *
     * If there is a query parameter 'name', it will return the name (Used for subcustomers).
     *
     * This means, if you make a new route, you need to add a translation for
     *  it in the translation file under navbar.{routeName}
     * @param next
     */
    getTitle(next: ActivatedRouteSnapshot): string {
        const path: string = next.routeConfig.path;
        const pathArr = path.split('/');

        if (!next.queryParams['name'] && !next.queryParams['searchQuery']) {
            if (path.includes(':')) {
                return 'Airwallet';
            }
            return this.translate.instant(`navbar.${pathArr[pathArr.length - 1]}`);
        }

        if (next.queryParams['searchQuery']) {
            return `${next.queryParams['searchQuery']}`;
        }

        return `${next.queryParams['name']}`;
    }
}
