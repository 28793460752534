// Currently supported countries are:
/**
 * - Colombia
 * - Uzbekistan
 */
export const banks: Record<string, { name: string; code: string }[]> = {
    UZ: [
        { name: 'ANOR BANK UZ', code: 'ANOBUZ22XXX' },
        { name: 'ASIA ALLIANCE BANK', code: 'ASACUZ22XXX' },
        { name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN', code: 'CBUZUZ22XXX' },
        { name: 'CREDIT-STANDARD BANK', code: 'CRIDUZ21XXX' },
        { name: 'HI-TECH BANK PCJSB', code: 'HPCJUZ22XXX' },
        { name: 'INVEST FINANCE BANK', code: 'INFBUZ2XXXX' },
        { name: 'IPOTEKA-BANK', code: 'UZHOUZ22XXX' },
        { name: 'JSC ALOQABANK', code: 'JSCLUZ22XXX' },
        { name: 'JSC ASAKABANK', code: 'ASBKUZ22XXX' },
        { name: 'JSC BANK APELSIN', code: 'APELUZ22XXX' },
        { name: 'JSC MADAD INVEST BANK', code: 'JSMIUZ22XXX' },
        { name: 'JSC POYTAXT BANK', code: 'JOCPUZ22XXX' },
        { name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN', code: 'SCPEUZ22XXX' },
        { name: 'JSC ZIRAAT BANK UZBEKISTAN', code: 'TCZBUZ22XXX' },
        { name: 'JSCB AGROBANK', code: 'PAKHUZ22XXX' },
        { name: 'JSCB HAMKORBANK', code: 'KHKKUZ22XXX' },
        { name: 'JSCB SAVDOGAR', code: 'JSCSUZ22XXX' },
        { name: 'JSCB TBC BANK', code: 'JOCBUZ22XXX' },
        { name: 'JSCB TENGE BANK', code: 'HSBKUZ22XXX' },
        { name: 'JSCB TURONBANK', code: 'TUROUZ22XXX' },
        { name: 'JSCB UZAGROEXPORTBANK', code: 'UZGOUZ22XXX' },
        { name: 'JSIC BANK IPAK YULI', code: 'INIPUZ22XXX' },
        { name: 'KAPITALBANK, JSCB', code: 'KACHUZ22XXX' },
        { name: 'KDB BANK UZBEKISTAN JSC', code: 'KODBUZ22XXX' },
        { name: 'MICROCREDITBANK', code: 'MICDUZ22XXX' },
        { name: 'MINISTRY OF FINANCE THE REPUBLIC OF UZBEKISTAN', code: 'MFRUUZ22XXX' },
        { name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN', code: 'NBFAUZ31XXX' },
        { name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN', code: 'NBFAUZ2XXXX' },
        { name: 'OJSC MICROCREDITBANK', code: 'OJMIUZ21XXX' },
        { name: "PJSCB 'ORIENT FINANCE'", code: 'ORFBUZ22XXX' },
        { name: 'PJSCB DAVR BANK', code: 'DVRBUZ22XXX' },
        { name: 'PJSCB TURKISTON', code: 'TRKSUZ22XXX' },
        { name: 'PJSCB UNIVERSAL BANK', code: 'PJUNUZ22XXX' },
        { name: 'QISHLOQ QURILISH BANK JSCB', code: 'GJSOUZ22XXX' },
        { name: 'RAVNAQ-BANK', code: 'RVBKUZ22XXX' },
        { name: 'REPUBLICAN STOCK EXCHANGE (TOSHKENT)', code: 'XSTEUZ21XXX' },
        { name: "THE STATE ENTERPRISE 'CENTRAL SECURITIES DEPOSITORY'", code: 'SNCUUZ22XXX' },
        { name: 'THE UZBEKISTAN REPUBLICAN CURRENCY EXCHANGE', code: 'XCUEUZ21XXX' },
        { name: 'TRUSTBANK, PJSB', code: 'TRSAUZ22XXX' },
        { name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB', code: 'UJSIUZ22XXX' }
    ],
    CO: [
        { name: 'BBVA', code: '013' },
        { name: 'Bancamia S.A.', code: '059' },
        { name: 'Banco Agrario', code: '040' },
        { name: 'Banco Av. Villas', code: '052' },
        { name: 'Banco Caja Social BCSC', code: '032' },
        { name: 'Banco Colpatria', code: '019' },
        { name: 'Banco Credifinanciera S.A.', code: '558' },
        { name: 'Banco Dale', code: '097' },
        { name: 'Banco Davivienda', code: '051' },
        { name: 'Banco Falabella S.A.', code: '062' },
        { name: 'Banco Finandina S.A.', code: '063' },
        { name: 'Banco J.P. Morgan Colombia S.A.', code: '071' },
        { name: 'Banco Multibank S.A.', code: '064' },
        { name: 'Banco Mundo Mujer', code: '047' },
        { name: 'Banco Pichincha', code: '060' },
        { name: 'Banco Popular', code: '002' },
        { name: 'Banco Procredit', code: '058' },
        { name: 'Banco Santander de Negocios Colombia S.A.', code: '065' },
        { name: 'Banco Serfinanza S.A.', code: '069' },
        { name: 'Banco Sudameris', code: '012' },
        { name: 'Banco W S.A.', code: '053' },
        { name: 'Banco de Bogota', code: '001' },
        { name: 'Banco de Occidente', code: '023' },
        { name: 'Bancoldex S.A.', code: '031' },
        { name: 'Bancolombia', code: '007' },
        { name: 'Bancoomeva', code: '061' },
        { name: 'Citibank', code: '009' },
        { name: 'Coltefinanciera S.A.', code: '370' },
        { name: 'Confiar', code: '292' },
        { name: 'Coofinep Cooperativa Financiera', code: '291' },
        { name: 'Coopcentral S.A.', code: '066' },
        { name: 'Cooperativa Financiera de Antioquia', code: '283' },
        { name: 'Corpbanca Itau', code: '006' },
        { name: 'Cotrafa Cooperativa Financiera', code: '289' },
        { name: 'Daviplata', code: '551' },
        { name: 'Financiera Juriscoop', code: '121' },
        { name: 'Giros y Finanzas CF', code: '303' },
        { name: 'Iris', code: '637' },
        { name: 'Itau', code: '014' },
        { name: 'LULO BANK S.A.', code: '070' },
        { name: 'MiBanco S.A.', code: '067' },
        { name: 'Movii', code: '801' },
        { name: 'Nequi', code: '507' },
        { name: 'Rappipay', code: '151' }
    ],
    ID: [
        { name: 'Anglomas International Bank', code: '531' },
        { name: 'BCA', code: '014' },
        { name: 'BCA Syariah', code: '536' },
        { name: 'BNI', code: '009' },
        { name: 'BNI Syariah', code: '427' },
        { name: 'BNP Paribas Indonesia', code: '057' },
        { name: 'BRI', code: '002' },
        { name: 'BRI Syariah', code: '422' },
        { name: 'BTN', code: '200' },
        { name: 'BTN Syariah', code: '200S' },
        { name: 'BTPN', code: '213' },
        { name: 'Bangkok Bank', code: '040' },
        { name: 'Bank ANZ Indonesia', code: '061' },
        { name: 'Bank Aceh', code: '116' },
        { name: 'Bank Aceh Syariah', code: '116S' },
        { name: 'Bank Agris', code: '945' },
        { name: 'Bank Artha Graha Internasional', code: '037' },
        { name: 'Bank Artos', code: '542' },
        { name: 'Bank BPD Bali', code: '129' },
        { name: 'Bank BPD DIY', code: '112' },
        { name: 'Bank BPD DIY Syariah', code: '112S' },
        { name: 'Bank BRI Agroniaga', code: '494' },
        { name: 'Bank Bengkulu', code: '133' },
        { name: 'Bank Bisnis Internasional', code: '459' },
        { name: 'Bank Bukopin', code: '441' },
        { name: 'Bank Bumi Arta', code: '076' },
        { name: 'Bank CTBC Indonesia', code: '949' },
        { name: 'Bank Capital', code: '054' },
        { name: 'Bank Commonwealth', code: '950' },
        { name: 'Bank DKI', code: '111' },
        { name: 'Bank DKI Syariah', code: '111S' },
        { name: 'Bank Danamon', code: '011' },
        { name: 'Bank Danamon Syariah', code: '011S' },
        { name: 'Bank Dinar', code: '526' },
        { name: 'Bank Fama International', code: '562' },
        { name: 'Bank Ganesha', code: '161' },
        { name: 'Bank HSBC Indonesia', code: '087' },
        { name: 'Bank Harda Internasional', code: '567' },
        { name: 'Bank ICBC Indonesia', code: '164' },
        { name: 'Bank INA Perdana', code: '513' },
        { name: 'Bank Index Selindo', code: '555' },
        { name: 'Bank Jabar Banten', code: '110' },
        { name: 'Bank Jabar Banten Syariah', code: '425' },
        { name: 'Bank Jambi', code: '115' },
        { name: 'Bank Jasa Jakarta', code: '472' },
        { name: 'Bank Jateng', code: '113' },
        { name: 'Bank Jateng Syariah', code: '113S' },
        { name: 'Bank Jatim', code: '114' },
        { name: 'Bank Jatim Syariah', code: '114S' },
        { name: 'Bank KEB Hana', code: '484' },
        { name: 'Bank Kalbar', code: '123' },
        { name: 'Bank Kalbar Syariah', code: '123S' },
        { name: 'Bank Kalsel', code: '122' },
        { name: 'Bank Kalsel Syariah', code: '122S' },
        { name: 'Bank Kalteng', code: '125' },
        { name: 'Bank Kaltimtara', code: '124' },
        { name: 'Bank Kaltimtara Syariah', code: '124S' },
        { name: 'Bank Kesejahteraan Ekonomi', code: '535' },
        { name: 'Bank Lampung', code: '121' },
        { name: 'Bank MNC Internasional', code: '485' },
        { name: 'Bank Maluku', code: '131' },
        { name: 'Bank Mandiri', code: '008' },
        { name: 'Bank Mandiri Taspen Pos', code: '564' },
        { name: 'Bank Maspion', code: '157' },
        { name: 'Bank Mayapada', code: '097' },
        { name: 'Bank Mayora', code: '553' },
        { name: 'Bank Mega', code: '426' },
        { name: 'Bank Mega Syariah', code: '506' },
        { name: 'Bank Mestika Dharma', code: '151' },
        { name: 'Bank Mitra Niaga', code: '491' },
        { name: 'Bank Mizuho Indonesia', code: '048' },
        { name: 'Bank Muamalat', code: '147' },
        { name: 'Bank Multi Arta Sentosa', code: '548' },
        { name: 'Bank NTB', code: '128' },
        { name: 'Bank NTB Syariah', code: '128S' },
        { name: 'Bank NTT', code: '130' },
        { name: 'Bank Nagari (BPD Sumbar)', code: '118' },
        { name: 'Bank Nagari (BPD Sumbar) Syariah', code: '118S' },
        { name: 'Bank Nusantara Parahyangan', code: '145' },
        { name: 'Bank Panin', code: '019' },
        { name: 'Bank Papua', code: '132' },
        { name: 'Bank Permata', code: '013' },
        { name: 'Bank Permata Syariah', code: '013S' },
        { name: 'Bank QNB', code: '167' },
        { name: 'Bank Resona Perdania', code: '047' },
        { name: 'Bank Riau Kepri', code: '119' },
        { name: 'Bank Royal', code: '501' },
        { name: 'Bank SBI Indonesia', code: '498' },
        { name: 'Bank Sahabat Sampoerna', code: '523' },
        { name: 'Bank Shinhan', code: '152' },
        { name: 'Bank Sinarmas', code: '153' },
        { name: 'Bank Sinarmas Syariah', code: '153S' },
        { name: 'Bank Sulselbar', code: '126' },
        { name: 'Bank Sulselbar Syariah', code: '126S' },
        { name: 'Bank Sulteng', code: '134' },
        { name: 'Bank Sultra', code: '135' },
        { name: 'Bank Sulut', code: '127' },
        { name: 'Bank Sumsel Babel', code: '120' },
        { name: 'Bank Sumut', code: '117' },
        { name: 'Bank Sumut Syariah', code: '117S' },
        { name: 'Bank Syariah Bukopin', code: '521' },
        { name: 'Bank Syariah Indonesia', code: '451' },
        { name: 'Bank Victoria', code: '566' },
        { name: 'Bank Woori Saudara', code: '212' },
        { name: 'Bank Yudha Bhakti', code: '490' },
        { name: 'Bank of America', code: '033' },
        { name: 'Bank of China', code: '069' },
        { name: 'CIMB Niaga', code: '022' },
        { name: 'CIMB Niaga Syariah', code: '022S' },
        { name: 'Citibank', code: '031' },
        { name: 'DBS Indonesia', code: '046' },
        { name: 'Deutsche Bank', code: '067' },
        { name: 'J Trust Bank', code: '095' },
        { name: 'JPMorgan Chase Bank', code: '032' },
        { name: 'MUFG Bank', code: '042' },
        { name: 'Maybank Indonesia', code: '016' },
        { name: 'Maybank Syariah Indonesia', code: '947' },
        { name: 'Nobu Bank', code: '503' },
        { name: 'OCBC NISP', code: '028' },
        { name: 'OCBC NISP Syariah', code: '028S' },
        { name: 'PT Bank of India Indonesia', code: '146' },
        { name: 'Panin Dubai Syariah Bank', code: '517' },
        { name: 'Prima Master Bank', code: '520' },
        { name: 'Standard Chartered', code: '050' },
        { name: 'UOB Buana', code: '023' }
    ],
    TH: [
        { name: 'BANGKOK BANK PUBLIC COMPANY LTD.', code: '002' },
        { name: 'BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES', code: '034' },
        { name: 'BANK OF AMERICA, NATIONAL ASSOCIATION', code: '027' },
        { name: 'BANK OF AYUDHYA PUBLIC COMPANY LTD.', code: '025' },
        { name: 'BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED', code: '052' },
        { name: 'BNP PARIBAS', code: '045' },
        { name: 'CIMB THAI BANK Public Company Limited', code: '022' },
        { name: 'CITIBANK, N.A.', code: '017' },
        { name: 'DEUTSCHE BANK AG.', code: '032' },
        { name: 'EXPORT-IMPORT BANK OF THAILAND', code: '035' },
        { name: 'GOVERNMENT HOUSING BANK', code: '033' },
        { name: 'GOVERNMENT SAVINGS BANK', code: '030' },
        { name: 'INDIAN OVERSEA BANK', code: '029' },
        { name: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED', code: '070' },
        { name: 'ISLAMIC BANK OF THAILAND', code: '066' },
        { name: 'JPMORGAN CHASE BANK, NATIONAL ASSOCIATION', code: '008' },
        { name: 'KASIKORNBANK PUBLIC COMPANY LTD.', code: '004' },
        { name: 'KIATNAKIN BANK PUBLIC COMPANY LIMITED', code: '069' },
        { name: 'KRUNG THAI BANK PUBLIC COMPANY LTD.', code: '006' },
        { name: 'LAND AND HOUSES BANK PUBLIC COMPANY LIMITED', code: '073' },
        { name: 'MEGA  INTERNATIONAL COMMERCIAL BANK PUBLIC COMPANY LIMITED', code: '026' },
        { name: 'Mizuho Bank, Ltd. Bangkok Branch', code: '039' },
        { name: 'OVER SEA-CHINESE BANKING CORPORATION LIMITED', code: '009' },
        { name: 'RHB BANK BERHAD', code: '023' },
        { name: 'SIAM COMMERCIAL BANK PUBLIC COMPANY LTD.', code: '014' },
        { name: 'SMALL AND MEDIUM ENTERPRISE DEVELOPMENT BANK OF THAILAND', code: '098' },
        { name: 'STANDARD CHARTERED BANK (THAI) PUBLIC COMPANY LIMITED', code: '020' },
        { name: 'SUMITOMO MITSUI BANKING CORPORATION', code: '018' },
        { name: 'SUMITOMO MITSUI TRUST BANK (THAI) PUBLIC COMPANY LIMITED', code: '080' },
        { name: 'THANACHART BANK PUBLIC COMPANY LTD.', code: '065' },
        { name: 'THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD.', code: '031' },
        { name: 'THE THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED', code: '071' },
        { name: 'TISCO BANK PUBLIC COMPANY LIMITED', code: '067' },
        { name: 'TMB BANK PUBLIC COMPANY LIMITED', code: '011' },
        { name: 'TMBTHANACHART BANK PUBLIC COMPANY LIMITED', code: '011' },
        { name: 'UNITED OVERSEAS BANK (THAI) PUBLIC COMPANY LIMITED', code: '024' },
        { name: 'TEST BANK', code: '999' }
    ],
    SG: [
        { name: 'ABN AMRO BANK NV [7010]', code: '7010' },
        { name: 'AMERICAN EXPRESS BANK LTD [7454]', code: '7454' },
        { name: 'ANEXT BANK PTE. LTD. [9742]', code: '9742' },
        { name: 'ANZ SINGAPORE [7931]', code: '7931' },
        { name: 'BANGKOK BANK PUBLIC COMPANY [7047]', code: '7047' },
        { name: 'BANK OF AMERICA [7065]', code: '7065' },
        { name: 'BANK OF CHINA [7083]', code: '7083' },
        { name: 'BANK OF INDIA [7108]', code: '7108' },
        { name: 'BANK OF NEW ZEALAND [8129]', code: '8129' },
        { name: 'BANK OF TOKYO-MITSUBISHI [7126]', code: '7126' },
        { name: 'BNP PARIBAS [7418]', code: '7418' },
        { name: 'CALYON [7135]', code: '7135' },
        { name: 'CHINATRUST COMMERCIAL BANK CO., LTD [9353]', code: '9353' },
        { name: 'CIMB BANK BERHAD [7986]', code: '7986' },
        { name: 'CITIBANK NA [7214]', code: '7214' },
        { name: 'CITIBANK SINGAPORE [9201]', code: '9201' },
        { name: 'CITIC Bank International Ltd [9423]', code: '9423' },
        { name: 'COMMERZBANK AG [8606]', code: '8606' },
        { name: 'COMMERZBANK SG [7393]', code: '7393' },
        { name: 'DBS Bank/POSB [7171]', code: '7171' },
        { name: 'DEUTSCHE BANK [7463]', code: '7463' },
        { name: 'DZ BANK AG Deutsche Zentral [0000]', code: '0000' },
        { name: 'DnB NOR Bank ASA [7737]', code: '7737' },
        { name: 'FAR EASTERN BANK [7199]', code: '7199' },
        { name: 'FIRST COMMERCIAL BANK [7764]', code: '7764' },
        { name: 'GXS BANK PTE. LTD. [9751]', code: '9751' },
        { name: 'HABIB BANK LIMITED [7409]', code: '7409' },
        { name: 'HL BANK [7287]', code: '7287' },
        { name: 'HONGKONG & SHANGHAI BANKING CORPORATION (HSBC) [7232]', code: '7232' },
        { name: 'HONGKONG & SHANGHAI BANKING CORPORATION (HSBC) [9548]', code: '9548' },
        { name: 'ICICI BANK [9186]', code: '9186' },
        { name: 'INDIAN BANK [7241]', code: '7241' },
        { name: 'INDIAN OVERSEAS BANK [7250]', code: '7250' },
        { name: 'INTERNALTIONALE NEDERLANDEN BANK N.V. [8509]', code: '8509' },
        { name: 'INTESA SANPAOLO S.p.A [8350]', code: '8350' },
        { name: 'INTESABCI S.P.A. [7436]', code: '7436' },
        { name: 'Industrial & Commercial Bank of China [8712]', code: '8712' },
        { name: 'JP MORGAN CHASE BANK, N.A. [7153]', code: '7153' },
        { name: 'KEB HANA BANK [7490]', code: '7490' },
        { name: 'MALAYAN BANKING BERHAD [9636]', code: '9636' },
        { name: 'MAYBANK SINGAPORE LIMITED [7302]', code: '7302' },
        { name: 'MIZUHO CORPORATE BANK, LTD [7621]', code: '7621' },
        { name: 'MOSCOW NARODNY BANK LIMITED [7427]', code: '7427' },
        { name: 'MatchMove Pay Pte Ltd [0510]', code: '0510' },
        { name: 'NATIONAL AUSTRALIA BANK LTD [8077]', code: '8077' },
        { name: 'NATIONAL BANK OF KUWAIT, SAK [8280]', code: '8280' },
        { name: 'NORDEA BANK FINLAND PLC [8518]', code: '8518' },
        { name: 'OVERSEA-CHINESE BANKING CORPORATION LTD [7339]', code: '7339' },
        { name: 'PT. BANK NEGARA INDONESIA  [7056]', code: '7056' },
        { name: 'RHB BANK BERHAD [7366]', code: '7366' },
        { name: 'SINGAPORE ISLAND BANK [7117]', code: '7117' },
        { name: 'SKANDINAVISKA ENSKILDA BANKEN [8527]', code: '8527' },
        { name: 'SOCIETE GENERALE [7852]', code: '7852' },
        { name: 'STANDARD CHARTERED BANK [7144]', code: '7144' },
        { name: 'STANDARD CHARTERED BANK [9496]', code: '9496' },
        { name: 'STATE BANK OF INDIA [7791]', code: '7791' },
        { name: 'SUMITOMO MITSUI BANKING CORPORATION [7472]', code: '7472' },
        { name: 'SVENSKA HANDELSBANKEN AB (PUBL) [8493]', code: '8493' },
        { name: 'SWISS BANK CORP [7685]', code: '7685' },
        { name: 'THE BANK OF EAST ASIA [7092]', code: '7092' },
        { name: 'THE BANK OF NOVA SCOTIA [7658]', code: '7658' },
        { name: 'THE DAI-ICHI KANGYO BANK, LTD [7755]', code: '7755' },
        { name: 'TRUST BANK SINGAPORE [9733]', code: '9733' },
        { name: 'UCO BANK [7357]', code: '7357' },
        { name: 'UNION DE BANQUES ARABES ET FRANCAISES [8110]', code: '8110' },
        { name: 'UNITED OVERSEAS BANK LTD [7375]', code: '7375' },
        { name: 'Wise Asia-Pacific Pte Ltd  [0516]', code: '0516' }
    ],
    MY: [
        { name: 'Affin Bank', code: 'PHBMMYKL' },
        { name: 'Affin Islamic Bank', code: 'AIBBMYKL' },
        { name: 'Agrobank', code: 'AGOBMYKL' },
        { name: 'Al-Rajhi Banking & Investment Corporation', code: 'RJHIMYKL' },
        { name: 'Alliance Bank', code: 'MFBBMYKL' },
        { name: 'AmBank', code: 'ARBKMYKL' },
        { name: 'AmBank Islamic', code: 'AISLMYKL' },
        { name: 'BNP Paribas', code: 'BNPAMYKL' },
        { name: 'Bangkok Bank', code: 'BKKBMYKL' },
        { name: 'Bank Islam', code: 'BIMBMYKL' },
        { name: 'Bank Kerjasama Rakyat Malaysia', code: 'BKRMMYKL' },
        { name: 'Bank Muamalat', code: 'BMMBMYKL' },
        { name: 'Bank Simpanan Nasional', code: 'BSNAMYK1' },
        { name: 'Bank of America', code: 'BOFAMY2X' },
        { name: 'Bank of China', code: 'BKCHMYKL' },
        { name: 'Bank of Tokyo-Mitsubishi UFJ', code: 'BOTKMYKX' },
        { name: 'CIMB Bank', code: 'CIBBMYKL' },
        { name: 'CIMB Islamic Bank', code: 'CTBBMYKL' },
        { name: 'China Construction Bank', code: 'PCBCMYKL' },
        { name: 'Citibank', code: 'CITIMYKL' },
        { name: 'Deutsche Bank', code: 'DEUTMYKL' },
        { name: 'HSBC Amanah', code: 'HMABMYKL' },
        { name: 'HSBC Bank', code: 'HBMBMYKL' },
        { name: 'Hong Leong Bank', code: 'HLBBMYKL' },
        { name: 'Hong Leong Islamic Bank', code: 'HLIBMYKL' },
        { name: 'Industrial & Commercial Bank of China (ICBC)', code: 'ICBKMYKL' },
        { name: 'JP Morgan Chase', code: 'CHASMYKX' },
        { name: 'Kuwait Finance House', code: 'KFHOMYKL' },
        { name: 'MBSB Bank', code: 'AFBQMYKL' },
        { name: 'Maybank', code: 'MBBEMYKL' },
        { name: 'Mizuho Bank', code: 'MHCBMYKA' },
        { name: 'OCBC Al-Amin Bank', code: 'OABBMYKL' },
        { name: 'OCBC Bank', code: 'OCBCMYKL' },
        { name: 'Public Bank', code: 'PBBEMYKL' },
        { name: 'RHB Bank', code: 'RHBBMYKL' },
        { name: 'Standard Chartered Bank', code: 'SCBLMYKX' },
        { name: 'Sumitomo Mitsui Banking Corporation', code: 'SMBCMYKL' },
        { name: 'United Overseas Bank', code: 'UOVBMYKL' }
    ]
};
