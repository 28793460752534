export enum actionType {
    antonLow = 'anton_low_battery', // Basic action
    antonOff = 'anton_offline', // Basic action
    unitNotUsed = 'unit_not_used', // Basic action
    unitUsedLess = 'unit_used_less',
    unitSignal = 'unit_signal_error',
    locationRemoveMachine = 'location_remove_machine',
    machineMaintenanceDue = 'machine_maintenance_due',
    outOfSoap = 'out_of_soap',
    prolongContract = 'prolong_contract',
    restructureContract = 'restructure_contract'
}

export enum actionState {
    error = 'error',
    observing = 'observing',
    advice = 'advice',
    complete = 'complete'
}

export enum closedStatus {
    unclosed = 'unclosed',
    closed_by_user = 'closed_by_user',
    unclosed_by_user = 'unclosed_by_user'
}

// key is device_id  -> Note : title and desription if from localize (i18n) by  enum.
export interface Action {
    key: string; // On units = device_id, on anton = terminal_key, on location = location_id
    log: Record<string, ActionLog>;
    active_actions?: Record<string, actionType>; // Only user can remove from this list by settting action to closed.
    customer_id: string;
    customer_name?: string;
    statics: AntonAction | DeviceAction | LocationAction;
    dynamics?: Dynamics; //this is for values that have possibly changed every time the action is fetched
    item_number?: number; // used for Mixpanel to identify which action card is clicked
}

export interface Dynamics {
    battery_status?: number;
    maintenance_limit?: number;
    current_maintenance_count?: number;
    soap_limit?: number;
    current_soap_count?: number;
}

// id- timeptamp - action type
export interface ActionLog {
    action_type: actionType;
    timestamp: number;
    state: actionState;
    action?: ActionSpecifics;
    closed: closedStatus; //
    user_name?: string;
}

export interface ActionSpecifics {
    last_used?: number;
    last_checkin?: number;
    log_id?: string; // Id to changed state for specfic action
    product_type?: string;
}

export interface AntonAction {
    location_id: string;
    user_uid: string; // this is user uid, is set when anton a stripe account
    terminal_key: string;
    location_name: string;
    device_name: string;
}

export interface DeviceAction {
    location_id: string;
    device_id: string;
    location_name: string;
    device_name: string;
}

export interface LocationAction {
    location_id: string;
    location_name: string;
}
