import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor } from '@angular/common';
import { LoadingComponent } from '@components/loading/loading.component';

@Component({
    selector: 'app-page-layout',
    templateUrl: './page-layout.component.html',
    styleUrls: ['./page-layout.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, TranslateModule]
})
export class PageLayoutComponent implements OnInit {
    @Input() pageTitle = 'Page title goes here';
    @Input() tabOptions: string[] = [];
    @Output() tabChanged: EventEmitter<string> = new EventEmitter<string>();

    @ViewChildren('buttons') buttons: QueryList<ElementRef>;

    public view = '';
    constructor() {}

    toggleActiveInactive(currentView: string) {
        this.view = currentView;
        this.tabChanged.emit(currentView);
    }

    ngOnInit() {
        this.view = this.tabOptions[0];
    }
}
