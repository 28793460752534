import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CustomModalComponent } from '@components/misc/custom-modal/custom-modal.component';
import { loadStripe, Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';
import { LoadingComponent } from '@components/loading/loading.component';
import { NgIf } from '@angular/common';
import { BillingService } from '@services/billing/billing.service';
import { environment } from '../../../../../environments/environment';
import { StripeRegions } from '@shared_models/stripe';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { BillingCard } from '@shared_models/billing/billing';

@Component({
    selector: 'app-billing-card-modal',
    standalone: true,
    imports: [FormsModule, TranslateModule, CustomModalComponent, LoadingComponent, NgIf],
    templateUrl: './billing-card.component.html',
    styleUrl: './billing-card.component.scss'
})
export class BillingCardComponent implements OnInit {
    @Output() newCard: EventEmitter<BillingCard> = new EventEmitter<BillingCard>();
    @Input() card: BillingCard | null;

    stripe: Stripe;
    stripeElements: StripeElements;
    clientSecret: string;
    loading: boolean = false;
    initialLoading: boolean = true;
    user: DashboardUser = this.helperService.getUser();
    updateCard: boolean = false;

    constructor(
        private helperService: HelperService,
        protected translate: TranslateService,
        private billingService: BillingService
    ) {}
    async ngOnInit(): Promise<void> {
        const stripeRegion = this.user.settings.stripe_region;
        const stripeKey: string = stripeRegion === StripeRegions.EU ? environment.stripePublicKeyEU : stripeRegion === StripeRegions.US ? environment.stripePublicKeyUS : 'no key';
        this.stripe = await loadStripe(stripeKey);
        this.initialLoading = false;
        if (!this.card) await this.setClientSecret();
    }

    private async setClientSecret() {
        this.clientSecret = await this.billingService.cardSetupIntent();
        this.createStripeCardElement();
    }

    private createStripeCardElement() {
        const options = {
            clientSecret: this.clientSecret,
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap'
                }
            ]
        };

        const cardElementContainer = document.getElementById('card-element');
        if (cardElementContainer) {
            this.stripeElements = this.stripe.elements(options);
            // @ts-ignore
            const cardElement: StripeCardElement = this.stripeElements.create('card', {
                hidePostalCode: true,
                disableLink: true,
                style: {
                    base: {
                        color: '#03045E',
                        fontWeight: '400',
                        fontFamily: '"Barlow", sans-serif',
                        fontSize: '16px',
                        '::placeholder': {
                            color: '#bcc0c4'
                        }
                    }
                }
            });
            cardElement.mount('#card-element');
        } else {
            console.error('#card-element does not exist in the DOM');
        }
    }

    async saveCard(): Promise<BillingCard> {
        this.loading = true;
        const cardElement: StripeCardElement = this.stripeElements.getElement('card');
        return this.stripe
            .confirmCardSetup(this.clientSecret, {
                payment_method: {
                    card: cardElement
                }
            })
            .then(async result => {
                if (result.setupIntent && result.setupIntent.status === 'succeeded') {
                    this.card = await this.billingService.setCard(result.setupIntent.payment_method);
                    this.helperService.defaultHtmlToast('', this.translate.instant('billing.card_setup_success'), 'Success');
                    this.setClientSecret(); // sets up new client secret
                    this.loading = false;
                    return this.card;
                } else {
                    this.loading = false;
                    throw new Error('Card setup failed', { cause: result.setupIntent });
                }
            });
    }

    async handleUpdateCard() {
        this.updateCard = !this.updateCard;
        if (this.updateCard) this.setClientSecret();
    }
}
