<div class="expire-contract-box box">
    <div class="box-header">
        <app-custom-tool-tip header="{{ 'overview.expiring_contracts' | translate }}" description="{{ 'overview.performance_tooltip' | translate }}" placementUp="true" titleClass="line-title"></app-custom-tool-tip>
        <p class="pointer" (click)="onLinkClick()" [routerLink]="['/operator/contracts']" *ngIf="!isMobile">
            <u>{{ "overview.open_list" | translate }}</u>
            <img src="../../../../assets/icons/arrow-up-blue.svg" style="transform: rotate(90deg)" alt="{{ 'customers.company_name' | translate }}" />
        </p>
    </div>
    <div *ngIf="!loading" style="position: relative">
        <div class="empty-performance-overlay" *ngIf="contracts[0] === ''">
            <p>{{ "overview.no_expiring_contracts" | translate }}</p>
        </div>
        <table style="width: 100%">
            <tr>
                <th class="smaller-column">{{ "operator.contract_modal.contract_id" | translate }}</th>
                <th class="locations-column">{{ "misc.locations" | translate }}</th>
                <th class="smaller-column overflow-column">{{ "overview.expiration_date" | translate }}</th>
            </tr>
            <tbody>
                <tr *ngFor="let contract of contracts">
                    <td [routerLink]="['/operator/contracts']" [queryParams]="{ id: contract.contract_key }" class="pointer smaller-column overflow-column">
                        <u>{{ contract.contract_id }}</u>
                    </td>
                    <td class="locations-column">{{ contract.location_names ? contract.location_names.join(", ") : "" }}</td>
                    <td class="smaller-column">
                        <strong [style]="isExpiring(contract.end_date) ? 'color: #D81E5B;' : isSemiExpiring(contract.end_date) ? 'color: #FC9124;' : ''">{{ getPeriod(contract.end_date) }}&ensp;</strong>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <p class="pointer bottom-link" [routerLink]="['/operator/contracts']" *ngIf="isMobile">
        <u>{{ "overview.open_list" | translate }}</u>
        <img src="../../../../assets/icons/arrow-up-blue.svg" style="transform: rotate(90deg)" alt="{{ 'customers.company_name' | translate }}" />
    </p>
    <div *ngIf="loading" class="loading-box">
        <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
    </div>
</div>
