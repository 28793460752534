@if (loadForm) {
    <form [formGroup]="formGroup">
        <div style="margin-bottom: 1rem">
            @for (control of controls; track $index) {
                @if (control.showControl) {
                    @switch (control.controlType) {
                        @case ("text") {
                            <div class="input-group-wrapper-feedback">
                                <app-aw-form-label [control]="control"></app-aw-form-label>
                                <input [appControl]="control.events" [id]="control.key" [name]="control.key" class="form-control" [type]="control.type" [formControlName]="control.key" placeholder="-" />
                                <div class="feedback-container">
                                    @if (showError(control)) {
                                        @if (getFormError(control)) {
                                            <div class="error-msg">{{ getFormError(control) | translate }} {{ getFormError(control, "details") }}</div>
                                        }
                                    }
                                </div>
                            </div>
                        }

                        @case ("number") {
                            <!-- Not Tested, might have some adjustments to styling-->
                            <div class="input-group-wrapper-feedback">
                                <app-aw-form-label [control]="control"></app-aw-form-label>
                                <input [appControl]="control.events" [id]="control.key" [name]="control.key" class="form-control" [type]="control.type" [formControlName]="control.key" placeholder="-" />
                                <div class="feedback-container">
                                    @if (showError(control)) {
                                        @if (getFormError(control)) {
                                            <div class="error-msg">{{ getFormError(control) | translate }} {{ getFormError(control, "details") }}</div>
                                        }
                                    }
                                </div>
                            </div>
                        }

                        @case ("aw-radio-group") {
                            <div class="input-group-wrapper-feedback">
                                <app-aw-form-label [control]="control"></app-aw-form-label>

                                <div class="aw-radio-wrapper mobile-wrapper">
                                    @for (option of control.options; track option.key) {
                                        <div class="aw-radio">
                                            <input [appControl]="control.events" [id]="option.key" [name]="control.key" [formControlName]="control.key" type="radio" [value]="option.key" />
                                            <label [for]="option.key">
                                                <span class="aw-radio-label-span">{{ option.value | translate }}</span>
                                            </label>
                                        </div>
                                    }
                                </div>

                                <div class="feedback-container">
                                    @if (showError(control)) {
                                        @if (getFormError(control)) {
                                            <div class="error-msg">{{ getFormError(control) | translate }} {{ getFormError(control, "details") }}</div>
                                        }
                                    }
                                </div>
                            </div>
                        }

                        @case ("select") {
                            <div class="input-group-wrapper-feedback">
                                <app-aw-form-label [control]="control"></app-aw-form-label>
                                <select [appControl]="control.events" [id]="control.key" [name]="control.key" class="form-select" [formControlName]="control.key">
                                    @if (control.placeholder) {
                                        <option value="null" disabled selected>{{ control.placeholder | translate }}</option>
                                    }
                                    @for (item of control.options; track item.key) {
                                        <option [ngValue]="item.key">{{ item.value }}</option>
                                    }
                                </select>
                                <div class="feedback-container">
                                    @if (showError(control)) {
                                        @if (getFormError(control)) {
                                            <div class="error-msg">{{ getFormError(control) | translate }} {{ getFormError(control, "details") }}</div>
                                        }
                                    }
                                </div>
                            </div>
                        }
                        @case ("phone-number") {
                            @if (control.lowerType === "phone-number") {
                                @let controlCallCode = getControlPhone("call-code");
                                <div class="input-group-wrapper-feedback">
                                    <app-aw-form-label [control]="control"></app-aw-form-label>
                                    <div class="phone-number-wrapper">
                                        <select [appControl]="control.events" class="form-select call-code-select" [name]="controlCallCode.key" [id]="controlCallCode.key" [formControlName]="controlCallCode.key">
                                            <optgroup label="Most used countries">
                                                <option [ngValue]="fav.label" *ngFor="let fav of phoneNumberPicker.fav" [className]="fav.list !== 'fav' ? 'removeOption' : ''">
                                                    {{ fav.label }}
                                                </option>
                                            </optgroup>
                                            <optgroup label="All countries">
                                                <option [ngValue]="all.label" *ngFor="let all of phoneNumberPicker.all" [className]="all.list !== 'all' ? 'removeOption' : ''">
                                                    {{ all.label }}
                                                </option>
                                            </optgroup>
                                        </select>

                                        <div style="position: relative">
                                            <input [appControl]="control.events" [id]="control.key" placeholder="-" [name]="control.key" class="form-control" type="text" [formControlName]="control.key" />
                                        </div>
                                    </div>
                                    <div class="feedback-container">
                                        @if (showError(control)) {
                                            @if (getFormError(control)) {
                                                <div class="error-msg">{{ getFormError(control) | translate }} {{ getFormError(control, "details") }}</div>
                                            }
                                        }
                                    </div>
                                </div>
                            }
                        }

                        <!-- @case ("aw-checkbox") {
                            <div class="input-group-wrapper-feedback">
                                <div class="checkbox mb-0">
                                    <app-aw-checkbox id="terms_check" [text]="control.label" [initialCheckedValue]="termsChecked" (checkStateEmitter)="toggleConsent('terms')" />
                                    <label for="terms_check">
                                        {{ "customers-create.accept" | translate }}
                                        <a class="primary-color" href="{{ termsOfUse }}" target="_blank" tabindex="-1">
                                            {{ "customers-create.terms_n_conds" | translate }}
                                        </a>
                                    </label>
                                </div>
                                <div class="feedback-container">
                                    <div class="error-msg" *ngIf="formSubmitted && !termsChecked">
                                        {{ "customers-create.terms_need_accept" | translate }}
                                    </div>
                                </div>
                            </div>
                        } -->

                        @case ("custom") {
                            <ng-content [select]="control.key"></ng-content>
                        }
                    }
                }
            }
        </div>

        <div class="d-flex flex-column align-items-center">
            <button class="btn btn-secondary" style="width: 100%" [disabled]="isFetching()" (click)="validateForm()">
                {{ isFetching() ? "" : (buttonLabel() | translate) }}
                @if (isFetching()) {
                    <loading-indicator [size]="'large'" [color]="'white'"></loading-indicator>
                }
            </button>
        </div>
    </form>
}
