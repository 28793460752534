import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SearchResult } from 'shared_models/search';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { HelperService } from 'src/app/services/helper/helper.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SearchService } from 'src/app/services/search/search-service';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';
import { AccountState } from 'shared_models/details';
import { SearchSuggestions, searchSuggestions } from './search-suggestions';
import { NgIf, NgFor, KeyValuePipe, AsyncPipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'app-search-preview',
    templateUrl: './search-preview.component.html',
    styleUrls: ['./search-preview.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, KeyValuePipe, TranslateModule, AsyncPipe]
})
export class SearchPreviewComponent implements OnInit {
    @Input() searchResult: SearchResult;
    @Input() searchResultAmount: number;
    @Input() searchQuery: string;
    @Input() showSearchSuggestions: BehaviorSubject<boolean>;
    @Output() itemClickedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() searchSuggestionSelected: EventEmitter<string> = new EventEmitter<string>();

    isOperator$: Observable<boolean> = this.authService.isOperator;
    currentChoosenId: string;
    shownQuery: string;
    user: DashboardUser;
    resultMap: Record<string, number> = {};
    previewLimit = 3;
    ready = false;
    startedNavigating = false;
    liList: any;
    lastElement: HTMLLIElement;
    hasResult: boolean;
    isMobile = false;
    searchSuggestions: SearchSuggestions = searchSuggestions;
    finishedSearching = false;

    constructor(
        protected authService: AuthService,
        public translate: TranslateService,
        public helperService: HelperService,
        private mixpanelService: MixpanelService,

        private router: Router,
        private breakpointObserver: BreakpointObserver,
        public searchService: SearchService
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    async ngOnInit() {
        this.user = this.helperService.getUser();
        await this.setupMap();
        this.shownQuery = this.searchQuery;
        if (!this.isMobile) {
            document.addEventListener('keydown', (e: KeyboardEvent) => {
                this.handleKey(e);
            });
        }
        this.isThereData();
        this.currentChoosenId = this.isMobile ? '' : this.resultMap['all'] ? `${this.resultMap['all']}-0` : '';
        const sub = this.searchService.searchFinished
            .asObservable()
            .subscribe(res => {
                if (res) {
                    this.finishedSearching = true;
                }
            })
            .unsubscribe();
    }

    async setupMap() {
        return new Promise<void>((resolve, reject) => {
            let count = 1;
            this.resultMap['all'] = 0;
            for (const key in this.searchResult) {
                const element = this.searchResult[key];
                this.resultMap[key] = count;
                count++;
            }
            resolve();
        });
    }

    isThereData() {
        console.log('search results', this.searchResult);
        if (this.searchResult) {
            Object.keys(this.searchResult).forEach(key => {
                if (this.searchResult[key].length > 0) {
                    this.hasResult = true;
                }
            });
            this.ready = true;
        } else {
            this.hasResult = false;
        }
    }

    async navigate(cat: string, item: unknown, id: string) {
        this.mixpanelService.track(`Search Category: ${cat}`, { category: cat });
        this.mixpanelService.identify(); // Associate actions with the user

        interface RouteType {
            path: string;
            params?: object;
        }
        const route: RouteType = await this.searchService.getPath(cat, item, this.user.uid);

        if (route.path.length <= 0 && Object.keys(route.params).length <= 0) {
            this.itemClickedEvent.emit(true);
            return;
        }

        this.searchService.setStopSearch(true);

        if (this.isMobile) {
            setTimeout(() => {
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate([route.path], { queryParams: route.params });
                });
                this.currentChoosenId = '';
                this.itemClickedEvent.emit(true);
            }, 200);
        } else {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([route.path], { queryParams: route.params });
            });

            this.itemClickedEvent.emit(true);
        }
    }

    viewFullSearch(id: string) {
        this.mixpanelService.track(`Search Category: full view`, { search_value: this.searchQuery });
        this.mixpanelService.identify(); // Associate actions with the user

        if (this.isMobile) {
            this.currentChoosenId = id;
            setTimeout(() => {
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['search'], {
                        queryParams: {
                            searchQuery: this.shownQuery
                        }
                    });
                });
                this.itemClickedEvent.emit(true);
                this.currentChoosenId = '';
            }, 200);
        } else {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['search'], {
                    queryParams: {
                        searchQuery: this.shownQuery
                    }
                });
            });
            this.itemClickedEvent.emit(true);
        }
    }

    async changeHoverStyle(event: MouseEvent) {
        if (!this.liList) {
            this.liList = document.querySelectorAll('.ul-list li');
        }

        if (this.isMobile) {
            return;
        }

        if (event.type === 'mouseenter') {
            this.currentChoosenId = (event.target as HTMLElement).id;
            this.lastElement = event.target as HTMLLIElement;
        } else if (event.type === 'mouseleave') {
            this.currentChoosenId = '';
            this.lastElement = null;
        }
    }

    async handleKey(event: KeyboardEvent) {
        if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown' && event.key !== 'Enter') {
            return;
        }

        if (!this.liList) {
            this.liList = document.querySelectorAll('.ul-list li');
        }

        if (this.isMobile) {
            return;
        }

        if (this.currentChoosenId === '' || !this.lastElement) {
            this.currentChoosenId = this.resultMap['all'] ? `${this.resultMap['all']}-0` : this.searchSuggestions[0].id;
            this.lastElement = this.liList[0];
            return;
        }

        let index: number = this.lastElement ? [].indexOf.call(this.liList, this.lastElement) : 0;

        switch (event.key) {
            case 'ArrowUp':
                //Prevents the scroll bar to also be navigated
                event.preventDefault();

                // Set up a counter to keep track of which <li> is selected
                if (index > 0) {
                    index--; // Decrease the counter
                } else {
                    index = this.liList.length - 1; // Go to the last <li> when at the top
                }

                this.lastElement = this.liList[index];
                this.currentChoosenId = this.lastElement.id;
                break;

            case 'ArrowDown':
                //Prevents the scroll bar to also be navigated
                event.preventDefault();

                if (index < this.liList.length - 1) {
                    index++; // Increase counter
                } else {
                    index = 0; // Go to the first <li> when at the bottom
                }
                this.lastElement = this.liList[index];
                this.currentChoosenId = this.lastElement.id;
                break;
            case 'Enter':
                if (this.liList[index]) {
                    console.log('enter event');
                    if (!this.searchSuggestions.some(item => item.id === this.currentChoosenId)) {
                        // should only emit if it is a search result select and not a suggestion select
                        console.log('emitting');
                        (this.liList[index] as HTMLElement).click();
                        this.itemClickedEvent.emit(true);
                    } else {
                        console.log('suggestion selected');
                        event.preventDefault();
                        const shortCode: string = this.searchSuggestions.find(item => item.id === this.currentChoosenId)?.short_code || '';
                        this.searchSuggestionSelected.emit(shortCode);
                    }
                }
                break;
        }
    }

    isCurrentId(id: string): boolean {
        if (id === this.currentChoosenId) {
            return true;
        } else {
            false;
        }
    }

    getStatusString(status: AccountState): string {
        return this.translate.instant(`customers.${status}`);
    }

    roundPerform(num: number | null): string {
        return num ? String(`${num < 0 ? '' : '+'}${this.helperService.roundToTwoDecimals(num * 100)}%`) : '';
    }

    getDate(timestamp: number): string {
        return this.helperService.getLocalizedDate(timestamp);
    }

    selectSuggestion(shortCode: string) {
        this.searchSuggestionSelected.emit(shortCode);
    }
}
