import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { environment } from '../../../environments/environment';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SetPasswordParams } from 'shared_models/sub-customer';
import { HelperService } from 'src/app/services/helper/helper.service';
import { LoadingComponent } from '../loading/loading.component';
import { NgIf } from '@angular/common';
import { LocalStorageService } from '@services/local-storage/local-storage.service';

@Component({
    selector: 'app-customers-set-password',
    templateUrl: './customers-set-password.component.html',
    styleUrls: ['./customers-set-password.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, LoadingComponent, LottieComponent, TranslateModule]
})
export class CustomersSetPasswordComponent implements OnInit, OnDestroy {
    options: AnimationOptions = {
        // https://www.npmjs.com/package/ngx-lottie
        path: '../../assets/animations/AW_waves.json'
    };
    loading: boolean;
    setPasswordDone: boolean;
    formSubmitted: boolean;
    setPasswordForm: FormGroup;
    supportPhone: {
        phoneNumber: string;
        telLink: string;
    };
    email: string;
    operatorUid: string;
    pickedRegion: 'us' | 'eu' = 'eu';
    euTermsOfUse: string = environment.euTermsOfUse;
    usTermsOfUse: string = environment.usTermsOfUse;
    gdprPolicy: string = environment.gdprPolicy;
    gdprChecked: boolean;
    termsChecked: boolean;

    constructor(
        private customerService: CustomerService,
        private localStorageService: LocalStorageService,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private toast: ToastrService,
        private router: Router,
        private authService: AuthService,
        private translate: TranslateService,
        private helperService: HelperService
    ) {}

    ngOnDestroy(): void {}

    ngOnInit(): void {
        this.loading = true;
        this.supportPhone = environment.supportPhone;
        this.email = this.route.snapshot.paramMap.get('email');
        this.operatorUid = this.route.snapshot.paramMap.get('operatorUid');
        this.pickedRegion = this.route.snapshot.paramMap.get('region') as 'us' | 'eu'; // same as operator region
        this.setPasswordForm = this.formBuilder.group({
            password: [null, [Validators.required, Validators.maxLength(255)]],
            repeatPassword: [null, Validators.required]
        });

        this.loading = false;
        this.onChanges();
    }

    onChanges(): void {
        this.setPasswordForm.valueChanges.subscribe(val => {
            const repeatPassword: string = val.repeatPassword;
            const password: string = val.password;

            if (password === repeatPassword && !this.setPasswordForm.pristine) {
                // valid state
                this.setPasswordForm.get('repeatPassword').setErrors(null);
                this.setPasswordForm.get('repeatPassword').setValidators([Validators.required]);
            }

            if (this.formSubmitted && !this.setPasswordForm.get('password').pristine && this.helperService.isStrongPassword(password).is_strong) {
                // valid state
                this.setPasswordForm.get('password').setErrors(null);
                this.setPasswordForm.get('password').setValidators([Validators.required, Validators.minLength(12), Validators.maxLength(255)]);
            }
        });
    }

    setPassword() {
        this.formSubmitted = true;

        // Validating password
        if (!this.helperService.isStrongPassword(this.password.value).is_strong) {
            this.setPasswordForm.get('password').setErrors({ weakPassword: true });
        } else if (this.password.value !== this.repeatPassword.value) {
            // no match!
            this.setPasswordForm.get('repeatPassword').setErrors({ pwNoMatch: true });
        }

        if (this.setPasswordForm.valid && this.gdprChecked && this.termsChecked) {
            this.loading = true;
            const passwordParams: SetPasswordParams = {
                password: this.password.value,
                email: this.email,
                operatorUid: this.operatorUid
            };
            this.customerService
                .setPassword(passwordParams)
                .then(() => {
                    this.loading = false;
                    this.setPasswordDone = true;
                    this.toast.success(this.translate.instant('customers-set-password.password_succes'), this.translate.instant('misc.success'));
                })
                .catch(err => {
                    const { error, status } = err;
                    let errorMsg: string;

                    console.log(error.error);

                    switch (error.error) {
                        case 'password_set':
                            this.router.navigate(['/sign-in']).then(() => {
                                this.localStorageService.setItem('passwordSet', 'true');
                                window.location.reload();
                            });
                            break;

                        default:
                            errorMsg = error.error.message;
                            this.toast.warning(this.translate.instant('customers-set-password.password_error'), this.translate.instant('misc.attention'));
                            break;
                    }

                    this.loading = false;
                });
        }
    }

    toggleConsent(typeOfConsent: string) {
        if (typeOfConsent == 'terms' && !this.termsChecked) {
            this.termsChecked = true;
        } else if (typeOfConsent == 'terms' && this.termsChecked) {
            this.termsChecked = false;
        }

        if (typeOfConsent == 'gdpr' && !this.gdprChecked) {
            this.gdprChecked = true;
        } else if (typeOfConsent == 'gdpr' && this.gdprChecked) {
            this.gdprChecked = false;
        }
    }

    async signIn() {
        this.loading = true;
        this.authService.signOut(true).then(async () => {
            // Sign out before signing in to avoid two active sessions at the same time
            await this.wait(200);
            this.authService
                .signIn(this.email, this.password.value, true)
                .then(() => {
                    this.router.navigate(['/sign-in']);
                })
                .catch(errorMsg => {
                    this.toast.warning(errorMsg ? errorMsg : this.translate.instant('customers-set-password.login_fail'), this.translate.instant('misc.attention'), {
                        timeOut: 999999
                    });
                    this.loading = false;
                });
        });
    }
    async wait(ms: number): Promise<void> {
        return new Promise<void>(resolve => setTimeout(resolve, ms));
    }

    get password() {
        return this.setPasswordForm.get('password');
    }
    get repeatPassword() {
        return this.setPasswordForm.get('repeatPassword');
    }
}
