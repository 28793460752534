import Stripe from 'stripe';
import { Coupon } from './coupon';
import { Status } from './order';

export type Titles = 'top_spender' | 'test_user' | 'high_refunds' | 'blocked' | 'new_user' | 'no_title';
export type StatusTypes = 'refund' | 'comment' | 'payment_failed' | 'wallet_payment_failed' | 'payment_method_successful' | 'user_creation';
export interface ShallowActivityDetails {
    status_type: StatusTypes;
    author?: string;
    comment?: string;
    amount?: number;
}

export interface RecentActivityDetails {
    id: string;
    status_type: StatusTypes;
    author?: string;
    amount?: number;
    message?: string;
    payment_id?: string;
    description?: string;
    next_steps?: string;
    currency?: string;
    timestamp: number;
    last4?: string;
    paymentType?: Stripe.PaymentMethod.Card.Wallet.Type | string;
    decline_code?: string;
}

export interface AllowedUpdateFields {
    user_type?: null; // old should be removed
    platform?: 'app' | 'terminal';
    refunds?: Record<string, number>;
    refunds_sum?: number;
    blocked?: boolean;
    pm?: 'no_payment_type' | 'test';
    transactions?: UserTransaction[];
    user_title?: 'top_spender' | 'high_refunds' | 'new_user' | 'no_title';
    recent_activity_list?: Record<string, RecentActivityDetails>;
    available_coupons?: Coupon[];
    applied_coupons?: Coupon[];
}

export interface FireUser {
    last_activity: number;
    uid: string;
    name: string;
    lname: string; // lowercased name to be able to sort on query level
    phone: string;
    call_code: string;
    platform: 'app' | 'terminal';
    // spend: number; // debrecated
    total_spend: Record<string, number>;
    total_spend_sum: number;
    payments: number;
    refund: Record<string, number>; // track on each currency
    refunds: number; // count of refunds
    refunds_sum: number; //
    created: number;
    currency: string;
    blocked: boolean;
    stripe_cus_id: string | null;
    owner_uid: string; // the customer who owns that user object, on normal customers/subcustomer this is used as base to control access, // and is always used oif filter 'customer_id' is set
    operator_owner_uid: string; // on opeartors this this value is used as base to control access, this value is always populated, on normal customers it is the same as owner_uid on sub customer it is the operators uid, on operators it is the same as owner_uid
    location_id: string | null;
    pm: paymentTypes;
    last4: string | null; //
    pm_exp_date: string | null;
    pm_fingerprint: string | null;
    wallet: WalletTypes | null;
}

export interface DetailedUser extends FireUser {
    transactions: UserTransaction[];
    user_title: 'top_spender' | 'test_user' | 'high_refunds' | 'blocked' | 'new_user' | 'no_title';
    subcustomer_name?: string;
    recent_activity_list: RecentActivityDetails[];
    available_coupons: Coupon[];
    applied_coupons: Coupon[];
}

export interface UserTransaction {
    payment_id: string;
    timestamp: number;
    amount: number;
    currency: string;
    location_name: string;
    device_name: string;
    refund: boolean;
    user_id: string;
    uid: string;
    refund_status?: Status;
    id: string;
}

export type WalletTypes = 'apple_pay' | 'google_pay' | 'samsung_pay' | 'link';

export const paymentTypesList: paymentTypes[] = ['no_payment_type', 'test', 'visa', 'credit', 'giropay', 'mastercard', 'blik', 'ideal', 'bancontact', 'credit', 'maestro', 'amex', 'link', 'paypal'];

export type paymentTypes =
    | 'no_payment_type'
    | 'test'
    | 'credit'
    | 'visa'
    | 'giropay'
    | 'mastercard'
    | 'blik'
    | 'ideal'
    | 'bancontact'
    | 'credit'
    | 'maestro'
    | 'american_express' // app is mixing the names; 'american_express', 'amex' or 'americanexpress'. All are valid and the same
    | 'americanexpress' // app is mixing the names; 'american_express', 'amex' or 'americanexpress'. All are valid and the same
    | 'amex' // app is mixing the names; 'american_express', 'amex' or 'americanexpress'. All are valid and the same
    | 'link'
    | 'paypal';

export interface UserPaymentMethod {
    wallet?: WalletTypes;
    exp_date?: string;
    last4?: string | null;
    in_use: boolean;
    type: paymentTypes;
    fingerprint?: string;
}

export interface Card {
    exp_date: string;
    last4: string;
    in_use: boolean;
    type: string;
}

export interface Credit {
    credit_user_id: string;
    in_use: boolean;
    payment_code: string;
    type: 'credit';
}
export interface ApplePay {
    in_use: boolean;
    type: 'applepay';
}

export interface GiroPay {
    in_use: boolean;
    type: 'giropay';
}

export enum UserSortParams {
    name = 'name',
    phone_no = 'phone_no',
    platform = 'platform',
    payment_method = 'payment_method',
    total_spend_sum = 'total_spend_sum',
    payments = 'payments',
    refunds_sum = 'refunds_sum',
    created = 'created'
}

export enum PaginateNavAction {
    first = 'first',
    next = 'next',
    previous = 'previous',
    last = 'last'
}

export interface UserStoreOptions {
    order_by: UserSortParams;
    direction: 'asc' | 'dsc';
    page_size: number;
    customer: 'all' | string;
    location: 'all' | string;
}

export interface UsersQueryResult {
    users: FireUser[];
    total: number;
    next_cursor?: string;
    prev_cursor?: string;
}

export interface ShallowAppUser {
    displayName: string;
    phoneNumber: string;
}

export interface UserCreate {
    name: string;
    phone_number: string;
    phone_number_call_code: string;
    customer: string;
}
