import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Coupon, CouponSortParams, CouponsQueryResult } from '../../../../../shared_models/coupon';
import { CouponService } from '../../../services/settings/coupon/coupon.service';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper/helper.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { FilterOption, FilterType } from '../../../../../shared_models/aw-components/filterOption';
import { FilterSortParams, Sort } from '../../../../../shared_models/search-params/FilterSortParams';
import { TableHeaderOptions } from '../../../../../shared_models/aw-components/tableHeaderOptions';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { PaginatePipe } from '../../../pipe/paginate.pipe';
import { CouponFormComponent } from './coupon-form/coupon-form.component';
import { LoadingComponent } from '../../loading/loading.component';
import { AwTableComponent } from '../../misc/aw-table/aw-table.component';
import { AwFilterButtonComponent } from '../../misc/aw-filter-button/aw-filter-button.component';
import { PageLayoutComponent } from '../../misc/aw-page-layout/page-layout.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-coupon',
    templateUrl: './coupon.component.html',
    styleUrls: ['./coupon.component.scss'],
    standalone: true,
    imports: [NgIf, PageLayoutComponent, AwFilterButtonComponent, AwTableComponent, NgFor, MatPaginator, LoadingComponent, CouponFormComponent, TranslateModule, PaginatePipe]
})
export class CouponComponent implements OnInit {
    coupons: Coupon[] = [];
    couponCount = 0;
    isMobile: boolean;
    initLoading: boolean;
    pageSizeOptions = [10, 50, 100];
    pageSize = this.pageSizeOptions[0];
    pageNumber = 0;
    terms: string;
    loadingCoupons = true;
    couponId: string;
    sortingAscending: boolean;
    sortBy: CouponSortParams = CouponSortParams.CREATED;
    isEdit = false;
    specificCouponParams: any;
    couponCodes: string[] = [];
    tableHeaderOptions: TableHeaderOptions[] = [
        {
            sortKey: 'lname',
            title: this.translate.instant('coupon.name'),
            width: '20%'
        },
        {
            sortKey: '',
            title: this.translate.instant('coupon.terms'),
            width: '40%'
        },
        {
            sortKey: 'entries.users.count',
            title: this.translate.instant('coupon.entries'),
            width: '10%',
            alignment: 'right'
        },
        {
            sortKey: 'redemptions',
            title: this.translate.instant('coupon.redemptions'),
            width: '10%',
            alignment: 'right'
        },
        {
            sortKey: 'duration.to',
            title: this.translate.instant('coupon.expire'),
            width: '10%',
            alignment: 'right'
        },
        {
            sortKey: 'created',
            title: this.translate.instant('coupon.created'),
            width: '10%',
            alignment: 'right'
        }
    ];

    filterOptions: FilterOption[] = [
        {
            key: 'created',
            type: FilterType.DATE_RANGE,
            value: null,
            label: 'coupon.created',
            selectOptions: [],
            isDateRange: true
        },
        {
            key: 'archived',
            type: FilterType.SELECT,
            value: 'false',
            label: 'coupon.archived',
            clearable: false,
            selectOptions: [
                { value: 'false', label: 'false' },
                { value: 'true', label: 'true' }
            ]
        },
        {
            key: 'locations',
            type: FilterType.SELECT_MULTIPLE,
            value: null,
            label: 'navbar.locations',
            clearable: false,
            selectOptions: []
        }
    ];

    params: FilterSortParams = {
        filter: { archived: 'false' },
        sortBy: { key: 'created', order: 'desc' },
        pageSize: this.pageSize,
        pageNumber: this.pageNumber
    };

    constructor(
        private router: Router,
        private couponService: CouponService,
        private breakpointObserver: BreakpointObserver,
        protected helperService: HelperService,
        protected translate: TranslateService
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    async ngOnInit(): Promise<void> {
        this.initLoading = true;
        Promise.all([this.getCouponFilters(), this.getCoupons()]);
        this.initLoading = false;
    }

    async getCouponFilters() {
        await this.couponService.getCouponFilters().then((response: { value: string; label: string }[]) => {
            this.filterOptions.find(option => option.key === 'locations').selectOptions = response;
        });
    }

    async getCoupons(): Promise<void> {
        this.loadingCoupons = true;
        await this.couponService
            .getCoupons(this.params)
            .then((response: CouponsQueryResult) => {
                this.coupons = response.coupons;
                this.couponCount = response.total;
                if (response.next_cursor) this.params.next_cursor = response.next_cursor;
                if (response.prev_cursor) this.params.prev_cursor = response.prev_cursor;
            })
            .catch(err => {
                console.error(err);
                this.helperService.defaultHtmlToast(this.translate.instant('billing.something_wrong'), `${this.translate.instant('billing.try_again')}`, 'Warning');
            })
            .finally(() => {
                this.initLoading = false;
                this.loadingCoupons = false;
            });
    }
    navigateToCouponCreation() {
        this.router.navigate(['coupon/coupon-create']);
    }

    navigateToCouponEdit(couponParams?: Coupon) {
        this.couponService.setSelectedCoupon(couponParams);
        this.router.navigate(['/coupon', couponParams?.key]);
        this.isEdit = true;
        this.specificCouponParams = couponParams;
    }

    getTerms(coupon: Coupon): string {
        return this.helperService.parseTranslationArray(coupon.description);
    }

    shortenTerms(fullTerms: string) {
        const maxLength = 57;
        return fullTerms.length > maxLength ? `${fullTerms.substring(0, maxLength)}...` : fullTerms;
    }

    //region Table Functions
    async catchFilterChanged(event: any) {
        if (JSON.stringify(this.params.filter) !== JSON.stringify(event)) {
            this.pageNumber = 0;
        }
        this.params.filter = event;
        this.params.pageNumber = this.pageNumber;
        await this.getCoupons();
    }

    async handlePage(e: PageEvent) {
        let action: 'next' | 'prev' | 'first' | 'last' = 'next';
        if (this.params.pageSize !== e.pageSize) {
            this.pageSize = e.pageSize;
            this.pageNumber = 0;
            this.params.prev_cursor = null;
            this.params.next_cursor = null;
        } else {
            if (e.pageIndex - 1 === e.previousPageIndex) action = 'next';
            if (e.pageIndex + 1 === e.previousPageIndex) action = 'prev';
            if (e.pageIndex < e.previousPageIndex - 1) action = 'first';
            if (e.pageIndex > e.previousPageIndex + 1) action = 'last';
        }
        this.params.pageNumber = e.pageIndex;
        this.params.pageSize = e.pageSize;
        this.params.action = action;
        await this.getCoupons();
    }

    async catchSortChanged(event: Sort) {
        if (this.params.sortBy.key !== event.key) {
            this.pageNumber = 0;
        }
        this.params.sortBy = event;
        this.params.pageNumber = this.pageNumber;
        await this.getCoupons();
    }
    //endregion
}
