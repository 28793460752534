import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgIf, NgClass, NgStyle } from '@angular/common';

/**
 * @description
 * A component that represents a checkbox with customizable properties.
 *
 * Usable input parameters:
 * - `checkedBackgroundColor1` {string}: First color chosen to fill the checkbox when it is checked.
 * - `checkedBackgroundColor2` {string}: Second color chosen to fill the checkbox when it is checked.
 * - `text` {string}: Text to be displayed next to the checkbox.
 * - `textPosition` {'before' | 'after'}: Position of the text relative to the checkbox. Can be 'before' or 'after'.
 * - `initialCheckedValue` {boolean}: Initial value of the checkbox i.e. whether the checkbox is ticked or not when it is initially displayed.
 *
 * @example
 * <app-aw-checkbox
 *     [checkedBackgroundColor1]="'#FF0000'"
 *     [checkedBackgroundColor2]="'#00FF00'"
 *     [text]="'Text goes here'"
 *     [textPosition]="'before'"
 *     [initialCheckedValue]="true"
 *     (checkStateEmitter)="toggleDeviceSelectionAll($event)"
 * />
 */
@Component({
    selector: 'app-aw-checkbox',
    templateUrl: './aw-checkbox.component.html',
    styleUrls: ['./aw-checkbox.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, NgStyle]
})
export class AwCheckboxComponent implements OnInit {
    @Input() checkedBackgroundColor1 = 'var(--primary-color-dark)';
    @Input() checkedBackgroundColor2 = 'var(--primary-color-light)';
    @Input() text = '';
    @Input() textPosition: 'before' | 'after' = 'after';
    @Input() initialCheckedValue = false;
    @Input() checkbox = false;
    @Input() disabled = false;
    @Input() partial = false; // setting the color to blue, a third state for the checkbox, used in the billing table as a select all nut (except some that is unselected)
    @Input() noTextFormatting = false;

    @Output() checkStateEmitter = new EventEmitter<boolean>();

    ngOnInit() {
        this.checkbox = this.initialCheckedValue;
    }

    toggleCheckbox() {
        this.checkbox = !this.checkbox;
        this.checkStateEmitter.emit(this.checkbox);
    }
}
