import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SearchService } from '../../services/search/search-service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SearchResult } from 'shared_models/search';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import * as Search from 'shared_models/search';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { HelperService } from 'src/app/services/helper/helper.service';
import { SearchCategoryResultComponent } from '@components/search/search-category-result/search-category-result.component';
import { LoadingComponent } from '@components/loading/loading.component';
import { NgIf, NgFor, KeyValuePipe } from '@angular/common';

type Categories = `${keyof typeof Search.SearchTypes}s`;

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingComponent, NgFor, SearchCategoryResultComponent, MatPaginator, KeyValuePipe, TranslateModule]
})
export class SearchComponent implements OnInit, OnDestroy {
    searchQuery = '';
    results: SearchResult;
    previewResults: SearchResult;
    showLoadingIndicator = true;
    categoryChoosen: Categories;
    showingResults = false;
    routeSub: Subscription;
    isDataAvailable: boolean;
    isMobile: boolean;
    onButton = false;
    pageSizeOptions: number[] = [20, 100];
    pageSize = 20;
    pageIndex: number;
    user: DashboardUser;
    rates: Record<string, number>;
    sliceStart = 0;
    sliceEnd = 20;

    constructor(
        public translate: TranslateService,
        private router: Router,
        public searchService: SearchService,
        private route: ActivatedRoute,
        private breakpointObserver: BreakpointObserver,
        private cdr: ChangeDetectorRef,
        public helperService: HelperService
    ) {
        this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    async ngOnInit(): Promise<void> {
        this.user = this.helperService.getUser();
        this.rates = (await this.helperService.getCurrencyConversionRates()).rates;
        const route = this.router.url.split('/').pop().split('?')[0];
        this.selectCategory(route as Categories);

        //Controls when we go to a category and when we go back to view all
        this.routeSub = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                const route = event.url.split('/').pop().split('?')[0];
                console.log(route);
                this.searchQuery = decodeURIComponent(event.url.split('?searchQuery=')[1]);
                this.selectCategory(route as Categories);
            }
        });
        //await this.searchService.getSearchResults('')
        const routerSub = this.route.queryParams.pipe(take(1)).subscribe(res => {
            this.searchQuery = res['searchQuery'];
        });
        routerSub.unsubscribe();

        this.searchService.searchResult.subscribe(res => {
            if (res) {
                this.results = res;
                this.previewResults = this.searchService.getPreviewResults(res);
                if (this.isDataPresent(res)) {
                    this.isDataAvailable = true;
                    this.showLoadingIndicator = false;
                } else {
                    this.isDataAvailable = false;
                    this.showLoadingIndicator = false;
                }
            }
        });
    }

    isDataPresent(result: SearchResult) {
        let isEmpty = true;
        if (Object.keys(result).length > 0) {
            Object.keys(result).forEach(key => {
                if (result[key].length > 0) {
                    isEmpty = false;
                }
            });
            return !isEmpty;
        } else {
            return !isEmpty;
        }
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }

    view(nav: string) {
        this.categoryChoosen = nav as Categories;
        this.showingResults = true;
        // this.results = this.searchService.getSearchResults();
        this.router.navigate([`search/${nav}`], { queryParams: { searchQuery: this.searchQuery } });
        this.cdr.detectChanges();
        // this.searchQuery = this.translate.instant('misc.' + nav);
    }

    isChoosenCategory(category: Categories): boolean {
        if (!this.showingResults) return true;
        return category === this.categoryChoosen;
    }

    selectCategory(route: Categories) {
        if (this.isCategoryValid(route)) {
            this.showingResults = true;
            this.categoryChoosen = route;
        } else {
            this.showingResults = false;
            this.categoryChoosen = undefined;
        }
    }

    isCategoryValid(category: string): category is Categories {
        const validCategories = Object.keys(Search.SearchTypes).map(key => key + 's');
        return validCategories.includes(category);
    }

    handlePaginate(paginateEvent: PageEvent) {
        if (paginateEvent.previousPageIndex !== paginateEvent.pageIndex) {
            //Go to index 0 if pageIndex === 0
            if (paginateEvent.pageIndex === 0) {
                this.sliceStart = 0;
                this.sliceEnd = paginateEvent.pageSize;
            }
        }

        if (paginateEvent.pageIndex == this.pageIndex + 1) {
            // Next page
            this.sliceStart = this.sliceEnd;
            this.sliceEnd = this.sliceEnd + paginateEvent.pageSize;
        } else if (paginateEvent.pageIndex == this.pageIndex - 1) {
            // Previous page
            if (this.sliceStart !== 0) {
                this.sliceEnd = this.sliceStart;
                this.sliceStart = this.sliceStart - paginateEvent.pageSize;
            }
        } else if (paginateEvent.pageIndex > this.pageIndex + 1) {
            this.sliceStart = paginateEvent.length - paginateEvent.pageSize;
            this.sliceEnd = paginateEvent.length;
        }

        if (paginateEvent.pageSize !== this.pageSize) {
            // page size is changed
            this.pageSize = paginateEvent.pageSize;
        }

        this.pageIndex = paginateEvent.pageIndex;
        this.cdr.detectChanges();
    }
}
