import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '@services/local-storage/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class MixpanelService {
    private readonly token = environment.mixpanelToken;

    constructor(private localStorageService: LocalStorageService) {
        this.init();
    }

    private init(): void {
        mixpanel.init(this.token, {
            debug: environment.production ? false : true // Set to false in production
        });
    }

    disableMixpanel(): boolean {
        return !!this.localStorageService.getItem('user')?.email.includes('@airwallet.net');
    }

    track(event: string, properties?: Record<string, any>): void {
        if (this.disableMixpanel()) return; // Mixpanel is disabled
        const defaultProperties = {
            Email: this.localStorageService.getItem('loggedInUser')?.email,
            'Customer Name': this.localStorageService.getItem('loggedInUser')?.company_name,
            'Operator ID': this.localStorageService.getItem('loggedInUser')?.uid.includes('_operated_by_') ? this.localStorageService.getItem('loggedInUser')?.uid.split('_operated_by_')[1] : 'not operated',
            Platform: this.localStorageService.getItem('user')?.settings.stripe_region
        };
        mixpanel.track(event, { ...properties, ...defaultProperties });
    }

    identify(userId?: string): void {
        if (this.disableMixpanel()) return; // Mixpanel is disabled
        mixpanel.identify(userId ?? this.localStorageService.getItem('loggedInUser')?.uid);
    }

    setUserProperties(properties: Record<string, any>): void {
        if (this.disableMixpanel()) return; // Mixpanel is disabled
        mixpanel.people.set(properties);
    }

    setEventSuperProperties(properties: Record<string, any>): void {
        if (this.disableMixpanel()) return; // Mixpanel is disabled
        mixpanel.register(properties);
    }

    reset(): void {
        mixpanel.reset();
    }
}
