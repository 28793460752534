import { NgFor, NgIf, NgStyle } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { StripeRegions } from 'shared_models/stripe';
import { HelperService } from 'src/app/services/helper/helper.service';
import { SupportService } from 'src/app/services/support/support.service';
import { loadingGifInBase64String } from './loading-gif-string';
import { LocationService } from '@services/location/location.service';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgFor, NgIf, NgStyle, TranslateModule]
})
export class SupportComponent implements OnInit, OnDestroy {
    gifStr: string = loadingGifInBase64String;
    loading = false;
    formSubmitted: boolean;
    supportForm: UntypedFormGroup;
    formSub: Subscription;
    actions: { method: string; endpoint: string; label: string; options?: string; group: string; groupKey: string }[] = [
        // Account
        { method: 'DELETE', endpoint: 'api_account/delete_account', label: 'support.delete_cus', group: 'support.group_account', groupKey: 'Account' },
        { method: 'PUT', endpoint: 'api_support/update_agreement_type', label: 'support.update_agreement', group: 'support.group_account', groupKey: 'Account' },
        { method: 'POST', endpoint: 'api_support/check_available_for_payout', label: 'support.check_available_for_payout', group: 'support.group_account', groupKey: 'Account' },
        { method: 'POST', endpoint: 'api_support/create_manual_payout', label: 'support.create_manual_payout', group: 'support.group_account', groupKey: 'Account' },
        { method: 'POST', endpoint: 'api_support/convert_to_manual', label: 'support.convert_to_manual', group: 'support.group_account', groupKey: 'Account' },

        // Authentication
        { method: 'PUT', endpoint: 'api_support/set_claims', label: 'support.set_roles', group: 'support.group_authentication', groupKey: 'Authentication' },
        { method: 'PUT', endpoint: 'api_support/reset_password', label: 'support.set_new_password', group: 'support.group_authentication', groupKey: 'Authentication' },

        // Billing
        { method: 'PUT', endpoint: 'api_support/toggle_subscription_state', label: 'support.toggle_devices', group: 'support.group_billing', groupKey: 'Billing' },
        { method: 'PUT', endpoint: 'api_support/switch_serial_on_sub', label: 'support.switch_serial_on_sub', group: 'support.group_billing', groupKey: 'Billing' },
        { method: 'PUT', endpoint: 'api_support/delete_sub', label: 'support.delete_sub', group: 'support.group_billing', groupKey: 'Billing' },
        { method: 'PUT', endpoint: 'api_support/auto_subscribe_units', label: 'support.auto_subscribe_units', group: 'support.group_billing', groupKey: 'Billing' },

        // Device
        { method: 'PUT', endpoint: 'api_support/toggle_maintenance', label: 'support.toggle_maintenance', group: 'support.group_device', groupKey: 'Device' },
        { method: 'PUT', endpoint: 'api_support/replace_serial', label: 'support.replace_serial', group: 'support.group_device', groupKey: 'Device' },
        { method: 'PUT', endpoint: 'api_support/release_device', label: 'support.update_last_used', group: 'support.group_device', groupKey: 'Device' },

        // Location
        { method: 'PUT', endpoint: 'api_support/move_location', label: 'support.move_location', group: 'support.group_location', groupKey: 'Location' },
        { method: 'PUT', endpoint: 'api_support/edit_ark_settings', label: 'support.edit_ark', group: 'support.group_location', groupKey: 'Location' },

        // Subscription
        { method: 'PUT', endpoint: 'api_support/subscription', label: 'support.update_reset', options: 'reset', group: 'support.group_sub', groupKey: 'Subscription' },
        { method: 'PUT', endpoint: 'api_support/subscription', label: 'support.update_add', options: 'add_one', group: 'support.group_sub', groupKey: 'Subscription' },
        { method: 'PUT', endpoint: 'api_support/subscription', label: 'support.update_cancal', options: 'cancel', group: 'support.group_sub', groupKey: 'Subscription' },

        // Transactions
        { method: 'PUT', endpoint: 'api_transactions/refund', label: 'support.create_refund', group: 'support.group_transactions', groupKey: 'Transactions' },

        // User
        { method: 'POST', endpoint: 'api_support/create_checkout_link', label: 'support.create_checkout_link', group: 'support.group_user', groupKey: 'User' },
        { method: 'DELETE', endpoint: 'api_support/delete_user', label: 'support.delete_user', group: 'support.group_user', groupKey: 'User' },
        { method: 'PUT', endpoint: 'api_support/toggle_test_user', label: 'support.toggle_user', group: 'support.group_user', groupKey: 'User' },
        { method: 'PUT', endpoint: 'api_support/set_trusted_device', label: 'support.trusted_device', group: 'support.group_user', groupKey: 'User' },
        { method: 'PUT', endpoint: 'api_support/get_checkout_session', label: 'support.get_url', group: 'support.group_user', groupKey: 'User' },
        { method: 'PUT', endpoint: 'api_support/remote_close_app', label: 'support.remote_close_app', group: 'support.group_user', groupKey: 'User' },
        { method: 'PUT', endpoint: 'api_support/verify_app_user', label: 'support.verify_app_user', group: 'support.group_user', groupKey: 'User' },
        { method: 'PUT', endpoint: 'api_support/audit_wallet', label: 'support.audit_wallet', group: 'support.group_user', groupKey: 'User' },
        { method: 'PUT', endpoint: 'api_support/wallet_settings', label: 'support.wallet_settings', group: 'support.group_user', groupKey: 'User' },
        { method: 'POST', endpoint: 'api_support/refund_wallet', label: 'support.refund_wallet', group: 'support.group_user', groupKey: 'User' },
        { method: 'PUT', endpoint: 'api_support/user_admin_claims', label: 'support.user_admin_claims', group: 'support.group_user', groupKey: 'User' },
        { method: 'PUT', endpoint: 'api_support/force_update_anton', label: 'support.force_update_anton', group: 'support.group_user', groupKey: 'User' },

        // Operator
        { method: 'PUT', endpoint: 'api_support/convert_customer_to_sub_customer', label: 'support.convert_cus', group: 'support.group_operator', groupKey: 'Operator' }
    ];
    actionGroups: string[] = ['Account', 'Authentication', 'Billing', 'Device', 'Location', 'Subscription', 'Transactions', 'User', 'Operator'];
    result: string;
    status: '👌👌👌 success 👌👌👌' | '💩💩💩 error 💩💩💩' | '❓❓❓ unknown ❓❓❓' | '🛑🛑🛑 unauthorized 🛑🛑🛑';
    shouldReleaseDevice = true;
    ownUid: string;
    user: DashboardUser;
    stripeRegions: StripeRegions[] = [StripeRegions.EU, StripeRegions.US];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private supportService: SupportService,
        private translate: TranslateService,
        private helperService: HelperService,
        private locationService: LocationService
    ) {}

    ngOnDestroy() {
        this.formSub ? this.formSub.unsubscribe() : null;
    }

    ngOnInit() {
        this.user = this.helperService.getUser();
        this.setupSupportForm();
        this.onChanges();
        // this.locationService.readDummyEndpoint();
    }

    onChanges(): void {
        this.formSub = this.supportForm.valueChanges.subscribe(val => {
            if (val.guidOld) {
                const oldGuid: string = val.guidOld;
                if (oldGuid.includes('AW') && oldGuid.substring(val.guidOld.length - 2) === 'R2') {
                    // check if it is a R2 device
                    if (this.relayNumber.disabled) {
                        this.relayNumber.enable();
                    }
                } else {
                    if (this.relayNumber.enabled) {
                        this.relayNumber.disable();
                    }
                }
            }
        });
    }

    setupSupportForm() {
        this.supportForm = this.formBuilder.group({
            action: new UntypedFormControl(null, [Validators.required]),
            email: new UntypedFormControl(null),
            priceId: new UntypedFormControl(null),
            insurance: new UntypedFormControl(null),
            prorate: new UntypedFormControl(null),
            newPassword: new UntypedFormControl(null),
            userUid: new UntypedFormControl(null),
            claim: new UntypedFormControl(null),
            customerUid: new UntypedFormControl(null),
            operatorUid: new UntypedFormControl(null),
            subId: new UntypedFormControl(null),
            locationId: new UntypedFormControl(null),
            guidOld: new UntypedFormControl(null),
            guidNew: new UntypedFormControl(null),
            relayNumber: new UntypedFormControl(null),
            vatNumber: new UntypedFormControl(null),
            toCustomerUid: new UntypedFormControl(null),
            fromCustomerUid: new UntypedFormControl(null),
            paymentId: new UntypedFormControl(null),
            refundReason: new UntypedFormControl(null),
            awPoKey: new UntypedFormControl(null),
            stripeRegion: new UntypedFormControl(null),
            otherReason: new UntypedFormControl(null),
            devicePath: new UntypedFormControl(null),
            locationPath: new UntypedFormControl(null),
            mode: new UntypedFormControl(null),
            operation: new UntypedFormControl(null),
            phoneNumberOrUid: new UntypedFormControl(null),
            accountId: new UntypedFormControl(null),
            currency: new UntypedFormControl(null),
            agreementType: new UntypedFormControl(null),
            minFee: new UntypedFormControl(null),
            percentFee: new UntypedFormControl(null),
            deviceFingerprint: new UntypedFormControl(null),
            refillAmount: new UntypedFormControl(null), // wallet settings
            threshold: new UntypedFormControl(null), // wallet settings
            autoRefill: new UntypedFormControl(null), // wallet settings
            withFee: new UntypedFormControl(null), // wallet withdrawal
            arkMode: new UntypedFormControl(null),
            tier1TotalAmount: new UntypedFormControl(null),
            tier2TotalAmount: new UntypedFormControl(null),
            tier3TotalAmount: new UntypedFormControl(null),
            tier1UpTo: new UntypedFormControl(null),
            tier2UpTo: new UntypedFormControl(null),
            tier3UpTo: new UntypedFormControl(null),
            dispatchReviewMessage: new UntypedFormControl(null)
        });
    }

    actionSelected() {
        // init value
        if (this.relayNumber.enabled) {
            this.relayNumber.disable();
        }

        for (const key in this.supportForm.controls) {
            if (key !== 'action') {
                this.supportForm.patchValue({ [key]: null }, { emitEvent: false });
                this.supportForm.controls[key].setValidators(null);
                this.supportForm.controls[key].setErrors(null);
                this.supportForm.controls[key].updateValueAndValidity();
            }
            if (key === 'tier1Name') {
                this.supportForm.patchValue({ [key]: 'Bi' }, { emitEvent: false });
                this.supportForm.controls[key].updateValueAndValidity();
            }
            if (key === 'tier2Name') {
                this.supportForm.patchValue({ [key]: 'Koala' }, { emitEvent: false });
                this.supportForm.controls[key].updateValueAndValidity();
            }
            if (key === 'tier3Name') {
                this.supportForm.patchValue({ [key]: 'Tiger' }, { emitEvent: false });
                this.supportForm.controls[key].updateValueAndValidity();
            }
            if (key === 'stripeRegion') {
                this.supportForm.patchValue({ [key]: 'eu' }, { emitEvent: false });
                this.supportForm.controls[key].updateValueAndValidity();
            }
            if (key === 'withFee') {
                this.supportForm.patchValue({ [key]: 'true' }, { emitEvent: false });
                this.supportForm.controls[key].updateValueAndValidity();
            }
            if (key === 'dispatchReviewMessage') {
                this.supportForm.patchValue({ [key]: true }, { emitEvent: false });
                this.supportForm.controls[key].updateValueAndValidity();
            }
            if (key === 'refundReason') {
                this.supportForm.patchValue({ [key]: 'requested_by_customer' }, { emitEvent: false });
                this.supportForm.controls[key].updateValueAndValidity();
            }
        }

        this.result = null;
        this.formSubmitted = false;
        this.loading = false;
    }

    async submit() {
        this.loading = true;
        this.result = null;
        this.formSubmitted = true;
        for (const key in this.supportForm.controls) {
            if (key !== 'action') {
                this.supportForm.controls[key].setValidators(null);
            }
        }

        const tierUpToChecker = (): Record<string, any> | null => {
            if (this.supportForm.controls['tier1UpTo'].value && this.supportForm.controls['tier2UpTo'].value && this.supportForm.controls['tier1UpTo'].value >= this.supportForm.controls['tier2UpTo'].value) {
                return { tier2UpToNotRespected: true };
            }

            if (this.supportForm.controls['tier2UpTo'].value && this.supportForm.controls['tier3UpTo'].value && this.supportForm.controls['tier2UpTo'].value >= this.supportForm.controls['tier3UpTo'].value) {
                return { tier3UpToNotRespected: true };
            }

            return null; // all 'ok'
        };

        const tierAmountChecker = (): Record<string, any> | null => {
            if (this.supportForm.controls['tier1TotalAmount'].value && this.supportForm.controls['tier2TotalAmount'].value && this.supportForm.controls['tier1TotalAmount'].value >= this.supportForm.controls['tier2TotalAmount'].value) {
                return { tier2AmountNotRespected: true };
            }

            if (this.supportForm.controls['tier2TotalAmount'].value && this.supportForm.controls['tier3TotalAmount'].value && this.supportForm.controls['tier2TotalAmount'].value >= this.supportForm.controls['tier3TotalAmount'].value) {
                return { tier3AmountNotRespected: true };
            }

            return null; // all 'ok'
        };

        const tierAmountDecimalsControl = (): Record<string, any> | null => {
            if (
                !this.priceValidation(
                    this.supportForm.controls['tier1TotalAmount'].value && this.supportForm.controls['tier1UpTo'].value && this.supportForm.controls['tier1TotalAmount'].value / this.supportForm.controls['tier1UpTo'].value
                        ? this.supportForm.controls['tier1TotalAmount'].value / this.supportForm.controls['tier1UpTo'].value
                        : null
                ).valid
            ) {
                return { tier1UnwantedDecimals: true };
            }
            if (
                !this.priceValidation(
                    this.supportForm.controls['tier2TotalAmount'].value && this.supportForm.controls['tier2UpTo'].value && this.supportForm.controls['tier2TotalAmount'].value / this.supportForm.controls['tier2UpTo'].value
                        ? this.supportForm.controls['tier2TotalAmount'].value / this.supportForm.controls['tier2UpTo'].value
                        : null
                ).valid
            ) {
                return { tier2UnwantedDecimals: true };
            }
            if (
                !this.priceValidation(
                    this.supportForm.controls['tier3TotalAmount'].value && this.supportForm.controls['tier3UpTo'].value && this.supportForm.controls['tier3TotalAmount'].value / this.supportForm.controls['tier3UpTo'].value
                        ? this.supportForm.controls['tier3TotalAmount'].value / this.supportForm.controls['tier3UpTo'].value
                        : null
                ).valid
            ) {
                return { tier3UnwantedDecimals: true };
            }

            return null; // all 'ok'
        };

        const prepForm = (valuesToKeep: string[], optional?: string[]) => {
            for (const key in this.supportForm.controls) {
                if (!valuesToKeep.includes(key) && key !== 'action' && (!optional || (optional && !optional.includes(key)))) {
                    this.supportForm.patchValue({ [key]: null }, { emitEvent: false });
                }
            }

            for (const value of valuesToKeep) {
                if (value !== 'otherReason') {
                    this[value].setValidators([Validators.required]);
                }

                // validation for edit Ark settings
                if (value.includes('tier') && value.includes('TotalAmount')) {
                    this[value].setValidators([Validators.required, tierAmountChecker, tierAmountDecimalsControl, Validators.pattern(/^(\d+(?:[\.\,]\d{1,2})?)$/)]); // pattern: float with one or two decimals
                }
                if (value.includes('tier') && value.includes('UpTo')) {
                    this[value].setValidators([Validators.required, tierUpToChecker, Validators.pattern(/^[1-9]\d*$/)]); // pattern: positive integers
                }
                if (value.includes('tier')) {
                    this['customerUid'].setValidators(null);
                }

                // determine if relay number is needed (is R1 or R2)
                if (value === 'guidOld') {
                    this.relayNumber.disabled ? this.relayNumber.setValidators(null) : null;
                }
            }
        };

        switch (this.action.value.endpoint) {
            case 'api_transactions/refund':
                prepForm(['refundReason', 'otherReason', 'stripeRegion'], ['paymentId', 'phoneNumberOrUid', 'dispatchReviewMessage']);
                break;

            case 'api_support/remote_close_app':
                prepForm(['userUid', 'dispatchReviewMessage']);
                break;

            case 'api_support/set_claims':
                prepForm(['customerUid', 'claim', 'dispatchReviewMessage']);
                break;

            case 'api_support/auto_subscribe_units':
                prepForm(['customerUid', 'priceId', 'prorate', 'insurance', 'dispatchReviewMessage']);
                break;

            case 'api_account/delete_account':
                prepForm(['customerUid', 'dispatchReviewMessage']);
                break;

            case 'api_support/delete_user':
                prepForm(['userUid', 'dispatchReviewMessage']);
                break;

            case 'api_support/move_location':
                prepForm(['locationId', 'fromCustomerUid', 'toCustomerUid', 'dispatchReviewMessage']);
                break;

            case 'api_support/toggle_maintenance':
                prepForm(['devicePath']);
                break;

            case 'api_support/replace_serial':
                prepForm(['guidOld', 'guidNew', 'relayNumber']);
                break;

            case 'api_support/toggle_subscription_state':
                prepForm(['locationPath']);
                break;

            case 'api_support/release_device':
                prepForm(['devicePath']);
                break;

            case 'api_support/toggle_test_user':
                prepForm(['phoneNumberOrUid', 'dispatchReviewMessage']);
                break;

            case 'api_support/set_trusted_device':
                prepForm(['phoneNumberOrUid', 'deviceFingerprint', 'dispatchReviewMessage']);
                break;

            case 'api_support/wallet_settings':
                prepForm(['phoneNumberOrUid', 'dispatchReviewMessage'], ['refillAmount', 'threshold', 'autoRefill']);
                break;

            case 'api_support/refund_wallet':
                prepForm(['phoneNumberOrUid', 'withFee', 'dispatchReviewMessage']);
                break;

            case 'api_support/subscription':
                prepForm(['userUid', 'dispatchReviewMessage']);
                break;

            case 'api_support/user_admin_claims':
                prepForm(['userUid']);
                break;

            case 'api_support/force_update_anton':
                prepForm(['userUid']);
                break;

            case 'api_support/convert_to_manual':
                prepForm(['customerUid', 'dispatchReviewMessage']);
                break;

            case 'api_support/delete_sub':
                prepForm(['subId', 'dispatchReviewMessage']);
                break;

            case 'api_support/check_available_for_payout':
                prepForm(['customerUid', 'dispatchReviewMessage']);
                break;

            case 'api_support/create_checkout_link':
                prepForm(['userUid', 'stripeRegion', 'dispatchReviewMessage']);
                break;

            case 'api_support/create_manual_payout':
                prepForm(['customerUid', 'awPoKey', 'dispatchReviewMessage']);
                break;

            case 'api_support/update_agreement_type':
                if (this.agreementType.value === 'life') {
                    prepForm(['customerUid', 'agreementType', 'dispatchReviewMessage']);
                } else {
                    prepForm(['customerUid', 'agreementType', 'minFee', 'percentFee', 'dispatchReviewMessage']);
                }
                break;

            case 'api_support/get_checkout_session':
                prepForm(['userUid', 'stripeRegion', 'dispatchReviewMessage']);
                break;

            case 'api_support/edit_ark_settings':
                prepForm(['customerUid', 'locationId', 'arkMode', 'tier1TotalAmount', 'tier2TotalAmount', 'tier3TotalAmount', 'tier1UpTo', 'tier2UpTo', 'tier3UpTo', 'dispatchReviewMessage']);
                break;

            case 'api_support/convert_customer_to_sub_customer':
                prepForm(['customerUid', 'operatorUid', 'dispatchReviewMessage']);
                break;

            case 'api_support/reset_password':
                prepForm(['email', 'newPassword']);
                break;

            case 'api_support/switch_serial_on_sub':
                prepForm(['guidOld', 'guidNew', 'relayNumber']);
                break;

            case 'api_support/verify_app_user':
                prepForm(['userUid', 'dispatchReviewMessage']);
                break;

            case 'api_support/audit_wallet':
                prepForm(['userUid', 'dispatchReviewMessage']);
                break;

            default:
                break;
        }

        for (const key in this.supportForm.controls) {
            this.supportForm.controls[key].updateValueAndValidity();
        }

        if (this.supportForm.valid) {
            const req = {
                action: this.action.value,
                data: {
                    ...this.supportForm.value,
                    ...{ paymentId: this.supportForm.value.paymentId ? this.supportForm.value.paymentId.trim() : null },
                    ...{ customerUid: this.supportForm.value.customerUid ? this.supportForm.value.customerUid.trim() : null },
                    ...{ accountId: this.supportForm.value.accountId ? this.supportForm.value.accountId.trim() : null },
                    ...{ userUid: this.supportForm.value.userUid ? this.supportForm.value.userUid.trim() : null },
                    ...{ awPoKey: this.supportForm.value.awPoKey ? this.supportForm.value.awPoKey.trim() : null },
                    ...{ locationId: this.supportForm.value.locationId ? this.supportForm.value.locationId.trim() : null },
                    ...{ devicePath: this.supportForm.value.devicePath ? this.supportForm.value.devicePath.trim() : null },
                    ...{ phoneNumberOrUid: this.supportForm.value.phoneNumberOrUid ? this.supportForm.value.phoneNumberOrUid.trim() : null },
                    ...{ guidOld: this.supportForm.value.guidOld ? this.supportForm.value.guidOld.trim() : null },
                    ...{ guidNew: this.supportForm.value.guidNew ? this.supportForm.value.guidNew.trim() : null },
                    ...{ locationPath: this.supportForm.value.locationPath ? this.supportForm.value.locationPath.trim() : null },
                    ...{ deviceFingerprint: this.supportForm.value.deviceFingerprint ? this.supportForm.value.deviceFingerprint.trim() : null },
                    ...{ currency: this.supportForm.value.currency ? this.supportForm.value.currency.trim() : null },
                    ...{ operatorUid: this.supportForm.value.operatorUid ? this.supportForm.value.operatorUid.trim() : null },
                    ...{ newPassword: this.supportForm.value.newPassword ? this.supportForm.value.newPassword.trim() : null },
                    ...{ subId: this.supportForm.value.subId ? this.supportForm.value.subId.trim() : null }
                }
            };

            switch (req.action.endpoint) {
                case 'api_support/subscription':
                    switch (req.action.options) {
                        case 'cancel':
                            req.data.mode = 'cancel';
                            break;
                        case 'reset':
                            req.data.mode = 'adjust';
                            req.data.operation = 'reset';

                            break;
                        case 'add_one':
                            req.data.mode = 'adjust';
                            req.data.operation = 'add_one';

                            break;

                        default:
                            break;
                    }
                    break;

                default:
                    break;
            }

            req.data.userName = 'Airwallet'; //username is always Airwallet when support tool is used

            await this.supportService
                .supportCall(req)
                .then(res => {
                    this.status = !res.status || res.status === 200 ? '👌👌👌 success 👌👌👌' : res.status === 400 ? '💩💩💩 error 💩💩💩' : res.status === 401 ? '🛑🛑🛑 unauthorized 🛑🛑🛑' : '❓❓❓ unknown ❓❓❓';
                    this.result = JSON.stringify(res, undefined, 4);
                    this.formSubmitted = false;
                    this.loading = false;
                })
                .catch((err: HttpErrorResponse) => {
                    this.status = err.status === 400 ? '💩💩💩 error 💩💩💩' : err.status === 401 ? '🛑🛑🛑 unauthorized 🛑🛑🛑' : '❓❓❓ unknown ❓❓❓';
                    // this.result = err.error && err.error.error && err.error.error.message ? err.error.error.message : JSON.stringify(err, undefined, 4);
                    this.result = err.error ? (err.error.error ? (err.error.error.message ? err.error.error.message : JSON.stringify(err.error.error)) : JSON.stringify(err.error)) : JSON.stringify(err);
                    this.formSubmitted = false;
                    this.loading = false;
                });
        } else {
            this.loading = false;
            console.log(this.supportForm.controls);

            for (const control in this.supportForm.controls) {
                if (this.supportForm.controls[control].status === 'INVALID') {
                    console.error(this.supportForm.controls[control].errors);
                }
            }
        }
    }

    toggleReleaseDevice() {
        this.shouldReleaseDevice = !this.shouldReleaseDevice;
    }

    priceValidation(price: number | null): { valid: boolean; style: { color: string } } {
        const valid = { valid: true, style: { color: '#212529' } };
        const invalid = { valid: false, style: { color: 'red' } };

        if (price) {
            const priceStr = price.toString();
            if (priceStr.includes('.')) {
                switch (priceStr.split('.')[1]) {
                    case '25':
                        return valid;
                    case '5':
                        return valid;
                    case '75':
                        return valid;
                    case '95':
                        return valid;
                    case '99':
                        return valid;

                    default:
                        return invalid;
                }
            } else {
                return valid;
            }
        } else {
            return valid;
        }
    }

    // removeOptions function is a fix for safari and firefox that does not accept hiding options in a optgroup tag. Instead we just remove the tags from the DOM.
    removeOptions() {
        this.actions.forEach(element => {
            element.label = this.translate.instant(element.label);
            element.group = this.translate.instant(element.group);
        });

        const options = Array.from(document.getElementsByClassName('removeOption'));

        if (options.length)
            options.forEach(option => {
                option.remove();
            });
    }

    get action() {
        return this.supportForm.get('action');
    }
    get priceId() {
        return this.supportForm.get('priceId');
    }
    get insurance() {
        return this.supportForm.get('insurance');
    }
    get prorate() {
        return this.supportForm.get('prorate');
    }
    get userUid() {
        return this.supportForm.get('userUid');
    }
    get awPoKey() {
        return this.supportForm.get('awPoKey');
    }
    get email() {
        return this.supportForm.get('email');
    }
    get newPassword() {
        return this.supportForm.get('newPassword');
    }
    get claim() {
        return this.supportForm.get('claim');
    }
    get customerUid() {
        return this.supportForm.get('customerUid');
    }
    get operatorUid() {
        return this.supportForm.get('operatorUid');
    }
    get subId() {
        return this.supportForm.get('subId');
    }
    get locationId() {
        return this.supportForm.get('locationId');
    }
    get guidOld() {
        return this.supportForm.get('guidOld');
    }
    get guidNew() {
        return this.supportForm.get('guidNew');
    }
    get relayNumber() {
        return this.supportForm.get('relayNumber');
    }
    get vatNumber() {
        return this.supportForm.get('vatNumber');
    }
    get toCustomerUid() {
        return this.supportForm.get('toCustomerUid');
    }
    get fromCustomerUid() {
        return this.supportForm.get('fromCustomerUid');
    }
    get paymentId() {
        return this.supportForm.get('paymentId');
    }
    get refundReason() {
        return this.supportForm.get('refundReason');
    }
    get stripeRegion() {
        return this.supportForm.get('stripeRegion');
    }
    get otherReason() {
        return this.supportForm.get('otherReason');
    }
    get devicePath() {
        return this.supportForm.get('devicePath');
    }
    get locationPath() {
        return this.supportForm.get('locationPath');
    }
    get mode() {
        return this.supportForm.get('mode');
    }
    get operation() {
        return this.supportForm.get('operation');
    }
    get phoneNumberOrUid() {
        return this.supportForm.get('phoneNumberOrUid');
    }
    get accountId() {
        return this.supportForm.get('accountId');
    }
    get currency() {
        return this.supportForm.get('currency');
    }
    get agreementType() {
        return this.supportForm.get('agreementType');
    }
    get minFee() {
        return this.supportForm.get('minFee');
    }
    get percentFee() {
        return this.supportForm.get('percentFee');
    }
    get deviceFingerprint() {
        return this.supportForm.get('deviceFingerprint');
    }
    get refillAmount() {
        return this.supportForm.get('refillAmount');
    }
    get threshold() {
        return this.supportForm.get('threshold');
    }
    get autoRefill() {
        return this.supportForm.get('autoRefill');
    }
    get withFee() {
        return this.supportForm.get('withFee');
    }
    get arkMode() {
        return this.supportForm.get('arkMode');
    }
    get tier1TotalAmount() {
        return this.supportForm.get('tier1TotalAmount');
    }
    get tier2TotalAmount() {
        return this.supportForm.get('tier2TotalAmount');
    }
    get tier3TotalAmount() {
        return this.supportForm.get('tier3TotalAmount');
    }
    get tier1UpTo() {
        return this.supportForm.get('tier1UpTo');
    }
    get tier2UpTo() {
        return this.supportForm.get('tier2UpTo');
    }
    get tier3UpTo() {
        return this.supportForm.get('tier3UpTo');
    }
    get dispatchReviewMessage() {
        return this.supportForm.get('dispatchReviewMessage');
    }
}
