import { z } from 'zod';
export const createUserSchema = z
    .object({
        name: z.string().min(1),
        phone_number_call_code: z.string().min(1),
        phone_number: z.string().regex(/[- +()0-9]+/),
        customer: z.string()
    })
    .strict();

export type CreateUserZod = Required<z.infer<typeof createUserSchema>>;
