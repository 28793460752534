<app-custom-modal>
    <ng-container modal-title>
        <h4>{{ "users.create_user" | translate }}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="closeModal()"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" /></a>
    </ng-container>
    <ng-container modal-body>
        <app-aw-form-creator [schema]="schema" (onSuccess)="createUser($event)" [isFetching]="isFetching || showSmallIndicator" [buttonLabel]="'users.add_user'" [form]="userForm"></app-aw-form-creator>
    </ng-container>
    <ng-container modal-footer> </ng-container>
</app-custom-modal>
