import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject, SnapshotAction } from '@angular/fire/compat/database';
import { Details } from 'shared_models/details';
import { CreateSubCustomerParams, SetPasswordParams, SubCustomer, SubCustomerPermission } from 'shared_models/sub-customer';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SetInvitedUserInfoParams } from '../../../../shared_models/invited-user';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpService, RequestTypes } from '../helper/http.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    Details: AngularFireObject<any>;

    constructor(
        private db: AngularFireDatabase,
        private http: HttpClient,
        private httpService: HttpService
    ) {}

    readSubCustomers(uid: string): AngularFireObject<Record<string, SubCustomer>> {
        return this.db.object(`customers/${uid}/sub_customers`);
    }

    async readSubCustomersAsPromise(uid: string): Promise<Record<string, SubCustomer>> {
        return new Promise((resolve, reject) => {
            const readCustomer_sub: Subscription = this.db
                .object(`customers/${uid}/sub_customers`)
                .snapshotChanges()
                .subscribe((readCustomer_snap: SnapshotAction<Record<string, SubCustomer>>) => {
                    readCustomer_sub.unsubscribe();
                    const readCustomer: Record<string, SubCustomer> = readCustomer_snap.payload.val();
                    const customerRecord: Record<string, SubCustomer> = {};
                    for (const subUid in readCustomer) {
                        customerRecord[`${subUid}_operated_by_${uid}`] = readCustomer[subUid];
                    }
                    return resolve(customerRecord);
                });
        });
    }

    readSubCustomer(uid: string, subUid: string): AngularFireObject<SubCustomer> {
        return this.db.object(`customers/${uid}/sub_customers/${subUid}`);
    }

    readSubCustomerPermission(subUid: string): AngularFireObject<SubCustomerPermission> {
        return this.db.object(`customers/${subUid}/permissions`);
    }

    async readSubCustomerAllowBilling(subUid: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const billing_sub: Subscription = this.db
                .object(`customers/${subUid}/permissions/allow_billing`)
                .snapshotChanges()
                .subscribe((allowBilling_snap: SnapshotAction<boolean>) => {
                    billing_sub.unsubscribe();
                    const allowBilling: boolean = allowBilling_snap.payload.val();
                    return resolve(allowBilling);
                });
        });
    }

    readSubCustomerBalance(uid: string, subUid: string, startAt: string, endAt: string) {
        return this.db.list(`customers/${uid}/sub_customers_balance/${subUid}`, ref => ref.orderByKey().startAt(startAt).endAt(endAt));
    }

    readCustomerDetails(uid: string): AngularFireObject<Details> {
        return this.db.object(`customers/${uid}/details`);
    }

    removeVerifyEmailTimestamp(uid: string): Promise<void> {
        return this.db.list(`customers/${uid}/details/verify_email`).remove();
    }

    async updateSubCustomerSettings(subUid: string, data: SubCustomerPermission): Promise<any> {
        return this.db.list(`customers/${subUid}`).update('permissions', data);
    }

    async updateBillingAllowedForSubcustomer(subUid: string, shouldAllowBilling: boolean): Promise<any> {
        return this.httpService.dynamicHttp(`api_billing/switch_allow_billing`, RequestTypes.POST, { body: { subUid, shouldAllowBilling } });
    }

    async updateRevenueShare(uid: string, subUid: string, data: SubCustomer, allowAdvancedShare: boolean): Promise<any> {
        if (allowAdvancedShare && !data.share) {
            this.db.list(`customers/${uid}/sub_customers/${subUid}`).remove('share');
        } else if (!allowAdvancedShare && !data.washer_share && !data.dryer_share && !data.other_share) {
            this.db.list(`customers/${uid}/sub_customers/${subUid}`).remove('washer_share');
            this.db.list(`customers/${uid}/sub_customers/${subUid}`).remove('dryer_share');
            this.db.list(`customers/${uid}/sub_customers/${subUid}`).remove('other_share');
        }
        return this.db.list(`customers/${uid}/sub_customers`).update(subUid, data);
    }

    async getSubCustomersDevicesState(data: { uids: Record<string, boolean> }): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(`${environment.baseUrl}/api_operator/get_sub_customers_inactive_devices?params=${JSON.stringify(data)}`))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async checkAllowChangeBilling(subUid: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(`${environment.baseUrl}/api_billing/check_allow_change_billing`, { subUid }))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async createSubCustomer(subCustomerParams: CreateSubCustomerParams): Promise<any> {
        return this.httpService.dynamicHttp(`api_operator/sub_customer`, RequestTypes.POST, { body: { subCustomerParams } });
    }

    async createTeamMember(invitedUserInfoParams: SetInvitedUserInfoParams): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.post(`${environment.baseUrl}/api_account/team_member/set_info`, invitedUserInfoParams))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async teamMemberExists(email: string): Promise<boolean> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(`${environment.baseUrl}/api_account/team_member/exists?email=${email}`))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async setPassword(passwordParams: SetPasswordParams): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(`${environment.baseUrl}/api_operator/sub_customer/password`, { passwordParams }))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async getCreditUsers(locationId: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(`${environment.baseUrl}/api_credit_payment/user_read_all?locationId=${locationId}`))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }
}
