// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import packageInfo from '../../package.json';

// test
export const environment = {
    production: false,
    firebase: {
        apiKey: 'AIzaSyCV79P8l2OnoAe-ZzZrgx9QxMYCAwNViPM',
        authDomain: 'airwallettest.firebaseapp.com',
        databaseURL: 'https://airwallettest.firebaseio.com',
        projectId: 'airwallettest',
        storageBucket: 'airwallettest.appspot.com',
        messagingSenderId: '184449480513',
        appId: '1:184449480513:web:2b29e007502e73d2c713c0',
        measurementId: 'G-MRC7JKY027'
    },
    VERSION: packageInfo.version,
    stripePublicKeyEU: 'pk_test_DtuZ6oGapSUofhNVmposMExg00G1Sic3kw',
    stripePublicKeyUS: 'pk_test_51I17JmDhhEGsJPhhhVCuxGXhBMIh8HY2Ot6ZwJU0r9brAWSTFjUGt1YOFHll7iVn9o2UQeQp9CtQF1W3h2zFaMg700WLq0qtYe',
    supportPhone: {
        phoneNumber: '(+45) 7870 9999',
        telLink: 'tel:+4578709999'
    },
    baseUrl: `${window.location.hostname !== 'sandbox.admin.airwallet.net' ? `http://${window.location.hostname}:5000/airwallettest/europe-west1` : `https://europe-west1-airwallettest.cloudfunctions.net`}`,
    baseFrontUrl: `${window.location.hostname !== 'sandbox.admin.airwallet.net' ? `http://${window.location.hostname}:4200` : `https://sandbox.admin.airwallet.net`}`,
    gdprPolicy: 'https://airwallet.net/airwallet-gdpr/',
    euTermsOfUse: 'https://airwallet.net/airwallet-terms-conditions-for-businesses/',
    usTermsOfUse: 'https://airwallet.net/business_terms_inc/',
    locals: ['en', 'da', 'fr', 'de', 'es', 'it', 'no', 'pl'],
    googleMapsID: 'f1e787b4b952ff8b',
    mixpanelToken: 'a741464ae1b7fc1df5fe916104d0fc75'
};

// prod
// export const environment = {
//     production: true,
//     firebase: {
//         apiKey: "AIzaSyARTlq-7pZMTofnSYDzix8sF945ZFN3lxw",
//         authDomain: "airwallet-685ff.firebaseapp.com",
//         databaseURL: "https://airwallet-685ff.firebaseio.com",
//         projectId: "airwallet-685ff",
//         storageBucket: "airwallet-685ff.appspot.com",
//         messagingSenderId: "543786955911",
//         appId: "1:543786955911:web:a92604475d723a8c"
//     },
//     VERSION: packageInfo.version,
//     stripePublicKeyEU: "pk_live_M0JntRzo7lrHdHoIPrtkJ8g100FDH2E5iH",
//     stripePublicKeyUS: "pk_live_51I17JmDhhEGsJPhhwkN25nitLk4vXkefxH7827tSGxqZFlbaeJFCivKDy1U7gPnf4fI305fL758YKqgyPfC3WD9C00GsJ34gSg",
//     supportPhone: {
//         phoneNumber: '(+45) 7870 9999',
//         telLink: 'tel:+4578709999'
//     },
//     baseUrl: `http://127.0.0.1:5000/airwallet-685ff/europe-west1`,
//     baseFrontUrl: `http://localhost:4200/`,
//     gdprPolicy: "https://airwallet.net/airwallet-gdpr/",
//     euTermsOfUse: 'https://airwallet.net/airwallet-terms-conditions-for-businesses/',
//     usTermsOfUse: 'https://airwallet.net/business_terms_inc/',
//     locals: ["en", "da", "fr", "de", "es", "it", "no", "pl"],
//     googleMapsID: "591028923d538ed",
//     mixpanelToken: '664e8b3d3efc63656b5e22754bffb1e3',
//     local_production: true
// };

//stag
// export const environment = {
//     production: false,
//     firebase: {
//         apiKey: 'AIzaSyAF7vmvQEl4uNtoO9lt7ntYvGow5wDFhd0',
//         authDomain: 'airwallet-staging.firebaseapp.com',
//         databaseURL: 'https://airwallet-staging.firebaseio.com',
//         projectId: 'airwallet-staging',
//         storageBucket: 'airwallet-staging.appspot.com',
//         messagingSenderId: '814196688274',
//         appId: '1:814196688274:web:e4332bda4f09bbf528ad4b',
//         measurementId: 'G-T3C2E6G7D7'
//     },
//     VERSION: packageInfo.version,
//     stripePublicKeyEU: 'pk_test_DtuZ6oGapSUofhNVmposMExg00G1Sic3kw',
//     stripePublicKeyUS: 'pk_test_51I17JmDhhEGsJPhhhVCuxGXhBMIh8HY2Ot6ZwJU0r9brAWSTFjUGt1YOFHll7iVn9o2UQeQp9CtQF1W3h2zFaMg700WLq0qtYe',
//     supportPhone: {
//         phoneNumber: '(+45) 7870 9999',
//         telLink: 'tel:+4578709999'
//     },
//     baseUrl: `${window.location.hostname === 'localhost' ? 'http://localhost:5000/airwallet-staging/europe-west1' : `https://europe-west1-airwallet-staging.cloudfunctions.net`}`,
//     baseFrontUrl: `${window.location.hostname !== 'sandbox.admin.airwallet.net' ? `http://${window.location.hostname}:4200` : `https://sandbox.admin.airwallet.net`}`,
//     gdprPolicy: 'https://airwallet.net/airwallet-gdpr/',
//     euTermsOfUse: 'https://airwallet.net/airwallet-terms-conditions-for-businesses/',
//     usTermsOfUse: 'https://airwallet.net/business_terms_inc/',
//     locals: ['en', 'da', 'fr', 'de', 'es', 'it', 'no', 'pl'],
//     googleMapsID: '591028923d538ed',
//     mixpanelToken: 'a741464ae1b7fc1df5fe916104d0fc75',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
