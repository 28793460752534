<div class="container mb-5">
    <!-- LOCATION NAME + BUTTONS -->
    <div class="title-area">
        <div>
            <h4 class="page-title">{{ location && location.name && !showLoadingIndicator ? location.name : ("location.fetching_location" | translate) }}</h4>
        </div>

        <div class="title-area-inner" *ngIf="!showLoadingIndicator">
            <app-animated-button [disabled]="showLoadingIndicator">
                <div class="animated-button-btns-wrapper">
                    <button class="btn btn-secondary btn-tiny" (click)="openModal(locationSettingsModal, 'locationSettingsModal')">
                        {{ "location.location_setting" | translate }}
                    </button>
                    <button class="btn btn-secondary btn-tiny" (click)="openModal(bookingSettingsModal)">
                        {{ "location.booking_setting" | translate }}
                    </button>
                    <button *ngIf="showDeviceSortBtn" class="btn btn-secondary btn-tiny" (click)="openModal(deviceOrderingModal)">
                        {{ "location.device_order" | translate }}
                    </button>
                </div>
            </app-animated-button>
            <div *ngIf="subCustomerAllowed && !loadingHeaderButtons && authService.hasLimitedAccess('create_units', role$ | async)" class="ms-2 primary-btns">
                <button class="btn btn-primary btn-tiny me-2" [routerLink]="['device_setup']">
                    <img class="plus-icon-on-btn" src="../../../../assets/icons/plus-for-create.svg" alt="{{ 'location.create_washer_dryer' | translate }}" />
                    {{ "location.create_washer_dryer" | translate }}
                </button>

                <button *ngIf="countryDetails && countryDetails.stripe_terminal" class="btn btn-primary btn-tiny" (click)="openModal(createTerminalModal, 'createTerminalModal')">
                    <img class="plus-icon-on-btn" src="../../../../assets/icons/plus-for-create.svg" alt="{{ 'location.create_terminal' | translate }}" />
                    {{ "location.create_terminal" | translate }}
                </button>
            </div>
        </div>
    </div>

    <app-device-cards-list [locId]="location_id" [inputDeviceList]="deviceList"></app-device-cards-list>

    <app-terminal-cards-list [locId]="location_id" [modalRef]="modalRef" [inputTerminalList]="terminalList"></app-terminal-cards-list>

    <app-credit-user-table [locationId]="location_id ? location_id : null" *ngIf="authService.hasLimitedAccess('device_stats', role$ | async)"></app-credit-user-table>

    <ng-template #createTerminalModal let-modal>
        <app-terminal-create-modal
            [locationCountry]="location.location_specifications.address.country"
            [locationId]="location_id"
            [isCustomerOperated]="isCustomerOperated"
            [creatingTerminal]="creatingTerminal"
            (createTerminalEmitter)="newTerminal($event)"
        ></app-terminal-create-modal>
    </ng-template>
    <!-- LOCATION SETTINGS MODAL -->
    <ng-template #locationSettingsModal let-modal>
        <app-custom-modal>
            <ng-container modal-title>
                <h4 *ngIf="!showDeleteLocation">
                    {{ "location.locations_settings" | translate }}
                </h4>
                <h4 *ngIf="showDeleteLocation">{{ "location.delete_location" | translate }}</h4>
            </ng-container>
            <ng-container modal-body>
                <form tabindex="0" [formGroup]="locationSettingsForm" *ngIf="!showDeleteLocation">
                    <div class="modal-padding-wrapper">
                        <!-- name -->
                        <div class="input-group-wrapper-feedback">
                            <app-custom-tool-tip forInput="true" label="{{ 'location.name' | translate }}" description="{{ 'locations.name_displayed' | translate }}"></app-custom-tool-tip>
                            <input autocomplete="new-name" name="name" [ngClass]="getClass(name)" type="text" formControlName="name" />

                            <div class="feedback-container">
                                @if (locationSettingsFormSubmitted && name.invalid) {
                                    <div class="error-msg" *ngIf="name.errors.required">
                                        {{ "misc.name_required" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="name.errors.maxlength">
                                        {{ "misc.name_max" | translate }} {{ name.errors.maxlength.requiredLength }}
                                        {{ "misc.characters_long" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="name.errors.minlength">
                                        {{ "misc.name_min" | translate }} {{ name.errors.minlength.requiredLength }}
                                        {{ "misc.characters_long" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="name.errors.uniqueName">
                                        {{ "location.location_name_unique" | translate }}
                                        <strong>{{ name.errors.uniqueName.value }} </strong>{{ "location.location_name_already_exists" | translate }}
                                    </div>
                                }
                            </div>
                        </div>

                        <!-- number_of_users -->
                        <div class="input-group-wrapper-feedback" formGroupName="location_specifications">
                            <app-custom-tool-tip forInput="true" label="{{ 'locations.number_of_users' | translate }}" description="{{ 'locations.estimated_users' | translate }}"></app-custom-tool-tip>
                            <input autocomplete="new-number_of_users" name="number_of_users" [ngClass]="getClass(number_of_users)" type="number" formControlName="number_of_users" />
                            <div class="feedback-container">
                                @if (locationSettingsFormSubmitted && number_of_users.invalid) {
                                    <div class="error-msg" *ngIf="number_of_users.errors.required">
                                        {{ "locations.require_number_of_users" | translate }}
                                    </div>
                                }
                            </div>
                        </div>

                        <!-- user_group -->
                        <div class="input-group-wrapper-feedback" formGroupName="location_specifications">
                            <app-custom-tool-tip forInput="true" label="{{ 'locations.group_users' | translate }}" description="{{ 'locations.mainly_consists' | translate }}"></app-custom-tool-tip>
                            <select [ngClass]="getClass(user_group, { 'form-select': true })" formControlName="user_group">
                                <option selected disabled value="null">{{ "locations.choose_group" | translate }}</option>
                                <option [ngValue]="item.key" *ngFor="let item of user_groups | keyvalue">
                                    {{ getUserGroup(item.value) }}
                                </option>
                            </select>
                            <div class="feedback-container">
                                @if (locationSettingsFormSubmitted && user_group.invalid) {
                                    <div class="error-msg" *ngIf="user_group.errors.required">
                                        {{ "locations.group_required" | translate }}
                                    </div>
                                }
                            </div>
                        </div>

                        <!-- location id -->
                        <div class="input-group-wrapper-feedback">
                            <app-custom-tool-tip forInput="true" label="{{ 'location.location_custom_id' | translate }}" description="{{ 'location.optional_id' | translate }}"></app-custom-tool-tip>
                            <input autocomplete="location_custom_id" name="location_custom_id" [ngClass]="getClass(location_custom_id)" type="text" formControlName="location_custom_id" />

                            <div class="feedback-container">
                                @if (locationSettingsFormSubmitted && location_custom_id.invalid) {
                                    <div class="error-msg" *ngIf="location_custom_id.errors.maxlength">
                                        {{ "location.location_custom_id_max" | translate }}
                                        {{ location_custom_id.errors.maxlength.requiredLength }}
                                        {{ "misc.characters_long" | translate }}
                                    </div>

                                    <div class="error-msg" *ngIf="location_custom_id.errors.pattern">
                                        {{ "misc.invalid_pattern" | translate }}
                                    </div>

                                    <div class="error-msg" *ngIf="location_custom_id.errors.alreadyExists">
                                        {{ "location.location_custom_id_already_exists" | translate }}
                                    </div>
                                }
                            </div>
                        </div>

                        <!-- currency select-->
                        <div class="input-group-wrapper-feedback add-fb">
                            <h6 class="mb-1">{{ "account.currency" | translate }}</h6>
                            <select class="form-select" formControlName="currency">
                                <option selected disabled [value]="currency">
                                    {{ currency.toUpperCase() }}
                                </option>
                            </select>
                        </div>

                        <!-- Opening Hours -->
                        <div class="input-group-wrapper-feedback">
                            <app-custom-tool-tip forInput="true" label="{{ 'locations.opening_hours' | translate }}" description="{{ 'locations.open_hours' | translate }}"></app-custom-tool-tip>
                            <div class="input-group-wrapper" style="display: flex; justify-content: end">
                                <div class="checkbox d-flex justify-content-between">
                                    <input type="checkbox" id="open_hours" [checked]="checkIf24Hours()" (change)="onOpen24HoursChange($event.target.checked)" [disabled]="initLoading" />
                                    <label class="checkbox-label" for="open_hours">{{ "location.open_24" | translate }}</label>
                                </div>
                            </div>
                            <app-aw-calendar-layout [week]="savedOpenTimes" [disableLayout]="initLoading" (edit)="showOpeningHours()"></app-aw-calendar-layout>

                            <div class="feedback-container">
                                <ng-container *ngIf="locationSettingsFormSubmitted && opening_schedule.invalid">
                                    <div class="error-msg" *ngIf="opening_schedule.errors.required">
                                        {{ "locations.require_open" | translate }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="full-width-div">
                        <h5>
                            {{ "location.location_address" | translate }}
                        </h5>
                    </div>
                    <div class="modal-padding-wrapper">
                        <!-- ADDRESS FIELDS -->
                        <!-- line1 and line2 -->
                        <div class="row gap-3 g-0" formGroupName="location_specifications">
                            <div class="input-group-wrapper-feedback col" formGroupName="address">
                                <label for="line1">{{ "locations.line1" | translate }}</label>
                                <input autocomplete="new-line1" name="line1" [ngClass]="getClass(line1)" type="text" formControlName="line1" />

                                <div class="feedback-container">
                                    @if (line1.invalid) {
                                        <div class="error-msg" *ngIf="line1.errors.street_number_missing">
                                            {{ "locations.street_number_missing" | translate }}
                                        </div>
                                        <div class="error-msg" *ngIf="line1.errors.route_missing">
                                            {{ "locations.route_missing" | translate }}
                                        </div>
                                        <div class="error-msg" *ngIf="line1.errors.street_number_unconfirmed">
                                            {{ "locations.street_number_unconfirmed" | translate }}
                                        </div>
                                        <div class="error-msg" *ngIf="line1.errors.route_unconfirmed">
                                            {{ "locations.route_unconfirmed" | translate }}
                                        </div>
                                        @if (locationSettingsFormSubmitted || validationCheck) {
                                            <div class="error-msg" *ngIf="line1.errors.required">
                                                {{ "locations.line1_required" | translate }}
                                            </div>
                                        }
                                    }
                                </div>
                            </div>
                            <div class="input-group-wrapper-feedback col" formGroupName="address">
                                <label for="line2">{{ "locations.line2" | translate }}</label>
                                <input autocomplete="new-line2" name="line2" [ngClass]="getClass(line2)" type="text" formControlName="line2" />
                                <div class="feedback-container">
                                    @if ((locationSettingsFormSubmitted || validationCheck) && line2.invalid) {
                                        <div class="error-msg" *ngIf="line2.errors.subpremise_unconfirmed">
                                            {{ "locations.subpremise_unconfirmed" | translate }}
                                        </div>
                                        <div class="error-msg" *ngIf="line2.errors.subpremise_missing">
                                            {{ "locations.subpremise_missing" | translate }}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <!-- City & Postal -->
                        <div class="row gap-3 g-0" formGroupName="location_specifications">
                            <div *ngIf="requiredFields.city" class="input-group-wrapper-feedback col" formGroupName="address">
                                <label for="city">{{ "locations.city" | translate }}</label>
                                <input autocomplete="new-city" name="city" [ngClass]="getClass(city)" type="text" formControlName="city" />
                                <div class="feedback-container">
                                    @if (city.invalid) {
                                        <div class="error-msg" *ngIf="city.errors.city_missing">
                                            {{ "locations.city_missing" | translate }}
                                        </div>
                                        <div class="error-msg" *ngIf="city.errors.city_unconfirmed">
                                            {{ "locations.city_unconfirmed" | translate }}
                                        </div>
                                        @if (locationSettingsFormSubmitted || validationCheck) {
                                            <div class="error-msg" *ngIf="city.errors.required">
                                                {{ "locations.city_required" | translate }}
                                            </div>
                                        }
                                    }
                                </div>
                            </div>
                            <div *ngIf="requiredFields.postal_code" class="input-group-wrapper-feedback col" formGroupName="address">
                                <label for="postal_code">{{ "locations.postal_code" | translate }}</label>
                                <input autocomplete="new-postal_code" name="postal_code" [ngClass]="getClass(postal_code)" type="text" formControlName="postal_code" />
                                <div class="feedback-container">
                                    @if (postal_code.invalid) {
                                        <div class="error-msg" *ngIf="postal_code.errors.postal_code_unconfirmed">
                                            {{ "locations.postal_code_unconfirmed" | translate }}
                                        </div>
                                        <div class="error-msg" *ngIf="postal_code.errors.postal_code_missing">
                                            {{ "locations.postal_code_missing" | translate }}
                                        </div>
                                        @if (locationSettingsFormSubmitted || validationCheck) {
                                            <div class="error-msg" *ngIf="postal_code.errors.required">
                                                {{ "locations.postal_code_required" | translate }}
                                            </div>
                                            <div class="error-msg" *ngIf="postal_code.errors.stripeValidation">
                                                {{ "locations.postal_code_invalid" | translate }}
                                            </div>
                                        }
                                    }
                                </div>
                            </div>
                        </div>

                        <!-- state (Selector for contires with statenames we know) -->
                        <div class="row gap-3 g-0" formGroupName="location_specifications" *ngIf="requiredFields.state">
                            <div class="input-group-wrapper-feedback col" formGroupName="address">
                                <label for="state">{{ "locations.state" | translate }}</label>
                                <select [ngClass]="getClass(state, { 'form-select': true })" formControlName="state">
                                    <option selected disabled value="null">Choose state</option>
                                    <option [ngValue]="stateItem.code" *ngFor="let stateItem of states">
                                        {{ stateItem.name }}
                                    </option>
                                </select>
                                <div class="feedback-container">
                                    @if ((locationSettingsFormSubmitted || validationCheck) && state.invalid) {
                                        <div class="error-msg" *ngIf="state.errors.required">
                                            {{ "locations.state_required" | translate }}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <!-- Country and validate -->
                        <div class="row gap-3 g-0" formGroupName="location_specifications">
                            <div class="input-group-wrapper-feedback col" formGroupName="address">
                                <label for="address">{{ "locations.country" | translate }}</label>
                                <select [ngClass]="getClass(country, { 'form-select': true })" formControlName="country" (change)="countrySelect()">
                                    <option selected disabled value="null">{{ "sign_up.choose_country" | translate }}</option>
                                    <option [ngValue]="countryItem.code" *ngFor="let countryItem of countries">
                                        {{ countryItem.name }}
                                    </option>
                                </select>
                                <div class="feedback-container">
                                    @if (country.invalid) {
                                        <div class="error-msg" *ngIf="country.errors.country_unconfirmed">
                                            {{ "locations.country_unconfirmed" | translate }}
                                        </div>
                                        <div class="error-msg" *ngIf="country.errors.country_missing">
                                            {{ "locations.country_missing" | translate }}
                                        </div>

                                        @if (locationSettingsFormSubmitted || validationCheck) {
                                            <div class="error-msg" *ngIf="country.errors.required">
                                                {{ "locations.country_required" | translate }}
                                            </div>
                                        }
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="row gap-3 g-0" *ngIf="requiresMap">
                            <div class="input-group-wrapper">
                                <div class="form-control google-toggler">
                                    <div (click)="enableMap()" class="pointer google-text-wrapper">
                                        <span class="google-toggler-text">{{ "location.locate_entrance" | translate }}</span>
                                        <img style="width: 11.5px; height: auto" src="../../../../assets/icons/arrow-{{ showMap ? 'up' : 'down' }}-blue.svg" alt="arrow" />
                                    </div>

                                    <div class="input-group-wrapper-feedback" id="google">
                                        <google-map [options]="mapOptions" [height]="showMap ? '400px' : '100px'" width="100%" [center]="center" [mapId]="mapId" (mapClick)="handleClick($event)">
                                            <map-advanced-marker *ngIf="markerPosition" [position]="markerPosition" />
                                        </google-map>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-padding-wrapper" [hidden]="payoutSettings.auto && (operatorPayoutSettings ? operatorPayoutSettings.auto : true)">
                        <div class="full-width-div">
                            <h5>
                                {{ "location.payout" | translate }}
                            </h5>
                        </div>
                        <!-- PAYOUT FIELDS -->
                        <!-- operators account -->
                        <div class="input-group-wrapper-feedback" [hidden]="(filteredExternalAccounts | keyvalue).length < 1 && isCustomerOperated">
                            <app-custom-tool-tip
                                forInput="true"
                                label="{{ isCustomerOperated ? ('location.customers_bank_account' | translate) : ('location.my_bank_account' | translate) }}"
                                description="{{ 'location.payout_tooltip' | translate }}"
                            ></app-custom-tool-tip>

                            <!-- location owner -->
                            <div class="input-group">
                                <!-- formControlName is both for operators looking at their sub customers location or a default account -->
                                <select class="form-select" formControlName="external_account_key">
                                    <option [ngValue]="filteredAccount.key" *ngFor="let filteredAccount of filteredExternalAccounts | keyvalue">
                                        {{ filteredAccount.value.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <!-- if operator  -->
                        <div class="input-group-wrapper-feedback" *ngIf="isCustomerOperated">
                            <app-custom-tool-tip
                                forInput="true"
                                label="{{ !isCustomerOperated ? ('location.customers_bank_account' | translate) : ('location.my_bank_account' | translate) }}"
                                description="{{ 'location.payout_tooltip' | translate }}"
                            ></app-custom-tool-tip>
                            <div class="input-group">
                                <select class="form-select" formControlName="operator_external_account_key">
                                    <option [ngValue]="operatorFilteredExternalAccount.key" *ngFor="let operatorFilteredExternalAccount of operatorFilteredExternalAccounts | keyvalue">
                                        {{ operatorFilteredExternalAccount.value.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>

                <div *ngIf="showDeleteLocation && subCustomerAllowed">
                    <p name="">
                        {{ "location.if_delete_location" | translate }} <br /><br />
                        {{ "location.confirm_delete" | translate }}?
                    </p>
                </div>
            </ng-container>
            <ng-container modal-footer>
                <div *ngIf="showDeleteLocation && subCustomerAllowed" class="d-flex flex-column align-items-center">
                    <button class="btn btn-danger mb-3 mt-3" style="width: 100%" tabindex="-1" (click)="deleteLocation()">
                        {{ "location.delete_location" | translate }}
                    </button>
                </div>

                <div *ngIf="!showDeleteLocation" class="d-flex flex-column align-items-center">
                    <button class="btn btn-secondary mb-3 mt-3" style="width: 100%" type="submit" [disabled]="showSmallLoadingIndicator || !authService.hasLimitedAccess('location_settings', role$ | async)" (click)="updateLocation()">
                        {{ showSmallLoadingIndicator ? "" : ("location.save_settings" | translate) }}
                        <loading-indicator *ngIf="showSmallLoadingIndicator" [size]="'large'" [color]="'white'"></loading-indicator>
                    </button>
                    <a
                        tabindex="-1"
                        *ngIf="subCustomerAllowed && authService.hasLimitedAccess('location_settings', role$ | async)"
                        (click)="showDeleteLocation = true"
                        (keyup.enter)="showDeleteLocation = true"
                        [disabled]="showSmallLoadingIndicator"
                        >{{ "location.delete_location" | translate }}</a
                    >
                </div>
            </ng-container>
        </app-custom-modal>
        <app-aw-calendar-grid *ngIf="showCalendar" (calendarClosed)="updateOpen($event)" [savedWeek]="savedOpenTimes" [modalTitle]="'locations.opening_hours' | translate"></app-aw-calendar-grid>
    </ng-template>

    <!-- BOOKING SETTINGS MODAL -->
    <ng-template #bookingSettingsModal let-modal>
        <app-custom-modal>
            <ng-container modal-title>
                <h4>{{ "location.booking_setting" | translate }}</h4>
            </ng-container>
            <ng-container modal-dismiss>
                <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" /></a>
            </ng-container>
            <ng-container modal-body>
                <form tabindex="0" [formGroup]="bookingSettingsForm" class="booking-settings-form">
                    <!-- future_limit -->
                    <div class="input-group-wrapper-feedback">
                        <app-custom-tool-tip forInput="true" label="{{ 'location.booking_future_limit' | translate }}" description="{{ 'location.booking_days_in_future' | translate }}"></app-custom-tool-tip>
                        <div class="input-group">
                            <input autocomplete="new-future_limit" name="future_limit" class="form-control" type="number" min="1" max="90" formControlName="future_limit" />
                            <div class="input-group-append">
                                <span class="input-group-text">{{ "location.days" | translate }}</span>
                            </div>
                        </div>
                        <div class="feedback-container">
                            @if (bookingSettingsFormSubmitted && future_limit.invalid) {
                                <div class="error-msg" *ngIf="future_limit.errors.required">
                                    {{ "location.booking_limit_req" | translate }}
                                </div>
                                <div class="error-msg" *ngIf="future_limit.errors.min">
                                    {{ "location.booking_limit_min" | translate }}
                                </div>
                                <div class="error-msg" *ngIf="future_limit.errors.max">
                                    {{ "location.booking_limit_max" | translate }}
                                </div>
                            }
                        </div>
                    </div>

                    <!-- <div class="input-description">{{'location.booking_days_in_future' | translate}}</div> -->
                    <div class="target">
                        <div class="booking-button-wrapper">
                            <!--<label>{{'location.type_settings' | translate}}</label>-->
                            <div title="{{ 'location.washers' | translate }}">
                                <p
                                    (click)="productTypeSpecificSelector == eProductTypeSpecificSelector.Washer ? null : setProductType(eProductTypeSpecificSelector.Washer)"
                                    [className]="productTypeSpecificSelector == eProductTypeSpecificSelector.Washer ? 'booking-active-button' : 'booking-button'"
                                >
                                    {{ "location.washers" | translate }}
                                </p>
                            </div>
                            <div title="{{ 'location.dryers' | translate }}">
                                <p
                                    (click)="productTypeSpecificSelector == eProductTypeSpecificSelector.Dryer ? null : setProductType(eProductTypeSpecificSelector.Dryer)"
                                    [className]="productTypeSpecificSelector == eProductTypeSpecificSelector.Dryer ? 'booking-active-button' : 'booking-button'"
                                >
                                    {{ "location.dryers" | translate }}
                                </p>
                            </div>
                            <div title="{{ 'location.others' | translate }}">
                                <p
                                    (click)="productTypeSpecificSelector == eProductTypeSpecificSelector.Other ? null : setProductType(eProductTypeSpecificSelector.Other)"
                                    [className]="productTypeSpecificSelector == eProductTypeSpecificSelector.Other ? 'booking-active-button' : 'booking-button'"
                                >
                                    {{ "location.others" | translate }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div formGroupName="product_type_specific">
                        <div *ngIf="productTypeSpecificSelector == eProductTypeSpecificSelector.Washer" formGroupName="washer">
                            <!-- booking_enabled -->
                            <div class="input-group-wrapper">
                                <div style="display: flex; justify-content: space-between">
                                    <div class="checkbox">
                                        <input type="checkbox" id="customCheck" name="booking_enabled" formControlName="booking_enabled" />
                                        <label class="enable-label checkbox-label" for="customCheck">{{ "location.enable_booking" | translate }}</label>
                                    </div>
                                    <div *ngIf="authService.hasLimitedAccess('booking_settings', role$ | async)">
                                        <span class="label-link" (click)="applyDefaultBookingSettings()">{{ "location.reset_booking_setting" | translate }}</span>
                                    </div>
                                </div>
                            </div>

                            <!-- duration -->
                            <div class="input-group-wrapper">
                                <app-custom-tool-tip forInput="true" label="{{ 'location.booking_duration' | translate }}" description="{{ 'location.period_user_book' | translate }}"></app-custom-tool-tip>
                                <div class="input-group">
                                    <input
                                        autocomplete="new-duration"
                                        name="duration"
                                        [className]="washer_booking_enabled.value ? 'form-control' : 'form-control disabled-control'"
                                        type="number"
                                        min="30"
                                        max="180"
                                        formControlName="duration"
                                    />
                                    <div [className]="washer_booking_enabled.value ? 'input-group-append' : 'input-group-append disabled-control'">
                                        <span class="input-group-text">{{ "location.min" | translate }}.</span>
                                    </div>
                                </div>

                                <div *ngIf="bookingSettingsFormSubmitted && washer_duration.invalid">
                                    <div class="error-msg" *ngIf="washer_duration.errors.required">
                                        {{ "location.book_duration_req" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="washer_duration.errors.min">
                                        {{ "location.book_duration_min" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="washer_duration.errors.max">
                                        {{ "location.book_duration_max" | translate }}
                                    </div>
                                </div>
                            </div>

                            <!-- limit -->
                            <div class="input-group-wrapper">
                                <app-custom-tool-tip forInput="true" label="{{ 'location.booking_limit' | translate }}" description="{{ 'location.number_active_bookings' | translate }}"></app-custom-tool-tip>
                                <div class="input-group active-booking">
                                    <input autocomplete="new-limit" name="limit" [className]="washer_booking_enabled.value ? 'form-control' : 'form-control disabled-control'" type="number" min="1" max="20" formControlName="limit" />
                                </div>
                                <div *ngIf="bookingSettingsFormSubmitted && washer_limit.invalid">
                                    <div class="error-msg" *ngIf="washer_limit.errors.required">
                                        {{ "location.active_limit_req" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="washer_limit.errors.min">
                                        {{ "location.active_limit_min" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="washer_limit.errors.max">
                                        {{ "location.active_limit_max" | translate }}
                                    </div>
                                </div>
                            </div>

                            <!-- pre_reservation_time_limit -->
                            <div class="input-group-wrapper">
                                <app-custom-tool-tip forInput="true" label="{{ 'location.machine_blocked' | translate }}" description="{{ 'location.machine_blocked_to_ensure' | translate }}"></app-custom-tool-tip>
                                <div class="input-group">
                                    <input
                                        autocomplete="new-pre_reservation_time_limit"
                                        name="pre_reservation_time_limit"
                                        [className]="washer_booking_enabled.value ? 'form-control' : 'form-control disabled-control'"
                                        type="number"
                                        min="0"
                                        max="180"
                                        formControlName="pre_reservation_time_limit"
                                    />
                                    <div [className]="washer_booking_enabled.value ? 'input-group-append' : 'input-group-append disabled-control'">
                                        <span class="input-group-text">{{ "location.min" | translate }}.</span>
                                    </div>
                                </div>
                                <div *ngIf="bookingSettingsFormSubmitted && washer_pre_reservation_time_limit.invalid">
                                    <div class="error-msg" *ngIf="washer_pre_reservation_time_limit.errors.required">
                                        {{ "location.pre_reservation_req" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="washer_pre_reservation_time_limit.errors.min">
                                        {{ "location.pre_reservation_min" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="washer_pre_reservation_time_limit.errors.max">
                                        {{ "location.pre_reservation_max" | translate }}
                                    </div>
                                </div>
                            </div>

                            <!-- post_reservation_time_limit -->
                            <div class="input-group-wrapper">
                                <app-custom-tool-tip forInput="true" label="{{ 'location.machine_available' | translate }}" description="{{ 'location.machine_not_started' | translate }}"></app-custom-tool-tip>
                                <div class="input-group">
                                    <input
                                        autocomplete="new-post_reservation_time_limit"
                                        name="post_reservation_time_limit"
                                        [className]="washer_booking_enabled.value ? 'form-control' : 'form-control disabled-control'"
                                        type="number"
                                        min="0"
                                        max="180"
                                        formControlName="post_reservation_time_limit"
                                    />
                                    <div [className]="washer_booking_enabled.value ? 'input-group-append' : 'input-group-append disabled-control'">
                                        <span class="input-group-text">{{ "location.min" | translate }}.</span>
                                    </div>
                                </div>
                                <div *ngIf="bookingSettingsFormSubmitted && washer_post_reservation_time_limit.invalid">
                                    <div class="error-msg" *ngIf="washer_post_reservation_time_limit.errors.required">
                                        {{ "location.post_reservation_req" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="washer_post_reservation_time_limit.errors.min">
                                        {{ "location.post_reservation_min" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="washer_post_reservation_time_limit.errors.max">
                                        {{ "location.post_reservation_max" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="productTypeSpecificSelector == eProductTypeSpecificSelector.Dryer" formGroupName="dryer">
                            <!-- booking_enabled -->
                            <div class="input-group-wrapper">
                                <div style="display: flex; justify-content: space-between">
                                    <div class="checkbox">
                                        <input type="checkbox" id="customCheck" name="booking_enabled" formControlName="booking_enabled" />
                                        <label class="enable-label checkbox-label" for="customCheck">{{ "location.enable_booking" | translate }}</label>
                                    </div>
                                    <div *ngIf="authService.hasLimitedAccess('booking_settings', role$ | async)">
                                        <span class="label-link" (click)="applyDefaultBookingSettings()">{{ "location.reset_booking_setting" | translate }}</span>
                                    </div>
                                </div>
                            </div>

                            <!-- duration -->
                            <div class="input-group-wrapper">
                                <app-custom-tool-tip forInput="true" label="{{ 'location.booking_duration' | translate }}" description="{{ 'location.period_user_book' | translate }}"></app-custom-tool-tip>
                                <div class="input-group">
                                    <input
                                        autocomplete="new-duration"
                                        name="duration"
                                        [className]="dryer_booking_enabled.value ? 'form-control' : 'form-control disabled-control'"
                                        type="number"
                                        min="30"
                                        max="180"
                                        formControlName="duration"
                                    />
                                    <div [className]="dryer_booking_enabled.value ? 'input-group-append' : 'input-group-append disabled-control'">
                                        <span class="input-group-text">{{ "location.min" | translate }}.</span>
                                    </div>
                                </div>
                                <div *ngIf="bookingSettingsFormSubmitted && dryer_duration.invalid">
                                    <div class="error-msg" *ngIf="dryer_duration.errors.required">
                                        {{ "location.book_duration_req" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="dryer_duration.errors.min">
                                        {{ "location.book_duration_min" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="dryer_duration.errors.max">
                                        {{ "location.book_duration_max" | translate }}
                                    </div>
                                </div>
                            </div>

                            <!-- limit -->
                            <div class="input-group-wrapper">
                                <app-custom-tool-tip forInput="true" label="{{ 'location.booking_limit' | translate }}" description="{{ 'location.active_bookings' | translate }}"></app-custom-tool-tip>
                                <div class="input-group active-booking">
                                    <input autocomplete="new-limit" name="limit" [className]="dryer_booking_enabled.value ? 'form-control' : 'form-control disabled-control'" type="number" min="1" max="20" formControlName="limit" />
                                </div>
                            </div>
                            <div *ngIf="bookingSettingsFormSubmitted && dryer_limit.invalid">
                                <div class="error-msg" *ngIf="dryer_limit.errors.required">
                                    {{ "location.active_limit_req" | translate }}
                                </div>
                                <div class="error-msg" *ngIf="dryer_limit.errors.min">
                                    {{ "location.active_limit_min" | translate }}
                                </div>
                                <div class="error-msg" *ngIf="dryer_limit.errors.max">
                                    {{ "location.active_limit_max" | translate }}
                                </div>
                            </div>

                            <!-- pre_reservation_time_limit -->
                            <div class="input-group-wrapper">
                                <app-custom-tool-tip forInput="true" label="{{ 'location.machine_blocked' | translate }}" description="{{ 'location.machine_blocked_to_ensure' | translate }}"></app-custom-tool-tip>
                                <div class="input-group">
                                    <input
                                        autocomplete="new-pre_reservation_time_limit"
                                        name="pre_reservation_time_limit"
                                        [className]="dryer_booking_enabled.value ? 'form-control' : 'form-control disabled-control'"
                                        type="number"
                                        min="0"
                                        max="180"
                                        formControlName="pre_reservation_time_limit"
                                    />
                                    <div [className]="dryer_booking_enabled.value ? 'input-group-append' : 'input-group-append disabled-control'">
                                        <span class="input-group-text">{{ "location.min" | translate }}.</span>
                                    </div>
                                </div>
                                <div *ngIf="bookingSettingsFormSubmitted && dryer_pre_reservation_time_limit.invalid">
                                    <div class="error-msg" *ngIf="dryer_pre_reservation_time_limit.errors.required">
                                        {{ "location.pre_reservation_req" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="dryer_pre_reservation_time_limit.errors.min">
                                        {{ "location.pre_reservation_min" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="dryer_pre_reservation_time_limit.errors.max">
                                        {{ "location.pre_reservation_max" | translate }}
                                    </div>
                                </div>
                            </div>

                            <!-- post_reservation_time_limit -->
                            <div class="input-group-wrapper">
                                <app-custom-tool-tip forInput="true" label="{{ 'location.machine_available' | translate }}" description="{{ 'location.machine_not_started' | translate }}"></app-custom-tool-tip>
                                <div class="input-group">
                                    <input
                                        autocomplete="new-post_reservation_time_limit"
                                        name="post_reservation_time_limit"
                                        [className]="dryer_booking_enabled.value ? 'form-control' : 'form-control disabled-control'"
                                        type="number"
                                        min="0"
                                        max="180"
                                        formControlName="post_reservation_time_limit"
                                    />
                                    <div [className]="dryer_booking_enabled.value ? 'input-group-append' : 'input-group-append disabled-control'">
                                        <span class="input-group-text">{{ "location.min" | translate }}.</span>
                                    </div>
                                </div>
                                <div *ngIf="bookingSettingsFormSubmitted && washer_post_reservation_time_limit.invalid">
                                    <div class="error-msg" *ngIf="washer_post_reservation_time_limit.errors.required">
                                        {{ "location.post_reservation_req" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="washer_post_reservation_time_limit.errors.min">
                                        {{ "location.post_reservation_min" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="washer_post_reservation_time_limit.errors.max">
                                        {{ "location.post_reservation_max" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="productTypeSpecificSelector == eProductTypeSpecificSelector.Other" formGroupName="other">
                            <!-- booking_enabled -->
                            <div class="input-group-wrapper">
                                <div style="display: flex; justify-content: space-between">
                                    <div class="checkbox">
                                        <input type="checkbox" id="customCheck" name="booking_enabled" formControlName="booking_enabled" />
                                        <label class="enable-label checkbox-label" for="customCheck">{{ "location.enable_booking" | translate }}</label>
                                    </div>
                                    <div *ngIf="authService.hasLimitedAccess('booking_settings', role$ | async)">
                                        <span class="label-link" (click)="applyDefaultBookingSettings()">{{ "location.reset_booking_setting" | translate }}</span>
                                    </div>
                                </div>
                            </div>

                            <!-- duration -->
                            <div class="input-group-wrapper">
                                <app-custom-tool-tip forInput="true" label="{{ 'location.booking_duration' | translate }}" description="{{ 'location.period_user_book' | translate }}"></app-custom-tool-tip>
                                <div class="input-group">
                                    <input
                                        autocomplete="new-duration"
                                        name="duration"
                                        [className]="other_booking_enabled.value ? 'form-control' : 'form-control disabled-control'"
                                        type="number"
                                        min="30"
                                        max="180"
                                        formControlName="duration"
                                    />
                                    <div [className]="other_booking_enabled.value ? 'input-group-append' : 'input-group-append disabled-control'">
                                        <span class="input-group-text">{{ "location.min" | translate }}.</span>
                                    </div>
                                </div>

                                <div *ngIf="bookingSettingsFormSubmitted && other_duration.invalid">
                                    <div class="error-msg" *ngIf="other_duration.errors.required">
                                        {{ "location.book_duration_req" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="other_duration.errors.min">
                                        {{ "location.book_duration_min" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="other_duration.errors.max">
                                        {{ "location.book_duration_max" | translate }}
                                    </div>
                                </div>
                            </div>

                            <!-- limit -->
                            <div class="input-group-wrapper">
                                <app-custom-tool-tip forInput="true" label="{{ 'location.booking_limit' | translate }}" description="{{ 'location.num_active_bookings' | translate }}"></app-custom-tool-tip>
                                <div class="input-group active-booking">
                                    <input autocomplete="new-limit" name="limit" [className]="other_booking_enabled.value ? 'form-control' : 'form-control disabled-control'" type="number" min="1" max="20" formControlName="limit" />
                                </div>

                                <div *ngIf="bookingSettingsFormSubmitted && other_limit.invalid">
                                    <div class="error-msg" *ngIf="other_limit.errors.required">
                                        {{ "location.active_limit_req" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="other_limit.errors.min">
                                        {{ "location.active_limit_min" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="other_limit.errors.max">
                                        {{ "location.active_limit_max" | translate }}
                                    </div>
                                </div>
                            </div>

                            <!-- pre_reservation_time_limit -->
                            <div class="input-group-wrapper">
                                <app-custom-tool-tip forInput="true" label="{{ 'location.machine_blocked' | translate }}" description="{{ 'location.machine_blocked_to_ensure' | translate }}"></app-custom-tool-tip>
                                <div class="input-group">
                                    <input
                                        autocomplete="new-pre_reservation_time_limit"
                                        name="pre_reservation_time_limit"
                                        [className]="other_booking_enabled.value ? 'form-control' : 'form-control disabled-control'"
                                        type="number"
                                        min="0"
                                        max="180"
                                        formControlName="pre_reservation_time_limit"
                                    />
                                    <div [className]="other_booking_enabled.value ? 'input-group-append' : 'input-group-append disabled-control'">
                                        <span class="input-group-text">{{ "location.min" | translate }}.</span>
                                    </div>
                                </div>
                                <div *ngIf="bookingSettingsFormSubmitted && other_pre_reservation_time_limit.invalid">
                                    <div class="error-msg" *ngIf="other_pre_reservation_time_limit.errors.required">
                                        {{ "location.pre_reservation_req" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="other_pre_reservation_time_limit.errors.min">
                                        {{ "location.pre_reservation_min" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="other_pre_reservation_time_limit.errors.max">
                                        {{ "location.pre_reservation_max" | translate }}
                                    </div>
                                </div>
                            </div>

                            <!-- post_reservation_time_limit -->
                            <div class="input-group-wrapper">
                                <app-custom-tool-tip forInput="true" label="{{ 'location.machine_available' | translate }}" description="{{ 'location.machine_not_started' | translate }}"></app-custom-tool-tip>
                                <div class="input-group">
                                    <input
                                        autocomplete="new-post_reservation_time_limit"
                                        name="post_reservation_time_limit"
                                        [className]="other_booking_enabled.value ? 'form-control' : 'form-control disabled-control'"
                                        type="number"
                                        min="0"
                                        max="180"
                                        formControlName="post_reservation_time_limit"
                                    />
                                    <div [className]="other_booking_enabled.value ? 'input-group-append' : 'input-group-append disabled-control'">
                                        <span class="input-group-text">{{ "location.min" | translate }}.</span>
                                    </div>
                                </div>
                                <div *ngIf="bookingSettingsFormSubmitted && other_post_reservation_time_limit.invalid">
                                    <div class="error-msg" *ngIf="other_post_reservation_time_limit.errors.required">
                                        {{ "location.post_reservation_req" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="other_post_reservation_time_limit.errors.min">
                                        {{ "location.post_reservation_min" | translate }}
                                    </div>
                                    <div class="error-msg" *ngIf="other_post_reservation_time_limit.errors.max">
                                        {{ "location.post_reservation_max" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ng-container>
            <ng-container modal-footer>
                <div class="d-grid gap-2">
                    <button class="btn btn-secondary" [disabled]="savingBookingSettingsForm || !authService.hasLimitedAccess('location_settings', role$ | async)" (click)="saveBookingSettings()">
                        {{ savingBookingSettingsForm ? "" : ("location.save_settings" | translate) }}
                        <loading-indicator *ngIf="savingBookingSettingsForm" [size]="'large'" [color]="'white'"></loading-indicator>
                    </button>
                </div>
            </ng-container>
        </app-custom-modal>
    </ng-template>

    <ng-template #deviceOrderingModal let-modal>
        <app-device-order-modal [deviceList]="deviceList" [locationId]="location_id" [modalRef]="modalRef" (UpdateListEmitter)="updateList($event)"></app-device-order-modal>
    </ng-template>
</div>
