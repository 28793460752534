import { HttpEvent, HttpHandlerFn, HttpHeaders, HttpRequest } from '@angular/common/http';
import { from, Observable, lastValueFrom } from 'rxjs';
import { ControlledUser } from '../../../shared_models/controlled-user';
import { DashboardUser } from '../../dashboard-models/dashboard-user';
import firebase from 'firebase/compat/app';
import * as helperService from '@services/helper/helper.service';

type FirebaseUser = firebase.User;
const unprotectedRoutes: string[] = ['/assets/i18n/', '/api_account/team_member/'];

export function headerInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    return from(handleAccess(req, next));
}

async function handleAccess(originalRequest: HttpRequest<unknown>, next: HttpHandlerFn): Promise<HttpEvent<unknown>> {
    let modifiedRequest = originalRequest;
    // Check if the request URL matches any of the unprotected routes
    const isUnprotectedRoute = unprotectedRoutes.find(route => originalRequest.url.includes(route));

    // If the request URL matches an unprotected route, handle the request without the session token
    if (isUnprotectedRoute) {
        //Handle request without session token
        return lastValueFrom(next(originalRequest));
    } else {
        // Handle the request with the session token
        try {
            modifiedRequest = await addDefaultHeaders(modifiedRequest);
        } catch (error) {
            console.error('Error adding default headers', error);
            return lastValueFrom(next(originalRequest));
        }
        return handleAdditionalHeaders(modifiedRequest, next);
    }
}

function handleAdditionalHeaders(modifiedRequest: HttpRequest<unknown>, next: HttpHandlerFn): Promise<HttpEvent<unknown>> {
    const baseHeadersResponse = handleBaseHeadersOnly(modifiedRequest, next);
    if (baseHeadersResponse instanceof Promise) {
        return baseHeadersResponse;
    }

    modifiedRequest = handleControlledUser(modifiedRequest);
    const controlOnlyResponse = handleControlOnly(modifiedRequest, next);
    if (controlOnlyResponse instanceof Promise) {
        return controlOnlyResponse;
    }

    modifiedRequest = handleSkipOwner(modifiedRequest);

    return lastValueFrom(next(modifiedRequest));
}

async function addDefaultHeaders(modifiedRequest: HttpRequest<unknown>): Promise<HttpRequest<unknown>> {
    try {
        const authToken: string = (await fetchAuthState())?.session_token;
        if (authToken) {
            return modifiedRequest.clone({
                headers: modifiedRequest.headers.append('Authorization', authToken).append('Content-Type', 'application/json')
            });
        }
    } catch (error) {
        console.error('Error fetching auth state', error);
        throw error;
    }

    return modifiedRequest;
}

async function fetchAuthState(): Promise<{ user: DashboardUser; session_token: string } | null> {
    const user = firebase.auth().currentUser;
    if (!user) {
        return null;
    }
    const token = await user.getIdToken();
    if (!token) {
        if (!new helperService.HelperService().unguardedUrl(window.location.pathname)) {
            await this.signOut();
        }
    }
    const userObj: DashboardUser = JSON.parse(localStorage.getItem('user'));
    return { user: userObj, session_token: token };
}

function handleBaseHeadersOnly(modifiedRequest: HttpRequest<unknown>, next: HttpHandlerFn): Promise<HttpEvent<unknown>> | HttpRequest<unknown> {
    if (modifiedRequest.headers.has('X-Base-Headers-Only')) {
        modifiedRequest = modifiedRequest.clone({ headers: modifiedRequest.headers.delete('X-Base-Headers-Only') });
        return lastValueFrom(next(modifiedRequest));
    }
    return modifiedRequest;
}

function handleControlledUser(modifiedRequest: HttpRequest<unknown>): HttpRequest<unknown> {
    const controlledUser: ControlledUser = JSON.parse(localStorage.getItem('controlled_user'));
    if (controlledUser) {
        const controlleduid: string = controlledUser.uid.split('_operated_by_')[0];
        const newHeaders: HttpHeaders = modifiedRequest.headers.append('controlleduid', controlleduid);
        return modifiedRequest.clone({ headers: newHeaders });
    }
    return modifiedRequest;
}

function handleControlOnly(modifiedRequest: HttpRequest<unknown>, next: HttpHandlerFn): Promise<HttpEvent<unknown>> | HttpRequest<unknown> {
    if (modifiedRequest.headers.has('X-Control-only')) {
        modifiedRequest = modifiedRequest.clone({ headers: modifiedRequest.headers.delete('X-Control-only') });
        return lastValueFrom(next(modifiedRequest));
    }
    return modifiedRequest;
}

function handleSkipOwner(modifiedRequest: HttpRequest<unknown>): HttpRequest<unknown> {
    if (!modifiedRequest.headers.has('X-Skip-Owner')) {
        const owner: string = localStorage.getItem('owner');
        if (owner) {
            const newHeaders: HttpHeaders = modifiedRequest.headers.append('owner', owner);
            modifiedRequest = modifiedRequest.clone({ headers: newHeaders });
        }
    } else {
        modifiedRequest = modifiedRequest.clone({ headers: modifiedRequest.headers.delete('X-Skip-Owner') });
    }
    return modifiedRequest;
}
