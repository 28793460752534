<div class="container mb-5">
    <div class="title-area">
        <div>
            <h4 class="page-title">{{ "users.user.user" | translate }}</h4>
        </div>
        <div>
            <!-- FEATURE TOGGLE, ID = USER_MANAGEMENT_ACTION_BUTTON  // Remove false when feature is ready on APP-->
            @defer {
                <app-animated-button *ngIf="currentUser && currentUser.platform !== 'terminal'" [disabled]="showLoadingIndicator" [menuTitle]="'navbar.actions' | translate" [forceClose]="forceClose">
                    <div class="animated-button-btns-wrapper" *ngIf="!showLoadingIndicator">
                        <!-- Hidden for future release - Apply credit payment -->
                        <button class="btn btn-secondary btn-tiny" *ngIf="false" (click)="openModal(ApplyCreditPayment)">
                            {{ "users.user.apply_credit_payment" | translate }}
                        </button>
                        <!-- Hidden for future release - Apply Coupon -->
                        <button [disabled]="!fetchedCoupons" class="btn btn-secondary btn-tiny" (click)="openModal(ApplyCoupon)">
                            {{ fetchedCoupons ? ("users.user.apply_coupon" | translate) : "" }}
                            <loading-indicator *ngIf="!fetchedCoupons" [size]="'small'" [color]="'white'"></loading-indicator>
                        </button>
                        <button
                            [className]="showSmallIndicatorTest ? 'btn btn-secondary-disabled btn-tiny hasLoadingCapability' : 'btn btn-secondary btn-tiny hasLoadingCapability'"
                            (click)="handleTestUserSubmit()"
                            [disabled]="showSmallIndicatorTest"
                        >
                            {{ showSmallIndicatorTest ? "" : currentUser.pm === "test" ? ("users.user.remove_test_user" | translate) : ("users.user.make_test_user" | translate) }}
                            <loading-indicator *ngIf="showSmallIndicatorTest" [size]="'small'" [color]="'white'"></loading-indicator>
                        </button>
                        <button
                            [className]="showSmallIndicatorBlock ? 'btn btn-secondary-disabled btn-tiny hasLoadingCapability' : 'btn btn-secondary btn-tiny hasLoadingCapability'"
                            (click)="handleBlockUserSubmit()"
                            [disabled]="showSmallIndicatorBlock"
                        >
                            {{ showSmallIndicatorBlock ? "" : currentUser.blocked ? ("users.user.unblock_user" | translate) : ("users.user.block_user" | translate) }}
                            <loading-indicator *ngIf="showSmallIndicatorBlock" [size]="'small'" [color]="'white'"></loading-indicator>
                        </button>
                    </div>
                </app-animated-button>
            }
        </div>
    </div>

    <hr class="aw-hr" />

    <div *ngIf="showLoadingIndicator && !userNotFound" class="loading-height">
        <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
    </div>

    <div [className]="currentUser.owner_uid !== user.uid && isMobile ? 'main-container bigger-gap' : 'main-container'" *ngIf="currentUser !== undefined && !userNotFound">
        <div [className]="currentUser.subcustomer_name ? 'left-boxes more-gap' : 'left-boxes'" *ngIf="!showLoadingIndicator">
            <!-- User details -->

            <div class="user-information-box">
                <div class="inner-content">
                    <!-- name -->
                    <h6 class="name">
                        {{ currentUser.name ? currentUser.name.replaceAll("_", " ") : "-" }}
                    </h6>
                    <!-- phone -->
                    <span class="phone" *ngIf="currentUser.phone_number !== '-'">
                        {{ currentUser.phone ? currentUser.call_code + " " + currentUser.phone : "-" }}
                    </span>

                    <ng-container *ngIf="currentUser.user_title !== 'no_title' && !currentUser.blocked && currentUser.user_role === 'normal'">
                        <div class="user-title {{ currentUser.user_title.replace('_', '-') }}" [title]="'users.user.title' + currentUser.user_title | translate">
                            <span>{{ "users.user.titles." + currentUser.user_title | translate }}</span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="currentUser.blocked">
                        <div class="user-title blocked" [title]="'users.user.title.blocked' | translate">
                            <span>{{ "users.user.titles.blocked" | translate }}</span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="currentUser.pm === 'test' && !currentUser.blocked">
                        <div class="user-title test-user" [title]="'users.user.title.test_user' | translate">
                            <span>{{ "users.user.titles.test_user" | translate }}</span>
                        </div>
                    </ng-container>
                    <div class="insights">
                        <app-custom-tool-tip [spaceBetween]="true" [gap]="0.25" *ngIf="currentUser.total_spend && getLength(currentUser.total_spend) > 1" [tooltipTitle]="'users.user.all_currencies' | translate" [placementUp]="true">
                            <div title>
                                <label>{{ "users.user.insights" | translate }}</label>
                            </div>
                            <div description>
                                <p></p>
                                {{ "users.total_spend" | translate }}
                            </div>
                            <div description>
                                <div class="expand-currencies">
                                    <div *ngFor="let rec of currentUser.total_spend | keyvalue" style="width: 100%; text-align: left">
                                        <div>
                                            {{ rec.key.toUpperCase() }}:
                                            {{ helperService.localizeNumber(rec.value / 100) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div description *ngIf="currentUser.refunds">
                                <p></p>
                                {{ "users.refunds" | translate }}
                            </div>
                            <div description>
                                <div class="expand-currencies">
                                    <div *ngFor="let rec of currentUser.refund | keyvalue" style="width: 100%; text-align: left">
                                        <div>
                                            {{ rec.key.toUpperCase() }}:
                                            {{ helperService.localizeNumber(rec.value / 100) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </app-custom-tool-tip>
                        <hr class="aw-hr" />
                        <div class="insight-item">
                            <div class="insight-label">
                                {{ "users.total_spend" | translate }}
                            </div>
                            <div title>
                                <h4 style="font-weight: 400; font-size: 16px; margin-bottom: 0">
                                    {{ helperService.localizeNumberWithCurrency(currentUser.total_spend_sum / 100, undefined, undefined, currentUser.currency) }}
                                </h4>
                            </div>
                        </div>
                        <div class="insight-item">
                            <div class="insight-label">
                                {{ "users.payments" | translate }}
                            </div>
                            <div class="insight-value">
                                {{ currentUser.payments }}
                            </div>
                        </div>
                        <div class="insight-item">
                            <div class="insight-label">
                                {{ "users.refunds" | translate }}
                            </div>
                            <div class="insight-value">
                                <div title>
                                    <h4 style="font-weight: 400; font-size: 16px; margin-bottom: 0">
                                        {{ helperService.localizeNumberWithCurrency(currentUser.refunds_sum / 100, undefined, undefined, currentUser.currency) }}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <hr class="aw-hr" />
                    </div>
                    <div class="cus-id-wrapper" *ngIf="currentUser.stripe_cus_id || currentUser.platform === 'terminal'">
                        <span class="customer_id">ID: {{ currentUser.stripe_cus_id ? currentUser.stripe_cus_id : currentUser.uid }}</span>
                    </div>
                    <div class="sub-cus-email" *ngIf="currentUser.subcustomer_name">
                        <span>{{ "users.user.user_at" | translate }}:</span>
                        <span [title]="currentUser.subcustomer_name" class="customer-email"> {{ currentUser.subcustomer_name }}</span>
                    </div>
                </div>
            </div>

            @defer (when fetchedCoupons && appliedCoupons.length > 0) {
                <div class="coupon-list-box" *ngIf="fetchedCoupons && appliedCoupons.length > 0">
                    <div class="coupons">
                        <div class="edit-title">
                            <span>{{ "coupon.coupons" | translate }}</span>
                            <u class="pointer" (click)="openModal(EditCoupons)">{{ "device.edit" | translate }}</u>
                        </div>
                        <hr class="aw-hr" />
                        <div class="coupon-inner-wrapper">
                            <div class="coupon-item" *ngFor="let coupon of appliedCoupons; let last = last">
                                <div class="status">
                                    <img src="../../../../assets/icons/{{ getUIDetails(coupon).icon }}" width="14px" height="14px" alt="coupon" />
                                </div>
                                <div class="coupon-value">
                                    <span [ngClass]="getClassForCoupon(coupon)">{{ getUIDetails(coupon).text }}</span>
                                    <span class="coupon-name" style="margin-bottom: 1px">{{ coupon.name }}</span>
                                    <span class="coupon-terms"
                                        >{{ getTerms(coupon) }}.
                                        @if (coupon) {
                                            @if (coupon.status === "valid") {
                                                @if (coupon.target_amount) {
                                                    <br />
                                                    {{ "users.user.balance" | translate }}:
                                                    {{ helperService.localizeNumberWithCurrency((coupon.remaining_amount ? coupon.remaining_amount : 0) / 100, coupon.currenct) }}
                                                    <br />
                                                    {{ "users.user.count" | translate }}:
                                                    {{ helperService.localizeNumberWithCurrency(coupon.amount_since_used / 100, coupon.currency) }}
                                                    / {{ helperService.localizeNumberWithCurrency(coupon.target_amount / 100, coupon.currency) }}
                                                } @else if (coupon.target_cycles) {
                                                    <br />
                                                    {{ "users.user.count" | translate }}: {{ coupon.cycles_since_used }}/{{ coupon.target_cycles }}
                                                }
                                            }

                                            @if (coupon.status === "active") {
                                                <br />
                                                @if (coupon.remaining_amount) {
                                                    {{ "users.user.balance" | translate }}:
                                                    {{ helperService.localizeNumberWithCurrency((coupon.remaining_amount ? coupon.remaining_amount : 0) / 100, coupon.currency) }}
                                                } @else if (coupon.remaining_cycles) {
                                                    {{ "users.user.count" | translate }}: {{ coupon.remaining_cycles }}
                                                }
                                            }
                                        }
                                    </span>
                                </div>
                                <hr *ngIf="!last" class="lighter-aw-hr" />
                            </div>
                        </div>
                    </div>
                </div>
            }

            <!-- Hidden for future release - Credit Payment -->
            <div class="credit-payment-box" *ngIf="false">
                <div>
                    <div class="edit-title">
                        <span>{{ "users.user.credit_payment" | translate }}</span>
                        <u class="pointer" (click)="openModal(CreditPaymentInfo)">{{ "device.edit" | translate }}</u>
                    </div>
                    <hr class="aw-hr" />
                    <div class="d-flex flex-column">
                        <label style="font-size: 14px"
                            ><strong>{{ currentUsedPaymentMethod !== "terminal" ? (currentUsedPaymentMethod.in_use ? ("users.user.in_use" | translate) : "") : "" }}</strong></label
                        >
                        <label style="font-size: 12px">{{ currentUsedPaymentMethod.exp_date }}</label>
                        <div style="display: flex; justify-content: space-between">
                            <label style="font-size: 12px; width: 25%">{{ "users.user.user_id" | translate }}: </label>
                            <label [title]="currentUser.uid" class="overflow-column" style="font-size: 12px; width: 75%">{{ currentUser.uid }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Payments -->

        <div class="right-box">
            <div class="main-box">
                <div class="main-inner-content">
                    <!-- Mobile -->
                    <div class="mobile-body-title" *ngIf="isMobile">
                        <div class="d-flex" style="justify-content: space-between">
                            <h6 class="title">{{ "users.payments" | translate }}</h6>
                            <div (click)="goToTransactions()" class="pointer">
                                <span>{{ "users.user.open_complete_list" | translate }}</span>
                                <img src="../../../../assets/icons/arrow-right-black.svg" />
                            </div>
                        </div>
                        <hr class="aw-hr" />
                    </div>

                    <div class="body-title" *ngIf="!isMobile">
                        <h6 class="title">{{ "users.payments" | translate }}</h6>
                        <div (click)="goToTransactions()" class="pointer">
                            <span>{{ "users.user.open_complete_list" | translate }}</span>
                            <img src="../../../../assets/icons/arrow-right-black.svg" />
                        </div>
                    </div>

                    <div class="payment-box">
                        <div class="payments-wrapper" *ngIf="!showLoadingIndicator && !isMobile && currentUser.transactions" style="position: relative">
                            <div class="empty-performance-overlay" *ngIf="this.currentUser.transactions[0] === ''">
                                <p>{{ "users.user.no_payments" | translate }}</p>
                            </div>
                            <table style="width: 100%">
                                <tr>
                                    <th>{{ "transactions.transaction_id" | translate }}</th>
                                    <th>{{ "misc.date" | translate }}</th>
                                    <th class="align-right">{{ "misc.amount" | translate }}</th>
                                    <th>{{ "misc.location" | translate }}</th>
                                    <th>{{ "misc.device" | translate }}</th>
                                    <th>{{ "misc.refund" | translate }}</th>
                                </tr>
                                <tbody>
                                    <tr *ngFor="let payment of currentUser.transactions; let i = index">
                                        <ng-container *ngIf="this.currentUser.transactions[0] !== ''">
                                            <td class="payment-id overflow-column" style="width: 17%" [title]="payment.payment_id">
                                                <div style="width: 100%; display: flex; justify-content: space-between">
                                                    <p class="clip" id="{{ getId(payment.payment_id, i) }}">
                                                        {{ payment.payment_id }}
                                                    </p>
                                                    <button class="my-mini-btn copy-btn" (click)="helperService.copySomeText(payment.payment_id, getId(payment.payment_id, i))">
                                                        <img src="../../../assets/icons/copy-icon.svg" alt="" />
                                                    </button>
                                                </div>
                                            </td>
                                            <td class="overflow-column" style="width: 21%" [title]="getLocaleDate(payment.timestamp)">
                                                {{ getLocaleDate(payment.timestamp) }}
                                            </td>
                                            <td class="overflow-column align-right" style="width: 11%" [title]="formatCurrency(payment.amount, payment.currency)">
                                                {{ formatCurrency(payment.amount, payment.currency) }}
                                            </td>
                                            <td class="overflow-column" style="width: 20%" [title]="payment.location_name">
                                                {{ payment.location_name }}
                                            </td>
                                            <td class="overflow-column" style="width: 17%" [title]="payment.device_name">
                                                {{ payment.device_name }}
                                            </td>
                                            <td
                                                class="overflow-column"
                                                [title]="payment.refund ? ('misc.refunded' | translate) : payment.refund_status ? ('transactions.refund_status.' + payment.refund_status | translate) : ('misc.refund' | translate)"
                                                style="width: 15%"
                                            >
                                                <u (click)="openRefundModal(i, Refund)" class="pointer" *ngIf="!payment.refund && !payment.refund_status">{{ "users.refund_no" | translate }}</u
                                                ><span class="refunded" *ngIf="payment.refund">{{ "users.refunded_yes" | translate }}</span>
                                                <span class="refunded" *ngIf="!payment.refund && payment.refund_status">{{ "transactions.refund_status." + payment.refund_status | translate }}</span>
                                            </td>
                                        </ng-container>
                                        <ng-container *ngIf="this.currentUser.transactions[0] === ''">
                                            <td class="payment-id" style="height: 21px; width: 25%; position: relative"></td>
                                            <td class="overflow-column" style="width: 17.5%"></td>
                                            <td class="overflow-column align-right"></td>
                                            <td class="overflow-column"></td>
                                            <td class="overflow-column"></td>
                                            <td class="overflow-column"></td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="mobile-body" *ngIf="!showLoadingIndicator && isMobile">
                            <div class="empty-performance-overlay" *ngIf="currentUser.transactions[0] === ''">
                                <p>{{ "users.user.no_payments" | translate }}</p>
                            </div>

                            <ng-container *ngFor="let payment of currentUser.transactions | slice: 0 : 3; let i = index">
                                <div class="mobile-payment-container" *ngIf="!(i !== 0 && currentUser.transactions[0] === '')">
                                    <!-- Hide if not first and no transactions -->
                                    <tbody>
                                        <div>
                                            <div *ngIf="currentUser.transactions[0] !== ''" class="mobile-payment-item">
                                                <div class="d-flex column">
                                                    <p>{{ payment.payment_id }}</p>
                                                    <button class="my-mini-btn" (click)="copyPaymentId(payment.payment_id)">
                                                        <img src="../../../assets/icons/copy-icon.svg" alt="" />
                                                    </button>
                                                </div>

                                                <p>{{ helperService.getLocalizedDate(payment.timestamp) + " - " + helperService.getLocalizedTime(payment.timestamp) }}</p>
                                            </div>
                                            <div *ngIf="currentUser.transactions[0] !== ''" class="mobile-payment-item">
                                                <p>
                                                    {{ payment.location_name }}
                                                </p>
                                                <p>{{ formatCurrency(payment.amount, payment.currency) }}</p>
                                            </div>
                                            <div *ngIf="currentUser.transactions[0] !== ''" class="mobile-payment-item">
                                                <p>{{ payment.device_name }}</p>
                                                <p>
                                                    <u (click)="openRefundModal(i, Refund)" class="pointer" *ngIf="!payment.refund && !payment.refund_status">{{ "misc.refund" | translate }}</u>
                                                    {{ payment.refund ? ("misc.refunded" | translate) : payment.refund_status ? ("transactions.refund_status." + payment.refund_status | translate) : null }}
                                                </p>
                                            </div>
                                            <div style="height: 24px" *ngIf="currentUser.transactions[0] === ''"></div>
                                            <div style="height: 24px" *ngIf="currentUser.transactions[0] === ''"></div>
                                            <div style="height: 24px" *ngIf="currentUser.transactions[0] === ''"></div>
                                        </div>
                                    </tbody>
                                </div>
                            </ng-container>

                            <div *ngIf="loading" class="loading-box">
                                <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
                            </div>
                        </div>

                        <!-- Payment Methods (For multiple) removed for now -->
                        <!-- <div class="mobile-body-title mb-2" *ngIf="currentUser.payment_methods.length > 0">
                            <h6 class="title">{{'users.payment_method' | translate}}</h6>
                            <hr class="aw-hr">
                            <div class="payment-method-wrapper" *ngFor="let pm of currentUser.payment_methods | slice:0:1;
                                ">
                                <div class="payment-method-icon">
                                    <img style="width: 32px; aspect-ratio: 1;"
                                        src="../../../../assets/icons/payment-methods/{{pm.type}}.svg"
                                        alt="payment-method">
                                </div>
                                <div class="payment-method-details">
                                    <div class="payment-method-name">
                                        {{('users.payment_methods.type.'+pm.type | translate)}}
                                        {{pm.last4 ? convertCardNumber(pm.last4) : ''}}
                                    </div>
                                    <div class="payment-method-expire" *ngIf="pm.exp_date && !isExpired(pm.exp_date)">
                                        {{('users.user.expires' | translate)}} {{pm.exp_date}}
                                    </div>

                                    <div class="payment-method-expire" style="color: red;"
                                        *ngIf="isExpired(pm.exp_date)">
                                        {{('users.user.expired' | translate)}} {{pm.exp_date}}
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!-- Payment Method -->
                        <div class="mobile-body-title mb-2" *ngIf="currentUser.pm !== 'no_payment_type'">
                            <h6 class="title">{{ "users.payment_method" | translate }}</h6>
                            <hr class="aw-hr" />
                            <div class="payment-method-wrapper">
                                <div class="payment-method-icon">
                                    <img
                                        *ngIf="currentUser.wallet"
                                        style="width: 32px; aspect-ratio: 1; margin-right: 0.5rem"
                                        [src]="'../../../../assets/icons/payment-methods/' + currentUser.wallet.toLowerCase() + '.svg'"
                                        alt="payment-method"
                                    />
                                    <img style="width: 32px; aspect-ratio: 1" [src]="'../../../../assets/icons/payment-methods/' + currentUser.pm.toLowerCase() + '.svg'" alt="payment-method" />
                                </div>
                                <div class="payment-method-details">
                                    <div class="payment-method-name">
                                        {{ "users.payment_methods.type." + currentUser.pm.toLowerCase() | translate }}
                                        {{ currentUser.last4 ? convertCardNumber(currentUser.last4) : "" }}
                                    </div>
                                    <div class="payment-method-expire" *ngIf="currentUser.pm_exp_date && !isExpired(currentUser.pm_exp_date)">{{ "users.user.expires" | translate }} {{ currentUser.pm_exp_date }}</div>

                                    <div class="payment-method-expire" style="color: red" *ngIf="currentUser.pm_exp_date && isExpired(currentUser.pm_exp_date)">{{ "users.user.expired" | translate }} {{ currentUser.pm_exp_date }}</div>
                                </div>
                            </div>
                        </div>

                        <!-- Activity List -->
                        <div class="recent-activity">
                            <div class="mobile-body-title">
                                <div style="display: flex; justify-content: space-between">
                                    <h6 class="title">{{ "users.user.recent_activity" | translate }}</h6>
                                    <u *ngIf="!showLoadingOnActivity" class="pointer" (click)="openModal(AddComment, 'md')">{{ "users.user.add_comment" | translate }}</u>
                                </div>
                                <hr class="aw-hr" />
                            </div>

                            <div class="activity-list" *ngIf="!showLoadingOnActivity">
                                <div
                                    [className]="
                                        isMobile ? (currentActivityIndex === i ? 'mobile-event-wrapper add-row event-wrapper' : 'mobile-event-wrapper event-wrapper') : currentActivityIndex === i ? 'add-row event-wrapper' : 'event-wrapper'
                                    "
                                    class="event-wrapper"
                                    *ngFor="let event of currentUser.recent_activity_list.slice(0, itemsToShow); let i = index"
                                >
                                    <div [className]="currentActivityIndex === i ? 'icon-list-wrapper extended-icon-list-wrapper' : 'icon-list-wrapper'" class="icon-list-wrapper">
                                        <!-- <div [className]="i === 0 ? 'transparent line-top' : 'line-top'"></div> -->
                                        <div class="box-top-wrapper">
                                            <div *ngFor="let _ of [].constructor(5)" [className]="i === 0 ? 'transparent box-top' : 'box-top'"></div>
                                        </div>
                                        <img
                                            src="../../../../assets/icons/events/{{
                                                event.status_type.includes('failed') ? 'failed' : event.status_type.includes('success') || event.status_type === 'user_creation' ? 'success' : event.status_type
                                            }}.svg"
                                        />
                                        <div class="box-bottom-wrapper">
                                            <div *ngFor="let _ of [].constructor(5)" [className]="i === currentUser.recent_activity_list.slice(0, itemsToShow).length - 1 ? 'box-bot transparent' : 'box-bot'"></div>
                                        </div>
                                    </div>
                                    <div class="details-wrapper">
                                        <div class="details">
                                            <p class="message">{{ event.message && event.author ? event.message : getStatusMessage(event) }}</p>
                                            <p class="date">
                                                {{ getLocaleDate(event.timestamp) }} {{ event.author ? ("misc.by" | translate) + " " + event.author : "" }}
                                                {{ event.error ? ",Error:" + event.error : event.decline_code ? formatPaymentFailedString(event.decline_code) : "" }}
                                            </p>
                                        </div>

                                        <div class="more-details-wrapper" *ngIf="event.description || event.decline_code">
                                            <div class="more-details-toggle">
                                                <div (click)="showMoreDetailsToggle(i)">
                                                    <p class="pointer" style="min-height: 14px">
                                                        {{ isMobile ? "" : ("users.user.show_details" | translate) }}
                                                    </p>
                                                    <div>
                                                        <img src="../../../../assets/icons/arrow-{{ currentActivityIndex === i ? 'up' : 'down' }}-blue.svg" [className]="'arrow'" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="remove-wrapper" *ngIf="event.status_type === 'comment'">
                                            <u class="pointer" style="font-size: 12px" (click)="removeComment(event.id)">{{ "misc.delete" | translate }}</u>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="currentActivityIndex === i">
                                        <div class="box-bottom-wrapper">
                                            <div *ngFor="let _ of [].constructor(15)" [className]="i === currentUser.recent_activity_list.slice(0, itemsToShow).length - 1 ? 'box-bot transparent' : 'box-bot'"></div>
                                        </div>

                                        <div class="more-details-box">
                                            <hr class="aw-hr" style="margin: 0" />

                                            <div class="more-info">
                                                <div>
                                                    <span *ngIf="event.description">
                                                        <strong>{{ "users.user.activity.description" | translate }}:</strong>
                                                        {{
                                                            event.status_type === "refund" && (event.description === "fraudulent" || event.description === "duplicate" || event.description === "requested_by_customer")
                                                                ? ("transactions.refund_reason_" + event.description | translate)
                                                                : ("users.user.activity.description" | translate) + ": " + event.description
                                                        }}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span *ngIf="event.status_type === 'refund'">
                                                        <strong>{{ "transactions.transaction_id" | translate }}:</strong>
                                                        {{ event.payment_id }}
                                                    </span>
                                                    <span *ngIf="event.decline_code">
                                                        <div>
                                                            <strong>{{ "users.user.activity.description" | translate }}:</strong>
                                                            {{ getDescriptionFromDeclineCode(event.decline_code) }}
                                                        </div>
                                                        <div>
                                                            <strong>{{ "users.user.activity.next_steps" | translate }}:</strong>
                                                            {{ getNextStepFromDeclineCode(event.decline_code) }}
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="p-2 d-flex" *ngIf="currentUser.recent_activity_list.length <= 0 && !showLoadingOnActivity">
                                    <img class="m-2" style="width: 15px; aspect-ratio: 1" src="../../../../assets/icons/events/no-events.svg" />
                                    <p class="m-2">{{ "users.user.no_activity" | translate }}</p>
                                </div>
                            </div>

                            <u
                                class="pointer"
                                *ngIf="currentUser.recent_activity_list.length > itemsToShowInitially && currentUser.recent_activity_list.length > itemsToShow && !showLoadingOnActivity"
                                style="margin: 0 0 0 10px"
                                (click)="extendActivityList()"
                                >{{ "misc.show_more" | translate }}</u
                            >
                            <loading-indicator style="padding: 2rem" *ngIf="showLoadingOnActivity" [size]="'large'" [color]="'secondary'"></loading-indicator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Edits -->

    <ng-template #CreditPaymentInfo let-modal>
        <app-credit-payment-info-modal [userId]="currentUser.uid"></app-credit-payment-info-modal>
    </ng-template>

    <ng-template #EditCoupons let-modal>
        <app-edit-coupons-modal (onStateChange)="updateUserState($event)" [availableCoupons]="availableCoupons" [appliedCoupons]="appliedCoupons" [userId]="currentUser.uid"></app-edit-coupons-modal>
    </ng-template>

    <!-- Actions -->
    <ng-template #ApplyCreditPayment let-modal>
        <app-apply-credit-payment-modal [userId]="currentUser.uid"></app-apply-credit-payment-modal>
    </ng-template>

    <ng-template #ApplyCoupon let-modal>
        <app-apply-coupon-modal (stateChange)="updateUserState($event)" [availableCoupons]="availableCoupons" [appliedCoupons]="appliedCoupons" [userId]="currentUser.uid"></app-apply-coupon-modal>
    </ng-template>

    <ng-template #AddComment let-modal>
        <app-add-comment-modal [userId]="currentUser.uid" [onBehalf]="currentUser.owner_uid" (onStateChange)="refetchActivities()"> </app-add-comment-modal>
    </ng-template>

    <ng-template #Refund let-modal>
        <app-refund-modal [phoneNumberOrUid]="currentUser.uid" [onBehalf]="currentUser.owner_uid" [transaction]="selectedTransaction" (onStateChange)="updateTransaction($event)"> </app-refund-modal>
    </ng-template>

    <ng-template #RefundReason let-modal>
        <app-refund-reason-modal></app-refund-reason-modal>
    </ng-template>
</div>
