<div class="container mb-5">
    <div class="mt-4 mb-3" *ngIf="showLoadingIndicator">
        <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
    </div>

    <!-- Onboadring flow content -->
    <ng-container *ngIf="!showLoadingIndicator">
        <app-onboarding [customerDetails]="customerDetails"></app-onboarding>
    </ng-container>

    <!-- Charts and default content -->
    <div class="inner-container" *ngIf="onboardingComplete && !showLoadingIndicator">
        <div class="box-wrapper">
            <div class="d-flex title-container" style="width: 100%">
                <div class="title-box col-8 d-flex justify-content-between">
                    <div class="col-9 d-flex" style="gap: 0.25rem; align-items: center">
                        <p class="overflow">
                            {{ "overview.owner" | translate }}: {{ persons.representative.first_name }}
                            {{ persons.representative.last_name }}
                        </p>

                        @switch (state.account_state) {
                            @case ("complete") {
                                <img style="width: 18px; height: 18px" src="../../../assets/icons/onboard/onboard-complete.svg" alt="{{ 'overview.verified' | translate }}" />
                            }
                            @case ("pending") {
                                <div class="account-state pending">
                                    <img src="../../../assets/icons/onboard/onboard-pending.svg" alt="{{ 'customer.pending' | translate }}" />
                                    <p>{{ "overview.pending_verification" | translate }}</p>
                                </div>
                            }
                            @case ("restricted") {
                                <img style="height: 18px; width: 18px" src="../../../assets/icons/onboard/onboard-restricted.svg" alt="{{ 'customer.restricted' | translate }}" />
                            }
                            @case ("restricted_soon") {
                                <div class="account-state restricted-soon">
                                    <img src="../../../assets/icons/onboard/onboard-restricted_soon.svg" alt="{{ 'customer.restricted_soon' | translate }}" />
                                    <p>{{ "overview.restricted_soon" | translate }}</p>
                                </div>
                            }
                        }

                        <!-- <p *ngIf="state.account_state === 'restricted'" style="color: #D81E5B">
                            {{'customers.restricted' | translate }}
                        </p>
                        <p *ngIf="state.account_state === 'restricted_soon'" style="color: #FC9124">
                            {{'customers.restricted_soon' | translate }}
                        </p>
                        <p *ngIf="state.account_state === 'pending'">
                            {{'customers.pending' | translate }}
                        </p> -->
                    </div>
                    <u (click)="goToStripeKYC()" (keyup.enter)="goToStripeKYC()" tabindex="0" class="col-3 text-end" *ngIf="authService.hasLimitedAccess('edit_kyc', role$ | async)">
                        <div class="d-flex align-items-center justify-content-center">
                            <p *ngIf="!showSmallLoadingIndicator">{{ "overview.edit" | translate }}</p>
                            <loading-indicator *ngIf="showSmallLoadingIndicator" [size]="'tiny'" [color]="'secondary'"></loading-indicator>
                        </div>
                    </u>
                </div>
                <div class="line-chart-btns-wrapper col-4" *ngIf="!isMobile">
                    <button [className]="viewOptionForCharts === 'week' ? 'line-chart-btn active' : 'line-chart-btn inactive'" (click)="week()">{{ "overview.seven_days" | translate }}</button>
                    <button [className]="viewOptionForCharts === 'month' ? 'line-chart-btn active' : 'line-chart-btn inactive'" (click)="month()">{{ "overview.thrity_days" | translate }}</button>
                    <button [className]="viewOptionForCharts === 'year' ? 'line-chart-btn active' : 'line-chart-btn inactive'" (click)="year()">{{ "overview.twelve_months" | translate }}</button>
                </div>
                <div *ngIf="isMobile">
                    <select class="form-select" #selectedFilter (change)="handleDateChange(selectedFilter.value)">
                        <option value="week">{{ "overview.seven_days" | translate }}</option>
                        <option value="month" [defaultSelected]="true">{{ "overview.thrity_days" | translate }}</option>
                        <option value="year">{{ "overview.twelve_months" | translate }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="d-flex box outer-box">
            <div class="line-chart-box col-9">
                <app-custom-tool-tip header="{{ 'overview.revenue' | translate }}" description="{{ 'overview.follow_revenue' | translate }}"></app-custom-tool-tip>
                <div class="d-flex align-items-center justify-content-center" [style]="isMobile ? 'min-height: 200px' : 'min-height: 300px'">
                    <div style="width: 100%; height: 100%">
                        <app-line (doneLoading)="UpdateRevenueOverPeriod($event)"></app-line>
                    </div>
                </div>
            </div>

            <div clasS="col-3 sidebar d-flex align-items-center justify-content-center" *ngIf="showLoadingIndicatorForPayoutDetails">
                <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
            </div>
            <div class="col-md-3 sidebar" *ngIf="!showLoadingIndicatorForPayoutDetails">
                <!--Total revenue-->
                <div>
                    <app-custom-tool-tip description="{{ 'overview.total_revenue' | translate }}" spaceBetween="true" placementUp="true">
                        <div title>
                            <h4 class="tiny-title">{{ "misc.revenue" | translate }}</h4>
                        </div>
                        <div tooltip-title>
                            <span class="tooltip-title">{{ "misc.revenue" | translate }}</span>
                        </div>
                    </app-custom-tool-tip>
                    <hr />
                    <div class="result" *ngIf="!showLoadingIndicatorForPayoutDetails">
                        <p class="payout-total-revenue">{{ totalRevenue }}</p>
                    </div>
                </div>
                <!--Payout-->
                <div>
                    <app-custom-tool-tip spaceBetween="true" fullWidth="{{!autoPayout ? 'true' : ''}}" placementUp="{{autoPayout ? 'true' : ''}}">
                        <div title>
                            <h4 class="tiny-title">{{ "overview.current_sum" | translate }}</h4>
                        </div>
                        <div tooltip-title>
                            <span class="tooltip-title">{{ "overview.current_sum" | translate }}</span>
                        </div>
                        @if (autoPayout) {
                            <div description>
                                <p></p>
                                {{ "overview.current_balance_stripe" | translate }}
                            </div>
                        } @else {
                            <div description>
                                <div *ngFor="let po of specificManualPayoutData" style="width: 100%; text-align: left">
                                    <p></p>
                                    {{ po.bank_account_name }}: • • • • {{ po.last4 }}:<br />
                                    {{ getPayoutDateAmountString(po) }}
                                </div>
                            </div>
                        }
                    </app-custom-tool-tip>
                    <hr />
                    <div class="result" *ngIf="!showLoadingIndicatorForPayoutDetails">
                        <p style="margin-bottom: 0px">{{ sumPayout }}</p>
                        <a class="small-note" href="https://wiser.airwallet.net/en/article/understanding-your-payout-schedule-and-revenue-collection-cqirg2/?bust=1723034171745" target="_blank">{{ "overview.explanation" | translate }}</a>
                    </div>
                </div>
                <!--Date for renewal-->
                <div>
                    <app-custom-tool-tip description="{{ 'overview.estimated_date' | translate }}" spaceBetween="true" fullWidth="true" placementUp="true">
                        <div title>
                            <h4 class="tiny-title">{{ "overview.date_next_pay" | translate }}</h4>
                        </div>
                        <div tooltip-title>
                            <span class="tooltip-title">{{ "overview.date_next_pay" | translate }}</span>
                        </div>
                    </app-custom-tool-tip>
                    <hr />
                    <div class="result" *ngIf="!showLoadingIndicatorForPayoutDetails">
                        <p class="payout-date">{{ nextPayoutDay }}</p>
                    </div>
                </div>
                <div>
                    <!--Active users-->
                    <app-custom-tool-tip description="{{ 'overview.amount_of_users' | translate }}" spaceBetween="true" placementUp="true">
                        <div title>
                            <h4 class="tiny-title">{{ "overview.active_users" | translate }}</h4>
                        </div>
                        <div tooltip-title>
                            <span class="tooltip-title">{{ "overview.active_users" | translate }}</span>
                        </div>
                    </app-custom-tool-tip>
                    <hr />
                    <div class="d-flex justify-content-between">
                        <div class="result bottom">
                            <p class="active-users">{{ active_users_present }}</p>
                        </div>
                        <div class="bottom">
                            <p class="note">
                                <img *ngIf="diffInActiveUsersFromPastToPresent > 0" src="../../../assets/icons/arrow-up.svg" alt="{{ 'overview.check_mark' | translate }}" />
                                <img *ngIf="diffInActiveUsersFromPastToPresent < 0" src="../../../assets/icons/arrow-down-red.svg" alt="{{ 'overview.check_mark' | translate }}" />
                                <strong class="ml5">{{ diffInActiveUsersFromPastToPresent }}</strong> /
                                {{ "misc." + viewOptionForCharts | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box actions-box">
            <div class="box-header">
                <div class="box-header-flex">
                    <app-custom-tool-tip header="{{ 'operator.filter_bar.actions' | translate }}" description="{{ 'overview.actions_tooltip' | translate }}" placementUp="true" titleClass="line-title" fullwidth="true"></app-custom-tool-tip>
                </div>
                <p class="pointer" [routerLink]="(isOperator$ | async) ? ['/operator/actions'] : ['/actions']" *ngIf="!isMobile">
                    <u>{{ "overview.open_list" | translate }}</u>
                    <img src="../../../../assets/icons/arrow-up-blue.svg" style="transform: rotate(90deg)" alt="{{ 'customers.company_name' | translate }}" />
                </p>
            </div>
            <div *ngIf="actionLoading" class="m-5">
                <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
            </div>
            <div class="action-grid" *ngIf="!actionLoading && !!actions">
                <ng-container *ngFor="let action of actions" style="width: 33%">
                    <app-small-actions-card [action]="action" [isSkeleton]="isSkeleton" [isSmallerCard]="true"></app-small-actions-card>
                </ng-container>
            </div>
            <ng-container *ngIf="showNoActionsBox() && !actionLoading">
                <div class="empty-container d-flex align-items-center justify-content-center flex-column">
                    <div class="content-container">
                        <div class="empty-top-half">
                            <div class="lottie-container">
                                <ng-lottie [options]="options" class="lottie-waves"></ng-lottie>
                            </div>
                            <p>{{ "operator.actions.empty_boxes.no_active_actions_hooray" | translate }}</p>
                        </div>
                        <p>{{ "operator.actions.empty_boxes.no_active_actions" | translate }}</p>
                    </div>
                </div>
            </ng-container>
            <p class="pointer bottom-link" [routerLink]="(isOperator$ | async) ? ['/operator/actions'] : ['/actions']" *ngIf="isMobile">
                <u>{{ "overview.open_list" | translate }}</u>
                <img src="../../../../assets/icons/arrow-up-blue.svg" style="transform: rotate(90deg); margin-left: 6px" alt="{{ 'customers.company_name' | translate }}" />
            </p>
        </div>

        <div class="contract-container d-flex" *ngIf="isOperator$ | async">
            <app-contract-performance-list class="col-6 contract-box"></app-contract-performance-list>
            <app-contract-expiring-list class="col-6 contract-box"></app-contract-expiring-list>
        </div>
    </div>
</div>
