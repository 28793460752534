import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExportedOrder, Order, Reason, RefundParams } from 'shared_models/order';
import { LocationNames } from 'shared_models/location';
import { TransactionService } from '../../services/transaction/transaction.service';
import { ToastrService } from 'ngx-toastr';
import { PageEvent } from '@angular/material/paginator';
import { CustomerService } from '../../services/customer/customer.service';
import { HelperService } from '../../services/helper/helper.service';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { Observable, Subscription } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Datepicker } from 'vanillajs-datepicker';
import { DatePickerLanguages } from '../../constants/datepickerLanguages';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PaginatePipe } from '../../pipe/paginate.pipe';
import { CustomModalComponent } from '../misc/custom-modal/custom-modal.component';
import { LoadingComponent } from '../loading/loading.component';
import { NgFor, NgIf } from '@angular/common';
import { DateTimeService } from '@services/date-time/date-time.service';
import { FilterOption, FilterType } from '@shared_models/aw-components/filterOption';
import { NgSelectModule } from '@ng-select/ng-select';
import { AwExportButtonComponent } from '@components/misc/aw-export-button/aw-export-button.component';
import { AwFilterButtonComponent } from '@components/misc/aw-filter-button/aw-filter-button.component';
import { FilterSortParams } from '@shared_models/search-params/FilterSortParams';
import { PageLayoutComponent } from '@components/misc/aw-page-layout/page-layout.component';
import { AwTableComponent } from '@components/misc/aw-table/aw-table.component';
import { TableHeaderOptions } from '@shared_models/aw-components/tableHeaderOptions';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import dayjs from 'dayjs';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { ControlledUser } from '@shared_models/controlled-user';

@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, LoadingComponent, CustomModalComponent, TranslateModule, PaginatePipe, NgSelectModule, AwExportButtonComponent, AwFilterButtonComponent, PageLayoutComponent, AwTableComponent]
})
export class TransactionsComponent implements OnInit, OnDestroy {
    isOperator$: Observable<boolean>;
    showLoadingIndicator = false;
    showSmallLoadingIndicator = false;
    orders: ExportedOrder[] = [];

    // filters
    chosenCustomerUid: string;
    Locations: LocationNames[] = [];
    locationId: string;
    deviceId: string;

    exportingExcel: boolean;
    subCustomerPermission: Subscription;
    user: DashboardUser;
    orderKeyToRefund = '';
    showRefund = true;
    orderToRefund: Order;
    refundReason: Reason = Reason.requested_by_customer;
    otherReason: string;
    placeholderText: string;
    frontendPagination: boolean;
    totalFetchedItems = 0;

    prev_cursor: { key: string; value: string } | null = null;
    next_cursor: { key: string; value: string } | null = null;

    protected isMobile = false;
    fetchingData = true;

    // Necessary inputs for FE pagination
    pageSize = 15;
    totalItems = 0;
    pageNumber = 0;
    hasMore = false;
    capReached = false;

    filter: Record<string, any> = {};
    filterSortParams: FilterSortParams;

    filterOptions: FilterOption[] = [
        {
            key: 'created',
            type: FilterType.DATE_RANGE,
            value: null,
            isDateRange: true,
            label: 'customers.created'
        },
        {
            key: 'customer_key',
            type: FilterType.SELECT,
            value: null,
            label: 'misc.customers',
            selectOptions: [],
            hasDependencyOn: 'location_key'
        },
        {
            key: 'location_key',
            type: FilterType.SELECT,
            value: null,
            label: 'misc.locations',
            selectOptions: [],
            hasDependencyOn: 'device_key'
        },
        {
            key: 'device_key',
            type: FilterType.SELECT,
            value: null,
            label: 'misc.devices',
            selectOptions: []
        },
        {
            key: 'status',
            type: FilterType.SELECT,
            value: null,
            label: 'transactions.status',
            selectOptions: [
                { value: 'completed', label: this.translate.instant('transactions.completed') },
                { value: 'refund', label: this.translate.instant('transactions.refunded') }
            ]
        }
    ];

    tableHeaderOptions: TableHeaderOptions[] = [
        {
            sortKey: '',
            title: this.translate.instant('transactions.transaction_id'),
            width: '10%'
        },
        {
            sortKey: '',
            title: this.translate.instant('transactions.date'),
            width: '15%'
        },
        {
            sortKey: '',
            title: this.translate.instant('transactions.amount'),
            width: '10%'
        },
        {
            sortKey: '',
            title: this.translate.instant('misc.customer'),
            width: '10%'
        },
        {
            sortKey: '',
            title: this.translate.instant('misc.location'),
            width: '15%'
        },
        {
            sortKey: '',
            title: this.translate.instant('misc.device'),
            width: '15%'
        },
        {
            sortKey: '',
            title: this.translate.instant('transactions.phone_no'),
            width: '15%'
        },
        {
            sortKey: '',
            title: this.translate.instant('transactions.refund'),
            width: '10%',
            alignment: 'center'
        }
    ];

    constructor(
        private authService: AuthService,
        private transactionService: TransactionService,
        private toast: ToastrService,
        private customerService: CustomerService,
        protected helperService: HelperService,
        public translate: TranslateService,
        private modalService: NgbModal,
        private dateTimeService: DateTimeService,
        private breakpointObserver: BreakpointObserver,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    ngOnDestroy() {
        if (this.subCustomerPermission) this.subCustomerPermission.unsubscribe();
    }

    async ngOnInit() {
        this.showLoadingIndicator = true;
        this.placeholderText = this.translate.instant('transactions.refund_enter_reason');

        this.user = this.helperService.getUser();
        this.isOperator$ = this.authService.isOperator;
        Object.assign(Datepicker.locales, DatePickerLanguages());
        Datepicker.locales['da']['today'] = 'I dag'; // fix for incorrect danish translation in the library
        this.setSubscriptions();
        this.getSubcustomers();
        const searchUid: string = this.route.snapshot.queryParamMap.get('uid');
        this.initializeFilterToMyAccount(searchUid);
        const id: string = this.route.snapshot.queryParamMap.get('id');
        const userId: string = this.route.snapshot.queryParamMap.get('user_id');
        const terminal: string = this.route.snapshot.queryParamMap.get('terminal');
        await this.loadTransactions('next', { id, userId, terminal });
    }

    private async initializeFilterToMyAccount(uid?: string) {
        this.chosenCustomerUid = uid ?? this.user.uid;
        const filterOption: FilterOption = this.filterOptions.find(option => option.key === 'customer_key');
        filterOption.value = this.chosenCustomerUid;
        this.hideCustomerFilterIfNotOperator(filterOption);
        this.filter = {
            customer_key: this.chosenCustomerUid
        };
        this.getLocationNames();
    }

    private async hideCustomerFilterIfNotOperator(filterOption: FilterOption) {
        const isOperator: boolean = await this.authService.getOperatorStatus();
        if (!isOperator) {
            // Hide customer field, if not operator
            filterOption.hidden = true;
        }
    }

    async loadTransactions(action?: 'next' | 'prev' | 'first' | 'last', { id, userId, terminal }: { id?: string; userId?: string; terminal?: string } = {}) {
        this.fetchingData = true;
        const filterSortParams: FilterSortParams = {
            filter: this.filter,
            sortBy: { key: 'timestamp', order: 'asc' }, // no sort available anyway
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            action: action ?? 'next'
        };
        if (id) filterSortParams.specificSearch = { key: 'transactionId', value: id };
        if (userId) filterSortParams.specificSearch = { key: 'userUid', value: userId };
        if (terminal) filterSortParams.specificSearch = { key: 'terminal', value: terminal };
        if (action === 'next' && this.next_cursor) filterSortParams.endBefore = this.next_cursor;
        if (action === 'prev' && this.prev_cursor) filterSortParams.startAfter = this.prev_cursor;

        this.filterSortParams = filterSortParams;

        const promises = [];

        if (!this.totalItems) {
            promises.push(
                this.transactionService.getTotal(filterSortParams, this.filter.customer_key).then(total => {
                    this.hasMore = total > 1000;
                    this.totalItems = total === 0 ? 0 : total - 1;
                })
            );
            promises.push(
                this.transactionService.getApproxTotal(filterSortParams, this.filter.customer_key).then(total => {
                    this.totalFetchedItems = total;
                })
            );
        }

        promises.push(
            this.transactionService.getTransactions(filterSortParams, this.filter.customer_key).then(result => {
                // this.totalFetchedItems = this.pageNumber ? this.totalFetchedItems + result.orders.length : result.orders.length;
                const itemsCount = result.orders.length;
                const exeedingCount = this.pageSize * this.pageNumber - this.totalItems + itemsCount;
                if (exeedingCount > 0) this.totalItems += exeedingCount;
                if (!this.capReached) this.hasMore = result.next_cursor ? true : false;
                if (!result.next_cursor) this.capReached = true;
                this.showLoadingIndicator = false;
                this.orders = result.orders;
                this.fetchingData = false;
                this.prev_cursor = result.prev_cursor;
                this.next_cursor = result.next_cursor;
                this.frontendPagination = result.frontendPagination;
            })
        );
        await Promise.all(promises);
    }

    async handlePage(e: PageEvent) {
        let action: 'next' | 'prev' | 'first' | 'last' = 'next';
        if (this.pageSize !== e.pageSize) {
            this.pageSize = e.pageSize;
            this.pageNumber = 0;
            this.prev_cursor = null;
            this.next_cursor = null;
        } else {
            this.pageNumber = e.pageIndex;
            if (!this.frontendPagination) {
                if (e.pageIndex - 1 === e.previousPageIndex) action = 'next';
                if (e.pageIndex + 1 === e.previousPageIndex) action = 'prev';
                if (e.pageIndex < e.previousPageIndex - 1) action = 'first';
                if (e.pageIndex > e.previousPageIndex + 1) action = 'last';
            }
        }
        await this.loadTransactions(action);
    }

    getCustomerName(uid: string): string {
        if (!uid) return '';
        const customerFilter = this.filterOptions.find(option => option.key === 'customer_key');
        const customer = customerFilter.selectOptions.find(customer => customer.value === uid.split('_operated_by_')[0]);
        if (customer) return customer.label;
        return '';
    }

    setSubscriptions() {
        if (this.user.uid.includes('_operated_by_')) {
            this.subCustomerPermission = this.customerService
                .readSubCustomerPermission(this.user.uid)
                .snapshotChanges()
                .subscribe(action => {
                    this.subCustomerPermission.unsubscribe();
                    this.showRefund = action.payload.val().allow_refund;
                    if (!this.showRefund) {
                        this.tableHeaderOptions.pop();
                        this.tableHeaderOptions[0].width = '20%';
                    }
                });
        }
    }

    handleFilterValueChange(event: Record<string, any>) {
        if (event.key === 'location_key') {
            this.chosenCustomerUid = event.value;
            this.getLocationNames();
            this.getDeviceNames();
        }
        if (event.key === 'device_key') {
            this.locationId = event.value;
            this.getDeviceNames();
        }
    }

    async catchFilterChanged(event: Record<string, any>) {
        this.pageNumber = 0;
        this.totalItems = 0;
        this.prev_cursor = null;
        this.next_cursor = null;
        if (Object.keys(event).length === 0) {
            this.initializeFilterToMyAccount();
        } else {
            this.filter = event;
        }
        await this.loadTransactions();
    }

    async getSubcustomers() {
        this.transactionService.getSubcustomerNames().then(subCustomerNames => {
            const customerFilter = this.filterOptions.find(option => option.key === 'customer_key');
            customerFilter.selectOptions = [{ value: this.user.uid, label: this.translate.instant('transactions.my_account') }, ...subCustomerNames];
        });
    }

    async getLocationNames() {
        this.transactionService.getLocationNames(this.chosenCustomerUid).then(locationNames => {
            this.filterOptions.find(option => option.key === 'location_key').selectOptions = locationNames;
        });
    }

    async getDeviceNames() {
        if (!this.locationId) {
            this.filterOptions.find(option => option.key === 'device_key').selectOptions = []; // reset device names
        } else {
            this.transactionService.getDeviceNames(this.chosenCustomerUid, this.locationId).then(deviceNames => {
                this.filterOptions.find(option => option.key === 'device_key').selectOptions = deviceNames;
            });
        }
    }

    localizeNumber = (number: number, currency: string): string => {
        return `${currency ? currency.toUpperCase() : this.user.settings.currency.toUpperCase()} ${this.helperService.localizeNumber(number)}`;
    };

    openRefundModal(modal: any, order: Order) {
        if (this.showSmallLoadingIndicator) return;

        this.orderToRefund = order;
        this.openModal(modal);
    }

    refundReasonCorrector(): string {
        if (this.orderToRefund.refund_reason === 'duplicate') {
            return this.translate.instant('transactions.refund_reason_duplicate');
        }
        if (this.orderToRefund.refund_reason === 'fraudulent') {
            return this.translate.instant('transactions.refund_reason_fraudulent');
        }
        if (this.orderToRefund.refund_reason === 'requested_by_customer') {
            return this.translate.instant('transactions.refund_reason_requested');
        }
        if (this.orderToRefund.refund_reason === 'other') {
            return this.translate.instant('transactions.refund_reason_other');
        }
    }

    getUserName(): string {
        const controlledUser: ControlledUser | null = this.localStorageService.getItem('controlled_user') as ControlledUser;
        if (controlledUser) {
            return 'Airwallet';
        } else {
            return this.localStorageService.getItem('loggedInUser').name;
        }
    }

    async refund(transaction_id: string, refundReason: Reason) {
        if (this.otherReason && this.otherReason.length > 255) {
            this.toast.info(this.translate.instant('transactions.reason_too_long'), this.translate.instant('misc.info'));
            return;
        }

        this.modalService.dismissAll();
        this.showSmallLoadingIndicator = true;
        this.orderKeyToRefund = this.orderToRefund['key'];
        if (this.orderToRefund.timestamp > dayjs().subtract(180, 'days').unix()) {
            const refundParams: RefundParams = {
                paymentId: transaction_id,
                refundReason: refundReason,
                otherReason: this.otherReason ? this.otherReason : '',
                userName: this.getUserName(),
                phoneNumberOrUid: this.orderToRefund.phone_number ?? this.orderToRefund.user_id,
                orderKey: this.orderKeyToRefund
            };
            await this.transactionService
                .refund(refundParams)
                .then((refundedOrder: Order) => {
                    console.log(refundedOrder);

                    for (const [index, value] of this.orders.entries()) {
                        if (value.key === this.orderKeyToRefund) {
                            if (this.orders[index].transaction_id.includes('pi_')) {
                                this.orders[index].refund = refundedOrder.refund;
                                this.orders[index].refund_reason = refundedOrder.refund_reason;
                                this.orders[index].refund_status = refundedOrder.refund_status;
                                this.orders[index].refund_po_id = refundedOrder.refund_po_id;
                                this.orders[index].operator_refund_po_id = refundedOrder.operator_refund_po_id;
                                this.orders[index].refund_details = refundedOrder.refund_details;
                                this.orders[index].user_name = refundedOrder.user_name;
                            } else {
                                this.orders[index].refund = true;
                                this.orders[index].refund_reason = this.refundReason;
                                this.orders[index].refund_details = this.otherReason;
                                this.orders[index].user_name = refundParams.userName;
                            }
                        }
                    }
                    this.orderToRefund = null;
                })
                .catch((httpResponseError: HttpErrorResponse) => {
                    console.log(httpResponseError);

                    const { error } = httpResponseError.error;
                    this.toast.info(this.translate.instant(error.translateString ? error.translateString : 'transactions.unknown'), this.translate.instant('misc.error'), { timeOut: 15000 });
                });
        } else {
            this.toast.info(this.translate.instant('transactions.order_too_old'), this.translate.instant('misc.info'));
        }

        this.showSmallLoadingIndicator = false;
        this.orderKeyToRefund = '';
        this.orderToRefund = null;
    }

    getLocalTime(timestamp: number): string {
        return this.dateTimeService.getDateAndTime(timestamp, false, true);
    }

    openModal(modal: any) {
        const modalOptions: NgbModalOptions = {
            ariaLabelledBy: 'modal-basic-title'
        };
        const modalRef: NgbModalRef = this.modalService.open(modal, modalOptions);
        modalRef.result.then(
            () => {
                // on close
            },
            () => {
                // on error/dismiss
            }
        );
    }
}
